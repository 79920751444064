import Logo from "./../../assets/Logo.png";
import Button from "../../components/Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { secondary } from "../../theme";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/api";
import { useForm } from "react-hook-form";
import { useGlobal } from "../../hooks/global";
import { IGroupProps } from "../../interfaces";
import { validateEmail } from "../../utils/validatesFields";
import { MagicButton } from "../../components/Buttons/MagicButton";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import {
  GenericForm,
  IGenericFormRefProps,
} from "../../components/GenericForm";
import {
  Content,
  ContainerBottomButtons,
  ContainerCheckBox,
  ContainerImage,
  ContainerTop,
  Form,
  Container,
  Title,
  Description,
  LogoImg,
  ResetPasswordButton,
} from "./style";

export const Login: React.FC = () => {
  const { notify } = useGlobal();
  const { signIn, remmemberCredentials, remmemberMe, setRemmemberMe } =
    useApi();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: remmemberCredentials as any,
    mode: "onChange",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const onSubmit = useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        await signIn(data, remmemberMe);
      } catch (err: any) {
        const error = err.response ? err.response.data : "SERVER ERROR";
        if (error !== "SERVER ERROR" && err.response) {
          setError("email", {
            type: "manual",
            message: "Email ou senha incorreta",
          });
          setError("password", {
            type: "manual",
            message: "Email ou senha incorreta",
          });
        } else notify("ERRO INTERNO DO SISTEMA.", "error");
      }
      setLoading(false);
    },
    [remmemberMe, form_ref, _form]
  );

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form]
  );

  const changeRemmemberMe = useCallback(
    () => setRemmemberMe((atual) => !atual),
    []
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "email",
            label: "Email",
            type: "input",
            validate: validateEmail,
            required: true,
            noUpperCase: true,
          },
        ],
        [
          {
            name: "password",
            label: "Senha",
            type: "input",
            required: true,
            isPassword: true,
            noUpperCase: true,
          },
        ],
      ],
    },
  ];

  return (
    <Container>
      <ContainerImage />

      <Content>
        <ContainerTop>
          <LogoImg src={Logo} height={70} />

          <Title>Bem vindo de volta!</Title>

          <Description>
            Preencha suas credenciais de acesso para acessar as funcionalidades
            da nossa plataforma!
          </Description>
        </ContainerTop>

        <Form onSubmit={handleStopPropagation}>
          <GenericForm
            ref={form_ref}
            groups={groups}
            _form={_form}
            control={control}
            trigger={trigger}
            errors={errors}
            setValue={setValue}
            register={register}
            noTrigger
          />

          <ContainerCheckBox>
            <MagicButton
              checked={remmemberMe}
              onClick={changeRemmemberMe}
              theme="light"
              type="checkbox"
              label="Lembrar de mim"
              labelStyle={{
                textTransform: "none",
                fontWeight: 600,
                fontSize: 13,
              }}
            />

            {/* <ResetPasswordButton>
                            <Link
                                to={"/reset-password"}
                                style={{ fontWeight: "600", color: "#212529" }}
                                children="Recuperar senha"
                            />
                        </ResetPasswordButton> */}
          </ContainerCheckBox>

          <button
            ref={buttonSubmitRef}
            type="submit"
            style={{ display: "none" }}
          />
        </Form>

        <ContainerBottomButtons>
          <Button
            color="#FFF"
            background={secondary.light}
            onClick={() => buttonSubmitRef.current?.click()}
            style={{ fontSize: 15, padding: "8px 16px" }}
            children="Entrar"
          />
        </ContainerBottomButtons>
      </Content>

      <ModalLoading loading={loading} theme="light" />
    </Container>
  );
};
