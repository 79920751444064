export const getAtualDate = ({ daysBefore = 0 }: any) => {
  const date = new Date();
  date.setDate(date.getDate() - daysBefore);
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

export const returnNumberWithDotInt = (value: number) => {
  return new Intl.NumberFormat("pt-br", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  })
    .format(value)
    .split(",")[0];
};

export const returnNumberWithDot = (value: number) => {
  return new Intl.NumberFormat("pt-br", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  }).format(value);
};
