import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "../../../../style";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { activityOptions } from "../options/activityOptions";
import { sendTypeOptions } from "../options/sendTypeOptions";
import { mapperTimeModes } from "../options/timeModeOptions";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { IGroupProps, INotificationRulesProps } from "../../../../interfaces";

interface IFormModalNotificationRuleProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: INotificationRulesProps | null;
  accommodationOptions: { label: string; value: string }[];
  groupOptions: { label: string; value: string }[];
  wppModels: { label: string; value: string }[];
  emailModels: { label: string; value: string }[];
}

export const FormModalNotificationRule: React.FC<IFormModalNotificationRuleProps> = (props) => {
  const { onSave, defaultData, accommodationOptions, groupOptions, wppModels, emailModels } = props;

  const { theme, notify } = useGlobal();
  const { api } = useApi();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : ({} as any),
    mode: "all",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();

      if (defaultData?.id) {
        await api.put(`/notification-rules/${defaultData?.id}`, form);
      } else {
        await api.post("/notification-rules", form);
      }

      notify(
        `Regra de notificação ${defaultData?.id ? "atualizada" : "criada"} com sucesso!`,
        "success"
      );

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field) setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, api]);

  const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    handleSubmit(onSubmit)(e);
  }, [handleSubmit, onSubmit, form_ref, _form, api]);

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "name",
            label: "Nome",
            type: "input",
            isClearable: true,
            required: true,
          },
        ],
        [
          {
            name: "send_type",
            label: "Tipo de envio",
            type: "select-fixed",
            required: true,
            isClearable: true,
            options: sendTypeOptions,
          },
          {
            name: "activity",
            label: "Atividade",
            type: "select-fixed",
            required: true,
            isClearable: true,
            options: activityOptions,
          },
          {
            name: "time_mode",
            label: "Tempo para envio",
            type: "select-fixed",
            required: true,
            isClearable: true,
            getOptions: (form) => {
              if (!form?.activity?.value) return [];
              return mapperTimeModes?.[form?.activity?.value] ?? [];
            },
          },
        ],
        [
          {
            name: "model_id",
            label: "Modelo",
            type: "select-fixed",
            required: true,
            isClearable: true,
            options: wppModels,
            getOptions: (form) => form?.send_type?.value === "wpp" ? wppModels : emailModels,
          }
        ],
        [
          {
            name: "accommodation_id",
            label: "Acomodação",
            type: "select-fixed",
            isClearable: true,
            options: accommodationOptions,
            getIsDisabled: (form) => !!form?.group_id?.value,
          },
          {
            name: "group_id",
            label: "Grupo",
            type: "select-fixed",
            isClearable: true,
            options: groupOptions,
            getIsDisabled: (form) => !!form?.accommodation_id?.value,
          },
        ]
      ],
    },
  ];

  useEffect(() => {
    if (!defaultData?.id) setValue('time_mode', null);
  }, [_form.send_type])

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", padding: 20 }}>
        <ContainerForm open>
          <Form onSubmit={handleStopPropagation}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
      </Column>

      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
