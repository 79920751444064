import React, { useCallback } from "react";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { ContainerPage } from "./style";
import { background } from "../../theme";
import { useGlobal } from "../../hooks/global";

export const DashboardPage: React.FC = ({ children }) => {
  const { menuOpen, theme, setSubMenuOpen, subMenuOpen, seeNotification } =
    useGlobal();

  const clickButtonMenu = useCallback(() => {
    if (subMenuOpen) setSubMenuOpen(false);
  }, [menuOpen, subMenuOpen]);

  const calcHeight = () => {
    const header = document.getElementById("header-content");
    return header?.offsetHeight || 0;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Header />
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: seeNotification ? calcHeight() : 80,
            flex: 1,
            background: background[theme],
            minHeight: "calc(100 * var(--vh))",
          }}
        >
          <Sidebar />
          <ContainerPage
            menuOpen={menuOpen}
            theme={theme}
            onClick={clickButtonMenu}
          >
            {children}
          </ContainerPage>
        </div>
      </div>
    </div>
  );
};
