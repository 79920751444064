import { useCallback, useEffect, useState } from "react";
import { useGlobal } from "../../hooks/global";
import { PageList } from "../../components/PageList";
import { IActions, IFilters, IMessageModels } from "../../interfaces";
import { useApi } from "../../hooks/api";
import { correctDate, setUpConsultation } from "../../services/generalServices";
import { ITable } from "../../components/TableList";
import { getFilterFields } from "../../utils/filterFields";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { IoAddCircleOutline } from "react-icons/io5";
import { ModalMessageModel } from "../../components/Modals/ModalMessageModel";
import { MdDelete, MdOutlineCancel, MdOutlineEdit } from "react-icons/md";
import { secondary } from "../../theme";
import { FaEdit } from "react-icons/fa";

const initialTable: ITable = {
    data: [],
    columns: [
        {
            attr: "name",
            name: "Nome",
            style: {},
            styleContent: { fontSize: 16, fontWeight: 600 },
        },
        {
            attr: "type",
            name: "Tipo",
            style: {},
            styleContent: { fontSize: 14 },
        },
        {
            attr: "createdAt",
            name: "Criado em",
            style: { textAlign: "center" },
            styleContent: { fontSize: 14, textAlign: "center" },
        },
    ],
};

const initialFilters: IFilters[] = [
    getFilterFields("type"),
];

export const MessageModels: React.FC = () => {
    const [updatedAt, setUpdatedAt] = useState(new Date());
    const [loadingMessageModels, setLoadingMessageModels] = useState(true);
    const [numOfMessageModels, setNumOfMessageModels] = useState(0);
    const [filters, setFilters] = useState<any>({});
    const [numberOfPages, setNumberOfPages] = useState<number>(1);
    const [atualPage, setAtualPage] = useState(1);
    const [searchString, setSearchString] = useState<string>("");
    const [messageModelSelected, setMessageModelSelected] = useState<IMessageModels | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [table, setTable] = useState<ITable>(initialTable);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const selectReservation = useCallback((accomoddation: IMessageModels) => {
        setMessageModelSelected({ ...accomoddation });
        setOpenModal(true);
    }, []);

    const { theme, paginationLimit, notify } = useGlobal()

    const { api, user } = useApi();

    const handleAction = useCallback((action_id: string) => {
        if (action_id === "new_item") openModalNewMessageModel();
    }, [])

    const handleDelete = useCallback(async (id: string) => {
        const result = window.confirm("Deseja realmente deletar este modelo de mensagem?");
        if (!result) return;

        setLoadingDelete(true);
        try {
            await api.delete(`/message-models/${id}`);
            notify("Modelo de mensagem deletado com sucesso!", "success");
            searchMessageModel(1);
        } catch (err) {
            notify("Erro ao deletar modelo de mensagem!", "error");
        }
        setLoadingDelete(false);
    }, []);

    const listAction = [
        {
            title: "Remover",
            onClick: (item: any) => handleDelete(item?.id),
            icon: (
                <MdDelete
                    color={secondary[theme]}
                    size={20}
                />
            ),
            disabled: (item: any) => !user?.user_type?.permissions?.includes('MESSAGE-MODELS-DELETE')
        },
        {
            title: "Editar",
            onClick: (item: any) => selectReservation(item),
            icon: (
                <FaEdit
                    color={secondary[theme]}
                    size={20}
                />
            ),
            disabled: (item: any) => !user?.user_type?.permissions?.includes('MESSAGE-MODELS-UPDATE')
        }
    ] as any[];

    const actions: IActions[] = [
        {
            main_option: { text: "Nova Check-in", id: "new_item" },
            className: "new_item",
            icon: <IoAddCircleOutline size={20} className="new_item" />,
            options: [],
            type: "solid",
            permissions: ["MESSAGE-MODELS-CREATE"],
        },
    ];

    const searchMessageModel = useCallback(
        async (page, _filters = null) => {
            setAtualPage(page);
            setLoadingMessageModels(true);
            try {
                const filters_obj = _filters
                    ? _filters
                    : { ...filters, search: searchString };

                let string = setUpConsultation(filters_obj);
                let url = `/message-models?page=${page}&limit=${paginationLimit}&${string}`;

                const result = await api.get(url);
                const new_message_models = result.data.data as IMessageModels[];
                setTable((atual) => {
                    const new_table = { ...atual };
                    new_table.data = new_message_models.map((message_model) => ({
                        name: message_model.name,
                        type: message_model.type === 'email' ? 'E-mail' : 'WhatsApp',
                        createdAt: correctDate(new Date(message_model.createdAt), { no_have_time: true }),
                        this: message_model,
                    }));
                    return { ...new_table };
                });

                setNumOfMessageModels(result.data.count);
                setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
                setUpdatedAt(new Date());
            } catch (err) {
                notify("Erro na busca pelos modelos de mensagens!", "error");
            }
            setLoadingMessageModels(false);
        },
        [user, filters, searchString]
    );

    const openModalNewMessageModel = useCallback(() => {
        setMessageModelSelected(null);
        setOpenModal(true);
    }, []);

    const onSaveMessageModel = useCallback(() => {
        setOpenModal(false);
        searchMessageModel(1);
    }, [user, filters, searchString]);

    useEffect(() => { searchMessageModel(1) }, []);

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingMessageModels}
            setLoading={setLoadingMessageModels}
            numberOfItems={numOfMessageModels}
            actions={actions}
            onClickOption={handleAction}
            textHeader="Modelos de Mensagens"
            textItems="modelos de mensagens encontradas"
            text_loading={"Buscando Modelos de Mensagens"}
            text_empty={"Nenhum modelo de mensagem encontrada"}
            theme={theme}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            pages={numberOfPages}
            page={atualPage}
            search={searchMessageModel}
            onChangeSearch={setSearchString}
            listActions={listAction}
        >
            {openModal && (
                <ModalMessageModel
                    onCancel={() => setOpenModal(false)}
                    onSave={onSaveMessageModel}
                    openModal={openModal}
                    defaultId={messageModelSelected?.id}
                />
            )}
            {loadingDelete && <ModalLoading loading={loadingDelete} theme={theme} />}
            {loading && <ModalLoading loading={loading} theme={theme} />}
        </PageList>
    )
}