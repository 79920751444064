import Button from "../../../Buttons/Button";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { IGroupProps } from "../../../../interfaces";
import { Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { validateCPF, validateEmail } from "../../../../utils/validatesFields";
import { uniqueId } from "lodash";
import { maskFunctions } from "../../../../services/maskServices";

import { FormCheckIn } from "../checkInForm";
import { Alert, Text } from "@mantine/core";
import { FiAlertCircle } from "react-icons/fi";

export interface IGuestsDocumentsCardRef {
  matchImages: () => Promise<boolean>;
}

interface IFormReservationModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: any;
  defaultGuests?: any[];
  isDoneCheckIn: boolean;
}

const guestsFields = [
  "name",
  "email",
  "phone",
  "document_type",
  "document_value_cpf",
  "document_value_others",
];

export const FormReservation: React.FC<IFormReservationModalProps> = (
  props
) => {
  const [isOpenCheckIn, setIsOpenCheckIn] = useState<boolean>(false);

  const { onSave, defaultData, defaultGuests, isDoneCheckIn } = props;

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
    unregister,
    clearErrors
  } = useForm({
    defaultValues: defaultData?.reservation_stays_id ? defaultData : ({} as any),
    mode: "all",
  });

  const _form = watch();

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const generateGuestGroup = (defaultId?: string) => {
    const _uniqueId = defaultId ? defaultId : uniqueId();

    const newGuestGroup: IGroupProps = {
      name: "",
      label: "",
      uniqueId: _uniqueId,
      fields: [
        [
          {
            name: "name-" + _uniqueId,
            label: "Nome",
            type: "input",
            required: true,
            get_enabled_change_form_type: () => false,
          },
          {
            name: "email-" + _uniqueId,
            label: "Email",
            type: "input",
            validate: validateEmail,
            required: true,
          },
        ],
        [
          {
            name: "document_type-" + _uniqueId,
            label: "Tipo do Documento",
            type: "select-fixed",
            isClearable: true,
            options: [
              { label: "CPF", value: "cpf" },
              { label: "Passaporte", value: "passport" },
            ],
            required: true,
          },
          {
            name: "document_value_cpf-" + _uniqueId,
            label: "Documento",
            type: "input",
            mask: "cpf",
            required: true,
            validate: validateCPF,
            getIsDisabled: (data) => !data["document_type-" + _uniqueId]?.value,
            canSee: (data) =>
              data["document_type-" + _uniqueId]?.value === "cpf",
          },
          {
            name: "document_value_others-" + _uniqueId,
            label: "Documento",
            type: "input",
            required: true,
            getIsDisabled: (data) => !data["document_type-" + _uniqueId]?.value,
            canSee: (data) =>
              data["document_type-" + _uniqueId]?.value !== "cpf",
          },
          {
            name: "phone-" + _uniqueId,
            label: "Telefone",
            type: "input",
            mask: "phone",
            required: true,
          }
        ],
      ],
    };

    return newGuestGroup;
  };

  const [loadingSave, setLoadingSave] = useState(false);
  const [guestsGroups, setGuestsGroups] = useState<IGroupProps[]>(() => {
    if (defaultGuests && defaultGuests.length > 0) {
      const _guestsGroups: any[] = [];

      const _defaultDataGuests = defaultGuests ?? [];
      _defaultDataGuests.forEach((executor: any) => {
        _guestsGroups.push(generateGuestGroup(executor.uniqueId));
      });

      return _guestsGroups;
    }
    return [];
  });

  const form_ref = useRef<IGenericFormRefProps>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const guestsRefs = useRef<IGenericFormRefProps[]>([]);

  const guestsDocumentsRefs = useRef<IGuestsDocumentsCardRef[]>([]);

  const onSubmit = useCallback(async () => {

    try {
      let isVerified = true;

      for (const ref of guestsDocumentsRefs.current) {
        if (ref && ref.matchImages) {
          const result = await ref.matchImages();

          if (isVerified) isVerified = result;
        }
      }

      if (!isVerified) {
        notify("Biometria não correspondida!", "error");

        return;
      }

      setLoadingSave(true);

      const form = form_ref.current?.getForm();

      const guests = getGuests();

      if (defaultData?.id) {
        await api.put(`/check-ins/${defaultData?.id}`, { guests, ...form });
      } else {
        await api.post("/check-ins", { guests, ...form });
      }

      if (defaultData?.id) notify("Check-in atualizado com sucesso", "success");
      else notify("Check-in criado com sucesso", "success");

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else if (error.title === "Recurso não encontrado")
        setError("reservation_stays_id", {
          type: "manual",
          message: "A acomodação dessa reserva não foi encontrada!",
        });
      else if (error.title === "Recurso já existe")
        setError("reservation_stays_id", {
          type: "manual",
          message: "Check-in já cadastrado para essa reserva!",
        });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingSave(false);
  }, [form_ref, _form, api, guestsRefs, defaultData]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api, guestsRefs]
  );

  const getGuests = useCallback(() => {
    const _refs = guestsRefs.current.filter((ref) => ref?.getForm());
    const guests = _refs.map((ref) => {
      const form = ref?.getForm();
      const _uniqueId = form?.uniqueId;
      const document_type = form?.["document_type-" + _uniqueId];
      const document_value =
        document_type === "cpf"
          ? form?.["document_value_cpf-" + _uniqueId]
          : form?.["document_value_others-" + _uniqueId];

      return {
        name: _form?.["name-" + _uniqueId],
        email: form?.["email-" + _uniqueId],
        phone: form?.["phone-" + _uniqueId],
        document_type,
        document_value,
        document_image_front: _form?.["document-front-" + _uniqueId],
        document_image_back: _form?.["document-back-" + _uniqueId],
        selfie_image: _form?.["document-selfie-" + _uniqueId],
        uniqueId: _uniqueId,
      };
    });

    return guests;
  }, [guestsRefs, _form]);

  const addGuestGroup = useCallback(() => {
    const newGuestGroup = generateGuestGroup();
    setGuestsGroups((prev) => {
      return [...prev, newGuestGroup];
    });
  }, [uniqueId]);

  const removeOwnerGroup = useCallback((index: number) => {
    setGuestsGroups((prev) => {
      return prev.filter(({ uniqueId }, i) => {
        if (i !== index) return true;
        else
          guestsFields.forEach((field) => unregister(field + "-" + uniqueId));
      });
    });
  }, []);

  const handleStartNewCheckIn = useCallback(async (data: any) => {
    setIsOpenCheckIn(true);

    const { reservation_stays_id } = data;
    if (!reservation_stays_id) {
      setGuestsGroups((groups) => {
        for (const group of groups)
          guestsFields.forEach((field) =>
            unregister(field + "-" + group.uniqueId)
          );
        return [];
      });
    } else {
      const _guestsData = reservation_stays_id?.guests ?? [];
      const _guestsGroups: any[] = [];

      const _guests = _guestsData.map((guest: any) => {
        const _uniqueId = uniqueId();
        const label = guest?.cpf ? "CPF" : guest?.rg ? "RG" : "Passaporte";
        const value = guest?.cpf ? "cpf" : guest?.rg ? "rg" : "passport";
        let document_value = guest?.cpf || guest?.rg || guest?.passport;

        return {
          ["document_type-" + _uniqueId]: { label, value },
          ["document_value_cpf-" + _uniqueId]:
            value === "cpf"
              ? maskFunctions.cpf.mask(document_value)
              : document_value,
          ["document_value_others-" + _uniqueId]: document_value,
          ["name-" + _uniqueId]: guest.name,
          ["email-" + _uniqueId]: guest.email,
          ["phone-" + _uniqueId]: guest.phone
            ? maskFunctions.phone.mask(guest.phone)
            : null,
          uniqueId: _uniqueId,
        };
      });

      for (const guest of _guests) {
        const _uniqueId = guest.uniqueId;

        for (const field of guestsFields) {
          setValue(field + "-" + _uniqueId, guest[field + "-" + _uniqueId]);
        }

        _guestsGroups.push(generateGuestGroup(guest.uniqueId));
      }

      setGuestsGroups(_guestsGroups);
    }
  }, [defaultData, isDoneCheckIn, isOpenCheckIn]);

  const groupsReservations: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "reservation_stays_id_name",
            label: "Reserva",
            type: "input",
            getIsDisabled: () => !!defaultData?.reservation_stays_id,
          },
          {
            name: "accommodation_stays_id_name",
            label: "Acomodação",
            type: "input",
            getIsDisabled: () => !!defaultData?.reservation_stays_id,
          },
        ],
        [
          {
            name: "check_in",
            label: "Entrada",
            type: "input",
            getIsDisabled: () => !!defaultData?.reservation_stays_id,
          },
          {
            name: "check_out",
            label: "Saída",
            type: "input",
            getIsDisabled: () => !!defaultData?.reservation_stays_id,
          },
        ],
      ],
    },
  ];

  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++)
      inputs[i].setAttribute("autocomplete", "new-password");
  }, [_form.access_type]);

  return (
    <>
      <Form onSubmit={handleStopPropagation} style={{ paddingBottom: 20 }}>
        <GenericForm
          ref={form_ref}
          groups={groupsReservations}
          _form={_form}
          control={control}
          trigger={trigger}
          errors={errors}
          setValue={setValue}
          register={register}
        />


        {(!isDoneCheckIn && !isOpenCheckIn) && (
          <>
            <Alert icon={<FiAlertCircle size="1rem" />} title="Check-in não realizado!" style={{
              backgroundColor: theme === "dark" ? 'rgba(224, 49, 49, 0.2)' : 'rgb(250, 82, 82, 0.1)', // Cor de fundo personalizada
              color: theme === "dark" ? 'rgb(255, 201, 201)' : 'rgb(250, 82, 82)'
            }}>
              <Text color={theme === "dark" ? '#fff' : '#000'}>Clique no botão abaixo para iniciar o check-in</Text>
            </Alert>

            <Button
              onClick={() => handleStartNewCheckIn({ reservation_stays_id: defaultData })}
              background={secondary[theme]}
              color={"#FFF"}
              style={{ padding: 8, maxWidth: 200, fontSize: 14 }}
              children="Realizar Check-In"
            />
          </>
        )}

        {(isDoneCheckIn || isOpenCheckIn) && (
          <FormCheckIn
            guestsDocumentsRefs={guestsDocumentsRefs}
            _form={_form}
            addGuestGroup={addGuestGroup}
            buttonSubmitRef={buttonSubmitRef}
            guestsGroups={guestsGroups}
            getGuests={getGuests}
            guestsRefs={guestsRefs}
            loadingSave={loadingSave}
            removeOwnerGroup={removeOwnerGroup}
            control={control}
            errors={errors}
            trigger={trigger}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            register={register}
          />
        )}
      </Form>

      <ModalLoading loading={loadingSave} theme={theme} />
    </>
  );
};
