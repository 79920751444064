import { getAtualDate } from '../utils';
import { Line, Column } from './../style';
import { secondary } from '../../../theme';
import { useCallback, useRef } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { useGlobal } from '../../../hooks/global';
import { MdOutlineHistory } from 'react-icons/md';
import { CircularProgress } from '@material-ui/core';
import { ContainerLastUpdate, Title } from './style';
import { correctDateDashboard } from '../../../services/generalServices';
import { DropdownButton } from '../../../components/Buttons/DropdownButton';
import { RangePicker, RangePickerRefProps } from '../../../components/RangePicker';

export interface IDashboardHeaderProps {
    loading: boolean;
    lastUpdate: any;
    onSearch: () => void;
    range: { from: any; to: any };
    setRange: React.Dispatch<React.SetStateAction<{
        from: any;
        to: any;
    }>>
}

const history_options = [
    { text: "Hoje", id: "0" },
    { text: "Últimos 7 dias", id: "6" },
    { text: "Últimos 15 dias", id: "14" },
    { text: "Últimos 30 dias", id: "29" },
    { text: "Período completo", id: "all" },
];

const HISTORY_CLASS = "dropdown-button-history";

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({ loading, lastUpdate, onSearch, range, setRange }) => {
    const { theme, menuOpen, setOpenSelect } = useGlobal();

    const range_picker_ref = useRef<RangePickerRefProps>(null);

    const iconHistory = (
        <MdOutlineHistory
            size={20}
            className={HISTORY_CLASS}
            onClick={() => setOpenSelect(HISTORY_CLASS)}
        />
    );

    const handleClickInHistory = useCallback(
        async (from) => {
            range_picker_ref.current?.applyRange({
                from,
                to: getAtualDate({ daysBefore: 0 }),
            });
        },
        [range_picker_ref]
    );

    const handleOpenSelectHistory = useCallback(
        (value: string) => {
            if (value !== "all") {
                handleClickInHistory(getAtualDate({ daysBefore: parseInt(value) }));
            }
            else handleClickInHistory({ day: 1, month: 1, year: 2024 });
        },
        [range_picker_ref]
    );

    return (
        <Title
            theme={theme}
            style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 20,
                justifyContent: "space-between",
            }}
        >
            <Column>
                <h4
                    style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    Dashboard
                </h4>
                <ContainerLastUpdate menuIsOpen={menuOpen}>
                    {!loading ? (
                        <>
                            <div style={{ fontSize: 14, whiteSpace: "nowrap" }}>
                                Última atualização realizada em{" "}
                                {correctDateDashboard(lastUpdate)}
                            </div>
                            <div
                                onClick={onSearch}
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <AiOutlineReload />
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ fontSize: 14 }}>Atualizando dashboard</div>
                            <CircularProgress
                                size={10}
                                variant="indeterminate"
                                style={{ color: secondary[theme] }}
                            />
                        </>
                    )}
                </ContainerLastUpdate>
            </Column>
            <Line style={{ gap: 10, flexWrap: "wrap" }}>
                <div style={{ minHeight: "100%" }}>
                    <RangePicker
                        onChange={(newRange: any) => setRange({ ...newRange })}
                        initialRange={range}
                        disabled={loading}
                        emptyMessage="Selecione um período"
                        noRemove
                        ref={range_picker_ref}
                    />
                </div>
                <DropdownButton
                    className={HISTORY_CLASS}
                    options={history_options}
                    icon={iconHistory}
                    loading={loading}
                    main_option={{ text: "", id: HISTORY_CLASS }}
                    onClick={handleOpenSelectHistory}
                    theme={theme}
                    zIndex={900}
                    style={{ height: 38 }}
                />
            </Line>
        </Title>
    );
}