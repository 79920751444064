import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormReservation } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { documentTypesOptions } from "../../../utils/options";
import { correctDate2, correctDateDashboard } from "../../../services/generalServices";
import { maskFunctions } from "../../../services/maskServices";
import { Guest } from "../../../interfaces";

interface IModalReservationsProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Reserva",
    edit: "Informações da Reserva",
  },
  subTitle: {
    new: "Informe os dados da reserva",
    edit: "Segue abaixo as informações da reserva",
  },
};

export const ModalReservations: React.FC<IModalReservationsProps> = (props) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const [isDoneCheckIn, setIsDoneCheckIn] = useState(false);

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>({});

  const searchReservation = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const { data } = await api.get(`/stays-reservations/${defaultId}`);
        const _data = {} as any;
        const checkInDate = String(data.check_in_date).split("T")[0];
        const checkInTime = String(data.check_in_time);
        const checkIn = correctDateDashboard(
          new Date(checkInDate + " " + checkInTime)
        );

        const checkOutDate = String(data.check_out_date).split("T")[0];
        const checkOutTime = String(data.check_out_time);
        const checkOut = correctDateDashboard(
          new Date(checkOutDate + " " + checkOutTime)
        );

        const guestPrimary = data.guests?.find((guest: { primary: boolean }) => guest.primary);

        _data.reservation_stays_id = data.id;
        _data.reservation_stays_id_name = data.id + ' - ' + guestPrimary.name;
        _data.accommodation_stays_id = data.accommodation_stays_id.value;
        _data.accommodation_stays_id_name = data.accommodation_stays_id.value + ' - ' + data.accommodation.name;
        _data.check_in = checkIn;
        _data.check_out = checkOut;
        _data.password = data.password;
        _data.check_in_id = data.check_in?.id;
        _data.guests = data.guests;

        setDefaultData({ ..._data });
      } catch (err) {
        console.log(err)
        notify("Erro ao buscar a reserva", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  const searchCheckIn = useCallback(async () => {
    if (defaultData.check_in_id) {
      setLoading(true);
      try {
        const { data } = await api.get(`/check-ins/${defaultData.check_in_id}`);
        const _data = {} as any;
        _data.id = data.id;

        for (const guest of data.guests) {
          const documentType = guest.document_type;
          const uniqueId = guest.id;

          _data["name-" + uniqueId] = guest.name;
          _data["email-" + uniqueId] = guest.email;
          _data["phone-" + uniqueId] = maskFunctions.phone.mask(guest.phone);
          _data["birth-" + uniqueId] = correctDate2(guest.birth);
          _data["document_type-" + uniqueId] = documentTypesOptions.find(
            (item) => item.value === documentType
          );
          if (documentType === "cpf") {
            _data["document_value_cpf-" + uniqueId] = maskFunctions.cpf.mask(
              guest.document_value
            );
          }
          _data["document_value_others-" + uniqueId] = guest.document_value;
          _data["document-front-" + uniqueId] = guest.document_image_front;
          _data["document-back-" + uniqueId] = guest.document_image_back;
          _data["document-selfie-" + uniqueId] = guest.selfie_image;

        }
        _data.guests = data.guests;
        setDefaultData((prevData: any) => ({
          ...prevData,
          ..._data,
        }));
      } catch (err) {
        notify("Erro ao buscar a check-in", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultData.check_in_id, defaultData]);

  useEffect(() => {
    searchReservation();
  }, []);

  useEffect(() => {
    searchCheckIn();
  }, [defaultData.check_in_id]);

  useEffect(() => {
    if (defaultData?.check_in_id) {
      setIsDoneCheckIn(true);
    }
  }, [defaultData]);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormReservation
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              defaultGuests={defaultData?.guests?.map((guest: any) => ({
                ...guest,
                uniqueId: guest.id,
              }))}
              isDoneCheckIn={isDoneCheckIn}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
