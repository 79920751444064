import * as React from "react";
import { primary } from "../../../../theme";

export const PhoneIcon = ({ size, color = "#AEAEAE", ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9 5h8M9 5c-2.636.002-4.017.036-5.088.581a5.333 5.333 0 0 0-2.33 2.331C1 9.052 1 10.546 1 13.533v1.6c0 2.987 0 4.48.581 5.622a5.334 5.334 0 0 0 2.331 2.33c1.14.582 2.634.582 5.621.582h6.934c2.987 0 4.48 0 5.62-.582a5.334 5.334 0 0 0 2.332-2.33C25 19.614 25 18.12 25 15.133v-1.6c0-2.987 0-4.48-.581-5.62a5.333 5.333 0 0 0-2.331-2.332c-1.07-.545-2.452-.579-5.088-.58M9 5V3.666A2.667 2.667 0 0 1 11.667 1h2.666A2.667 2.667 0 0 1 17 3.667V5m0 9.333a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
    />
  </svg>
);
