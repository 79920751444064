import Button from "../../components/Buttons/Button";
import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Column, Line } from "../../style";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useForm } from "react-hook-form";
import { useGlobal } from "../../hooks/global";
import { H4 } from "../../components/HeaderList/style";
import { maskFunctions } from "../../services/maskServices";
import { DashboardPage } from "../../components/DashboardPage";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { enabledDisabledOptions } from "./options/enabledDisabledOptions";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { GenericForm, IGenericFormRefProps } from "../../components/GenericForm";
import { fieldTypes, IFieldProps, IGroupProps, MaskTypes } from "../../interfaces";

interface IConstantsMapper {
  label: string,
  description: string,
  type: MaskTypes,
  inputType?: fieldTypes,
  options?: {
    label: string;
    value: number | string;
  }[]
}

const CONSTANTS_MAPPER: { [key: string]: IConstantsMapper } = {
  DAYS_TO_ABLE_CHECK_IN: {
    label: "Dias para habilitar check-in",
    description: "Dias para habilitar o check-in antes da data de entrada",
    type: 'onlyNumber'
  },
  DAYS_TO_RESEND_NOTIFICATION: {
    label: "Dias para enviar notificações em atraso",
    description: "Caso uma notificação não seja enviada, qual o máximo de dias que ela ainda poderá ser enviada",
    type: 'onlyNumber'
  },
  ENABLED_FACE_MATCH_CHECK_IN_USER: {
    label: "Face-match no módulo web check-in do usuário",
    description: "Habilitar ou desabilitar Face-match no módulo web check-in do usuário",
    type: 'onlyNumber',
    inputType: 'select-fixed',
    options: enabledDisabledOptions
  },
  ENABLED_FACE_MATCH_CHECK_IN_ADM: {
    label: "Face-match no módulo de reserva do administrativo",
    description: "Habilitar ou desabilitar Face-match no ckeck-in no módulo de reserva do administrativo",
    type: 'onlyNumber',
    inputType: 'select-fixed',
    options: enabledDisabledOptions
  },
}

export const GeneralSettings: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)

  const { api, user } = useApi();
  const { notify, theme } = useGlobal();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: ({} as any),
    mode: "all",
  });

  const _form = watch();

  const searchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/constants");

      for (const key of Object.keys(data)) {
        const { type, inputType } = CONSTANTS_MAPPER?.[key] ?? {}
        if (!type) continue
        let value = data[key]

        if (type === 'porcen') value = maskFunctions.porcen.mask(value * 100) + ' %'

        if (inputType === 'select-fixed') value = enabledDisabledOptions.find((option) => option.value === value);

        setValue(key, value)
      }
    } catch (error) {
      console.log(error)
      notify("Erro ao buscar as configurações", 'error');
    }
    setLoading(false);
  }, [api])

  const onSubmit = useCallback(async () => {
    setLoadingSave(true);

    try {
      const form = form_ref.current?.getForm();

      for (const key of Object.keys(form ?? {})) {
        const { type } = CONSTANTS_MAPPER?.[key] ?? {}
        if (!type) continue
        if (type === 'text') {
          form[key] = form[key]?.trim()?.toLowerCase();
          continue;
        }
        let value = Number(form[key])
        if (type === 'porcen') value = value / 100
        form[key] = value
      }

      await api.put("/general-constants", form);

      notify('Configurações atualizadas com sucesso!', 'success');

    } catch (err: any) {
      console.log(err)
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingSave(false);
  }, [form_ref, _form, api, searchSettings]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        Object.entries(CONSTANTS_MAPPER).map(([key, { label, description, type, inputType, options }]) => ({
          name: key,
          label,
          type: inputType || "input",
          mask: type,
          required: true,
          tooltip: description,
          options: options
        }) as IFieldProps),
      ],
    },
  ];

  useEffect(() => { searchSettings() }, [])

  return (
    <DashboardPage>
      <Column style={{ marginBottom: 16 }}>
        <Line>
          <H4>Configurações Gerais</H4>
        </Line>
      </Column>
      <ContentOrLoading loading={loading} theme={theme}>
        <Column style={{ flex: 1, paddingTop: 20 }}>
          <ContainerForm open>
            <Form onSubmit={handleStopPropagation}>
              <GenericForm
                ref={form_ref}
                groups={groups}
                _form={_form}
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
                register={register}
                disabled={loadingSave || !user.user_type.permissions.includes('SETTINGS-UPDATE')}
              />
              <button className="display-none" type="submit" ref={submitButtonRef} />
            </Form>
          </ContainerForm>
        </Column>
        {user.user_type.permissions.includes('SETTINGS-UPDATE') && (
          <ContainerButtons style={{ marginBottom: -10, marginTop: 40 }} theme={theme}>
            <Button
              onClick={() => submitButtonRef.current?.click()}
              background={secondary[theme]}
              color={"#FFF"}
              style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
              children={"Salvar"}
            />
          </ContainerButtons>
        )}
        <ModalLoading loading={loadingSave} theme={theme} />
      </ContentOrLoading>
    </DashboardPage>
  );
};
