import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormUserType } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import {
  PERMISSIONS,
  permissionsOptionsByType,
} from "../../../utils/permissions";
import { IOptionsProps } from "../../../interfaces";

interface IModalUserTypeProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Tipo de Usuário",
    edit: "Informações do Tipo de Usuário",
  },
  subTitle: {
    new: "Informe os dados do tipo de usuário",
    edit: "Segue abaixo as informações do tipo de usuário",
  },
};

export const ModalUserType: React.FC<IModalUserTypeProps> = (props) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();
  const [accommodations, setAccommodations] = useState<IOptionsProps[]>([]);

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);

  const searchUserType = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const { data } = await api.get(`/user-types/${defaultId}`);
        const _data = {} as any;

        _data.id = data.id;
        _data.name = data.name;

        Object.keys(PERMISSIONS).forEach((key: any) => {
          const options = permissionsOptionsByType(key);
          _data[key] = options.filter((option) =>
            data.permissions.includes(option.value)
          );
        });

        _data.accommodations = data.accommodations.map((item: any) => ({
          label: item,
          value: item,
        }));

        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar o tipo de usuário", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  const searchAllAccommodations = useCallback(async () => {
    const { data } = await api.get("/accommodations/all");
    setAccommodations(data.map((item: any) => ({ label: item.id, value: item.id })));
  }, [])

  useEffect(() => {
    searchUserType();
    searchAllAccommodations()
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormUserType
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              accommodations={accommodations}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
