import Button from "../../../Buttons/Button";
import { uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../Tooltip";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { AddButton, Column } from "../../../../style";
import { BsTrash } from "react-icons/bs";
import { HiIdentification } from "react-icons/hi";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { maskFunctions } from "../../../../services/maskServices";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import {
  validateCPF,
  validateEmail,
} from "../../../../utils/validatesFields";
import {
  ButtonsContainer,
  ContainerButtonAdd,
  ContainerGroups,
  ContainerRemove,
  ContainerGuests,
  DescriptionGuests,
  EmptyText,
  Form,
  Group,
  HeaderGuests,
  TitleGuests,
  ContainerTooltip,
  ContainerIcon,
} from "./style";
import { FaBook, FaUsers } from "react-icons/fa";
import { DocumentOrPicture } from "../documentOrPicture";

interface IFormCheckInModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData: any;
  defaultGuests?: any[];
}


const guestsFields = [
  "name",
  "email",
  "phone",
  // "birth",
  "document_type",
  "document_value_cpf",
  "document_value_others",
];

export const FormCheckIn: React.FC<IFormCheckInModalProps> = (props) => {
  const { onCancel, onSave, defaultData, defaultGuests } = props;

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
    unregister,
  } = useForm({
    defaultValues: defaultData as any,
    mode: "all",
  });

  const _form = watch();

  const { api, consultsServices } = useApi();
  const { theme, notify } = useGlobal();

  const generateGuestGroup = (defaultId?: string) => {
    const _uniqueId = defaultId ? defaultId : uniqueId();

    const newGuestGroup: IGroupProps = {
      name: "",
      label: "",
      uniqueId: _uniqueId,
      fields: [
        [
          {
            name: "name-" + _uniqueId,
            label: "Nome",
            type: "input",
            required: true,
            get_enabled_change_form_type: () => false,
          },
          {
            name: "email-" + _uniqueId,
            label: "Email",
            type: "input",
            validate: validateEmail,
            required: true,
          },
        ],
        [
          {
            name: "document_type-" + _uniqueId,
            label: "Tipo do Documento",
            type: "select-fixed",
            isClearable: true,
            options: [
              { label: "CPF", value: "cpf" },
              { label: "Passaporte", value: "passport" },
            ],
            required: true,
          },
          {
            name: "document_value_cpf-" + _uniqueId,
            label: "Documento",
            type: "input",
            mask: "cpf",
            required: true,
            validate: validateCPF,
            getIsDisabled: (data) => !data["document_type-" + _uniqueId]?.value,
            canSee: (data) =>
              data["document_type-" + _uniqueId]?.value === "cpf",
          },
          {
            name: "document_value_others-" + _uniqueId,
            label: "Documento",
            type: "input",
            required: true,
            getIsDisabled: (data) => !data["document_type-" + _uniqueId]?.value,
            canSee: (data) =>
              data["document_type-" + _uniqueId]?.value !== "cpf",
          },
          {
            name: "phone-" + _uniqueId,
            label: "Telefone",
            type: "input",
            mask: "phone",
            required: true,
          }
        ],
      ],
    };

    return newGuestGroup;
  };

  const [loadingSave, setLoadingSave] = useState(false);
  const [guestsGroups, setGuestsGroups] = useState<IGroupProps[]>(() => {
    if (defaultGuests && defaultGuests.length > 0) {
      const _guestsGroups: any[] = [];

      const _defaultDataGuests = defaultGuests ?? [];
      _defaultDataGuests.forEach((executor: any) => {
        _guestsGroups.push(generateGuestGroup(executor.uniqueId));
      });

      return _guestsGroups;
    }
    return [];
  });

  const form_ref = useRef<IGenericFormRefProps>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const guestsRefs = useRef<IGenericFormRefProps[]>([]);

  const onSubmit = useCallback(async () => {
    setLoadingSave(true);

    try {
      const form = form_ref.current?.getForm();

      const guests = getGuests();

      if (defaultData?.id) {
        await api.put(`/check-ins/${defaultData?.id}`, { guests, ...form });
      } else {
        await api.post("/check-ins", { guests, ...form });
      }

      if (defaultData?.id) notify("Check-in atualizado com sucesso", "success");
      else notify("Check-in criado com sucesso", "success");

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else if (error.title === "Recurso não encontrado")
        setError("reservation_stays_id", {
          type: "manual",
          message: "A acomodação dessa reserva não foi encontrada!",
        });
      else if (error.title === "Recurso já existe")
        setError("reservation_stays_id", {
          type: "manual",
          message: "Check-in já cadastrado para essa reserva!",
        });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingSave(false);
  }, [form_ref, _form, api, guestsRefs, defaultData]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api, guestsRefs]
  );

  const handleChangeReservation = useCallback(async (data: any) => {
    const { reservation_stays_id } = data;
    if (!reservation_stays_id) {
      setGuestsGroups((groups) => {
        for (const group of groups)
          guestsFields.forEach((field) =>
            unregister(field + "-" + group.uniqueId)
          );
        return [];
      });
    } else {
      const _guestsData = reservation_stays_id?.guests ?? [];
      const _guestsGroups: any[] = [];

      const _guests = _guestsData.map((guest: any) => {
        const _uniqueId = uniqueId();
        const label = guest?.cpf ? "CPF" : guest?.rg ? "RG" : "Passaporte";
        const value = guest?.cpf ? "cpf" : guest?.rg ? "rg" : "passport";
        let document_value = guest?.cpf || guest?.rg || guest?.passport;
        // const birth = guest?.birth?.split("-")?.reverse()?.join("/");

        return {
          ["document_type-" + _uniqueId]: { label, value },
          ["document_value_cpf-" + _uniqueId]:
            value === "cpf"
              ? maskFunctions.cpf.mask(document_value)
              : document_value,
          ["document_value_others-" + _uniqueId]: document_value,
          ["name-" + _uniqueId]: guest.name,
          ["email-" + _uniqueId]: guest.email,
          ["phone-" + _uniqueId]: guest.phone
            ? maskFunctions.phone.mask(guest.phone)
            : null,
          // ["birth-" + _uniqueId]: birth ? maskFunctions.date.mask(birth) : null,
          uniqueId: _uniqueId,
        };
      });

      for (const guest of _guests) {
        const _uniqueId = guest.uniqueId;

        for (const field of guestsFields) {
          setValue(field + "-" + _uniqueId, guest[field + "-" + _uniqueId]);
        }

        _guestsGroups.push(generateGuestGroup(guest.uniqueId));
      }

      setGuestsGroups(_guestsGroups);
    }
  }, []);

  const getGuests = useCallback(() => {
    const _refs = guestsRefs.current.filter((ref) => ref?.getForm());
    const guests = _refs.map((ref) => {
      const form = ref?.getForm();
      const _uniqueId = form?.uniqueId;
      const document_type = form?.["document_type-" + _uniqueId];
      const document_value =
        document_type === "cpf"
          ? form?.["document_value_cpf-" + _uniqueId]
          : form?.["document_value_others-" + _uniqueId];

      return {
        name: _form?.["name-" + _uniqueId],
        email: form?.["email-" + _uniqueId],
        phone: form?.["phone-" + _uniqueId],
        // birth: form?.["birth-" + _uniqueId],
        document_type,
        document_value,
        document_image_front: _form?.["document-front-" + _uniqueId],
        document_image_back: _form?.["document-back-" + _uniqueId],
        selfie_image: _form?.["document-selfie-" + _uniqueId],
        uniqueId: _uniqueId,
      };
    });

    return guests;
  }, [guestsRefs, _form]);

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "reservation_stays_id",
            label: "Reserva",
            type: "select-single-no-creatable",
            isClearable: true,
            required: true,
            getIsDisabled: () => !!defaultData?.id,
            searchOptions: consultsServices.reservation_stays_id,
            executeOnChange: async (data: any) => {
              handleChangeReservation({ reservation_stays_id: data?.this });
            },
          },
        ],
      ],
    },
  ];

  const addGuestGroup = useCallback(() => {
    const newGuestGroup = generateGuestGroup();
    setGuestsGroups((prev) => {
      return [...prev, newGuestGroup];
    });
  }, [uniqueId]);

  const removeOwnerGroup = useCallback((index: number) => {
    setGuestsGroups((prev) => {
      return prev.filter(({ uniqueId }, i) => {
        if (i !== index) return true;
        else
          guestsFields.forEach((field) => unregister(field + "-" + uniqueId));
      });
    });
  }, []);

  return (
    <>
      <Form onSubmit={handleStopPropagation} style={{ paddingBottom: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: 20,
          }}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              register={register}
              containerStyle={{ width: "100%" }}
            />
          </div>
          {_form?.reservation_stays_id?.value && (
            <ContainerTooltip>
              <Tooltip
                title={"Abrir reserva na Stays"}
                placement="top"
                disableTouchListener
                interactive={false}
                style={{ margin: 0, padding: 0 }}
                children={
                  <ContainerIcon
                    onClick={() =>
                      window.open(
                        `https://mgli.stays.net/i/reservation/${_form?.reservation_stays_id?.value}`,
                        "_blank"
                      )
                    }
                    theme={theme}
                    children={<FaBook color={"#FFF"} size={20} />}
                  />
                }
              />
            </ContainerTooltip>
          )}
        </div>
        <ContainerGuests theme={theme}>
          <HeaderGuests theme={theme}>
            <FaUsers size={24} color={secondary[theme]} />
            <Column>
              <TitleGuests>Hóspedes</TitleGuests>
              <DescriptionGuests>Informe os dados dos hóspedes</DescriptionGuests>
            </Column>
          </HeaderGuests>

          <ContainerGroups>
            {guestsGroups.map((guest_group, index) => (
              <Group key={guest_group.uniqueId}>
                {guestsGroups.length > 1 && (
                  <ContainerRemove>
                    <Tooltip
                      onClick={() => removeOwnerGroup(index)}
                      style={{ height: 36, width: 36 }}
                      title="Excluir"
                      children={
                        <div>
                          <BsTrash color={inactiveItens[theme]} size={20} />
                        </div>
                      }
                    />
                  </ContainerRemove>
                )}
                <Column style={{ flex: 1 }}>
                  <GenericForm
                    ref={(ref: any) => (guestsRefs.current[index] = ref)}
                    groups={[guest_group]}
                    _form={_form}
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    setValue={setValue}
                    register={register}
                  />
                </Column>
              </Group>
            ))}
            {guestsGroups.length === 0 && (
              <EmptyText>Nenhum hóspede informado</EmptyText>
            )}
            {_form?.reservation_stays_id?.value && (
              <ContainerButtonAdd>
                <AddButton
                  noHaveDelete={!(guestsGroups.length > 1)}
                  onClick={addGuestGroup}
                >
                  Incluir+
                </AddButton>
              </ContainerButtonAdd>
            )}
          </ContainerGroups>
        </ContainerGuests>

        {guestsGroups.map((guest_group) => {
          return (
            <ContainerGuests key={guest_group.uniqueId} theme={theme}>
              <HeaderGuests theme={theme}>
                <HiIdentification size={24} color={secondary[theme]} />
                <Column>
                  <TitleGuests>{`Documentos e selfie - [${_form[`name-${guest_group.uniqueId}`]?.toUpperCase()?.trim()}]`}</TitleGuests>
                  <DescriptionGuests>Informe os documentos e selfie do hóspede</DescriptionGuests>
                </Column>
              </HeaderGuests>

              <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 20, padding: 20, flexWrap: 'wrap' }}>
                <DocumentOrPicture
                  onChange={(newFile) => setValue(`document-front-${guest_group.uniqueId}`, newFile)}
                  defaultDocument={_form[`document-front-${guest_group.uniqueId}`]}
                  name="Documento Frente"
                  type="document"
                  guest={_form[`name-${guest_group.uniqueId}`]}
                />
                <DocumentOrPicture
                  onChange={(newFile) => setValue(`document-back-${guest_group.uniqueId}`, newFile)}
                  defaultDocument={_form[`document-back-${guest_group.uniqueId}`]}
                  name="Documento Verso"
                  type="document"
                  guest={_form[`name-${guest_group.uniqueId}`]}
                />
                <DocumentOrPicture
                  onChange={(newFile) => setValue(`document-selfie-${guest_group.uniqueId}`, newFile)}
                  defaultDocument={_form[`document-selfie-${guest_group.uniqueId}`]}
                  name="Selfie"
                  type="selfie"
                  guest={_form[`name-${guest_group.uniqueId}`]}
                />
              </div>

            </ContainerGuests>
          );
        })}

        <button
          ref={buttonSubmitRef}
          type="submit"
          style={{ display: "none" }}
        />
      </Form>

      <ButtonsContainer theme={theme}>
        <Button
          onClick={() => onCancel()}
          background={inactiveItens[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children="Voltar"
        />
        <Button
          onClick={() => buttonSubmitRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children="Salvar"
        />
      </ButtonsContainer>

      <ModalLoading loading={loadingSave} theme={theme} />
    </>
  );
};
