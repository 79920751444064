import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { TbRotateClockwise } from "react-icons/tb";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { info, secondary, success } from "../../theme";
import { getFilterFields } from "../../utils/filterFields";
import { FaBook, FaEdit, FaWhatsapp } from "react-icons/fa";
import { MdDelete, MdOutlineEmail } from "react-icons/md";
import { IPermissionsTypes } from "../../utils/permissions";
import { ModalSendWpp } from "../../components/Modals/ModalSendWpp";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalSendEmail } from "../../components/Modals/ModalSendEmail";
import { getCheckStatus, getGuests, getStatus } from "../../utils/columnsTables";
import { IStaysReservationProps, IActions, IFilters } from "../../interfaces/index";
import { reservation_status, payment_status } from "../../utils/reservation_status";
import { ModalReservations } from "../../components/Modals/ModalReservations/index";
import { correctDate, correctDateDashboard, setUpConsultation } from "../../services/generalServices";
import { FiDownload } from "react-icons/fi";

const initialFilters: IFilters[] = [
  getFilterFields("order_by"),
  getFilterFields("reservation_status"),
  getFilterFields("payment_status"),
  getFilterFields("check_in_status"),
  getFilterFields("check_in"),
  getFilterFields("check_out"),
  getFilterFields("created_at"),
];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "id",
      name: "ID",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "guests",
      name: "Hóspedes",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14 },
    },
    {
      attr: "accommodation_id",
      name: "Acomodação",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "reservation_status",
      name: "Status da Reserva",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14 },
    },
    {
      attr: "payment_status",
      name: "Status do Pagamento",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14 },
    },
    {
      attr: "checkIn",
      name: "Entrada",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "checkOut",
      name: "Saída",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "check_in_status",
      name: "Status do Check-in",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14 },
    },
    {
      attr: "origin",
      name: "Origem",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "createdAt",
      name: "Criado em",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
  ],
};

export const propertyInExtension = (address: any) => {
  if (!address) return "-";
  return `${address?.street}, ${address?.region}, Nº ${address?.streetNumber}, ${address?.city} - ${address?.stateCode}`;
};

export const ReservationsList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [loading, setLoading] = useState(false);
  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [numOfReservations, setNumOfReservations] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingReservations, setLoadingReservations] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [reservationSelected, setReservationSelected] =
    useState<IStaysReservationProps | null>(null);
  const [table, setTable] = useState<ITable>(initialTable);
  const [selectedCheckInSendEmail, setSelectedCheckInSendEmail] = useState<IStaysReservationProps | null>(null);
  const [selectedCheckInSendWpp, setSelectedCheckInSendWpp] = useState<IStaysReservationProps | null>(null);

  const selectReservation = useCallback(
    (accomoddation: IStaysReservationProps) => {
      setReservationSelected({ ...accomoddation });
      setOpenModal(true);
    },
    []
  );

  const searchReservations = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingReservations(true);
      try {
        const filters_obj = _filters ? _filters : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/stays-reservations?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_reservations = result.data.data as IStaysReservationProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_reservations.forEach((reservation) => {
            const checkInDate = String(reservation.check_in_date).split("T")[0];
            const checkInTime = String(reservation.check_in_time);
            const checkIn = correctDateDashboard(
              new Date(checkInDate + " " + checkInTime)
            );

            const checkOutDate = String(reservation.check_out_date).split(
              "T"
            )[0];
            const checkOutTime = String(reservation.check_out_time);
            const checkOut = correctDateDashboard(
              new Date(checkOutDate + " " + checkOutTime)
            );
            new_table.data.push({
              id: reservation.id,
              guests: getGuests({ guests: reservation?.guests ?? [], theme }),
              accommodation_id: reservation.accommodation_stays_id,
              reservation_status: getStatus({
                ...reservation_status[reservation.reservation_status],
                theme,
              }),
              payment_status: getStatus({
                ...payment_status[reservation.payment_status],
                theme,
              }),
              check_in_status: getCheckStatus({
                theme,
                text: reservation?.check_in?.id ? "Realizado" : "Pendente",
                color: reservation?.check_in?.id ? success : info,
              }),
              checkIn,
              checkOut,
              origin: reservation.origin,
              createdAt: correctDate(new Date(reservation.createdAt), {
                no_have_time: true,
              }),
              this: reservation,
            });
          });
          return { ...new_table };
        });

        setNumOfReservations(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas reservas!", "error");
      }
      setLoadingReservations(false);
    },
    [user, filters, searchString]
  );

  const openModalNewReservation = useCallback(() => {
    setReservationSelected(null);
    setOpenModal(true);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewReservation();
  };

  const actionsInitial: IActions[] = [];

  const handleRecreateCheckIn = useCallback(
    async (id: string) => {
      setLoading(true);

      try {
        await api.post(`/check-ins/create-new-password/${id}`);
        notify("Check-in recriado com sucesso!", "success");
        searchReservations(1);
      } catch (error: any) {
        notify("Erro ao recriar o check-in!", "error");
      }

      setLoading(false);
    },
    [api]
  );

  const handleUpdateReservation = useCallback(async (id: string) => {
    setLoading(true)

    try {
      await api.post(`/stays-reservations/${id}`)
      notify("Reserva atualizada com sucesso!", "success")
      searchReservations(1);
    } catch (err) {
      notify("Erro ao atualizar a reserva!", "error")
    }

    setLoading(false)
  }, []);

  const confirmRecreateCheckIn = useCallback(
    async (item: any): Promise<void> => {
      const result = window.confirm(
        "Tem certeza que deseja recriar o check-in da reserva " +
        item.id
      );

      if (result) handleRecreateCheckIn(item.check_in_id as unknown as string);
    },
    [handleRecreateCheckIn]
  );

  const handleConfirmDeleteCheckIn = useCallback(
    async (id: string) => {
      setLoading(true);

      try {
        await api.delete(`/check-ins/${id}`);
       
        notify("Check-in excluído com sucesso!", "success");
       
        await searchReservations(1);
      } catch (error: any) {
        const default_message = "Erro ao excluir a check-in!";
        const message = error.response?.data?.detail ?? default_message;
        notify(message, message === default_message ? "error" : "alert");
      }

      setLoading(false);
    },
    [searchReservations]
  );

  const alertDelete = useCallback(
    async (item: any): Promise<void> => {

      const result = window.confirm(
        "Tem certeza que deseja excluir o check-in da reserva " +
        item.id
      );

      if (result) handleConfirmDeleteCheckIn(item.check_in_id as unknown as string);
    },
    [handleConfirmDeleteCheckIn]
  );


  const listAction = [
    {
      title: "Editar",
      onClick: (reservation: IStaysReservationProps) => selectReservation(reservation),
      icon: <FaEdit color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
    {
      title: "Abrir reserva na Stays",
      onClick: (item: any) =>
        window.open(
          `https://mgli.stays.net/i/reservation/${item.id}`,
          "_blank"
        ),
      icon: <FaBook color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
    {
      title: "Atualizar reserva",
      onClick: (item: any) => handleUpdateReservation(item.id),
      icon: <TbRotateClockwise color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes('STAYS-RESERVATIONS-UPDATE'),
    },
    {
      title: "Enviar email",
      onClick: (item: any) => setSelectedCheckInSendEmail(item),
      icon: <MdOutlineEmail color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("SEND_EMAILS" as IPermissionsTypes),
    },
    {
      title: "Enviar WhatsApp",
      onClick: (item: any) => setSelectedCheckInSendWpp(item),
      icon: <FaWhatsapp color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("SEND_WPP" as IPermissionsTypes),
    },
    {
      title: "Recriar check-in",
      onClick: confirmRecreateCheckIn,
      icon: <TbRotateClockwise color={secondary[theme]} size={20} />,
      disabled: (item: any) => (
         !user.user_type.permissions.includes("STAYS-RESERVATIONS-UPDATE" as IPermissionsTypes)
         || !item?.check_in_id   
      ),
    },
    {
      title: "Excluir check-in",
      onClick: (item: any) => alertDelete(item),
      icon: <MdDelete color={secondary[theme]} size={20} />,
      disabled: (item: any) => (
        !user.user_type.permissions.includes("STAYS-RESERVATIONS-CHECK-INS-DELETE" as IPermissionsTypes)
       || !item?.check_in_id 
      ),
    },
    {
      title: "Baixar ficha dos hóspedes",
      onClick: (item: any) => window.open(process.env.REACT_APP_API + '/pdf/' + item.id, '_blank'),
      icon: <FiDownload color={secondary[theme]} size={20} />,
      disabled: () => false,
    }
  ];
  
  const onSaveReservation = useCallback(() => {
    setOpenModal(false);
    searchReservations(1);
  }, [user, filters, searchString]);

  useEffect(() => {
    searchReservations(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingReservations}
      setLoading={setLoadingReservations}
      numberOfItems={numOfReservations}
      actions={actionsInitial}
      onClickOption={handleAction}
      textHeader="Reservas"
      textItems="reservas encontradas"
      text_loading={"Buscando Reservas"}
      text_empty={"Nenhuma reserva encontrada"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      pages={numberOfPages}
      page={atualPage}
      search={searchReservations}
      onChangeSearch={setSearchString}
      listActions={listAction}
    >
      {openModal && (
        <ModalReservations
          onCancel={() => setOpenModal(false)}
          onSave={onSaveReservation}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={reservationSelected?.id}
        />
      )}
      {loading && <ModalLoading loading={loading} theme={theme} />}
      {selectedCheckInSendWpp?.id && (
        <ModalSendWpp
          onCancel={() => setSelectedCheckInSendWpp(null)}
          onSave={() => setSelectedCheckInSendWpp(null)}
          openModal={!!selectedCheckInSendWpp?.id}
          id={selectedCheckInSendWpp?.id as any}
        />
      )}
      {selectedCheckInSendEmail && (
        <ModalSendEmail
          onCancel={() => setSelectedCheckInSendEmail(null)}
          onSave={() => setSelectedCheckInSendEmail(null)}
          openModal={!!selectedCheckInSendEmail}
          id={selectedCheckInSendEmail.id as any}
        />
      )}
    </PageList>
  );
};
