export const BadQuality = ({ size = 56, color = "#DE4444", strokeColor = "#fff", strokeWidth = 2, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 56 56"
    {...props}
  >
    <path
      fill={color}
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="m19.602 36.4 16.8-16.8M36.402 36.4l-16.8-16.8"
    />
  </svg>
);

