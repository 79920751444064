import styled, { css } from "styled-components";
import { Column, Line } from "../../../../style";
import { IThemeProps, themeTypes } from "../../../../interfaces";
import {
  borderColors,
  divider,
  inactiveItens,
  primary,
  secondary,
} from "../../../../theme";

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
`;

interface IContainerProps extends IThemeProps {
  disabled?: boolean;
}

export const ContainerIcon = styled(Line)<IContainerProps>`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  border-radius: 4px;
  background: ${(props) => secondary[props.theme as themeTypes]};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: default;
    `}
`;

export const ContainerTooltip = styled.div<IContainerProps>`
  ${(props) =>
    props.disabled &&
    css`
      span:nth-child(2) {
        display: none;
      }
    `}
`;

export const ButtonsContainer = styled.div<IThemeProps>`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  ${(props) => css`
    border-top: 1px solid ${divider[props.theme as themeTypes]};
  `}
`;

export const ContainerGuests = styled(Column)<IThemeProps>`
  border-radius: 5px;
  background: ${(props) => primary[props.theme as themeTypes]};
  border: 1px solid ${(props) => borderColors[props.theme as themeTypes]};
`;

export const HeaderGuests = styled(Line)`
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${(props) => borderColors[props.theme as themeTypes]};
`;

export const TitleGuests = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const DescriptionGuests = styled.div`
  font-size: 13px;
  margin-top: -5px;
`;

export const EmptyText = styled.div`
  text-align: center;
  font-weight: 600;
  opacity: 0.7;
`;

export const ContainerButtonAdd = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ButtonAdd = styled.div<IThemeProps>`
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  color: ${(props) => inactiveItens[props.theme as themeTypes]};
`;

export const ContainerRemove = styled(Line)`
  align-items: flex-start;
  margin-top: 24px;
  min-width: 36px;
`;

export const ContainerGroups = styled(Column)`
  gap: 20px;
  padding: 20px;
`;

export const Group = styled(Line)`
  gap: 10px;
  flex: 1;
`;
