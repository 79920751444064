import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ContainerGuests, DescriptionGuests, HeaderGuests, TitleGuests } from "../checkInForm/style";
import { Flex } from "@mantine/core";
import { HiIdentification } from "react-icons/hi";
import { Column } from "../../../../style";
import { secondary } from "../../../../theme";
import BiometricsStatus from "../../../BiometricsStatus";
import { DocumentOrPicture } from "../../ModalCheckIn/documentOrPicture";
import { IGroupProps } from "../../../../interfaces";
import { useGlobal } from "../../../../hooks/global";
import { UseFormClearErrors, UseFormSetError, UseFormSetValue } from "react-hook-form";
import { useApi } from "../../../../hooks/api";

interface IGuestsDocumentsCardProps {
    guestGroup: IGroupProps;
    _form: any;
    setValue: UseFormSetValue<any>;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
    ref: any
}

export const GuestsDocumentsCard = forwardRef((props: IGuestsDocumentsCardProps, ref) => {
    const { guestGroup, _form, setValue, setError, clearErrors } = props;

    const { theme } = useGlobal();
    const { api } = useApi();

    const [frontImage, setFrontImage] = useState<string | null>(null);
    const [selfieImage, setSelfieImage] = useState<string | null>(null);

    const [loadingMathImages, setLoadingMathImages] = useState(false);
    const [isValidMathImage, setIsValidMathImage] = useState(true);
    const [isValidInputImages, setIsValidInputImages] = useState(false);
    const [isViewBiometricsStatus, setIsViewBiometricsStatus] = useState(false);

    const matchImages = useCallback(async () => {
        setIsValidMathImage(true);
        setIsViewBiometricsStatus(true);
        setLoadingMathImages(true);

        try {
            const { data } = await api.post("/photos/compare", {
                photo_1: selfieImage,
                photo_2: frontImage,
                origin: 'ADM'
            });

            setIsValidMathImage(data.valid);

            setLoadingMathImages(false);
            
            return data.valid;
        } catch (e) { }

    }, [selfieImage, frontImage]);

    useImperativeHandle(ref, () => ({
        matchImages: async () => {
          if (!selfieImage || !frontImage) {
            setError(`documents-required-guest-${guestGroup.uniqueId}`, {
              type: "manual",
              message: "Verificação da biometria não confirmada",
            });
            return false;
          }
      
          const result = matchImages(); 
          return result 
        },
      }));

    const handleDocumentFront = useCallback(async (guestId, newFile) => {
        setValue(`document-front-${guestId}`, newFile);

        setFrontImage(newFile);
    }, []);

    const handleDocumentSelfie = useCallback(async (guestId, newFile) => {
        setValue(`document-selfie-${guestId}`, newFile);

        setSelfieImage(newFile);
    }, []);

    useEffect(() => {
        if (!selfieImage || !frontImage) {
            setIsViewBiometricsStatus(false);
            setIsValidInputImages(false);

            setError(`documents-required-guest-${guestGroup.uniqueId}`, { type: "manual", message: "Verificação da biometria não confirmada" });
            return;
        };

        clearErrors(`documents-required-guest-${guestGroup.uniqueId}`);

        setIsValidInputImages(true);
        setIsValidMathImage(true);
        setIsViewBiometricsStatus(false);
    }, [selfieImage, frontImage]);

    return (
        <ContainerGuests key={guestGroup.uniqueId} theme={theme} style={(isValidInputImages && isValidMathImage) ? {} : { border: '1px solid red' }}>
            <HeaderGuests theme={theme} style={{ justifyContent: 'space-between' }}>
                <Flex gap={4} align='center'>
                    <HiIdentification size={24} color={secondary[theme]} />
                    <Column>
                        <TitleGuests>{`Documentos e selfie - [${_form[`name-${guestGroup.uniqueId}`]?.toUpperCase()?.trim()}]`}</TitleGuests>
                        <DescriptionGuests>Informe os documentos e selfie do hóspede</DescriptionGuests>
                    </Column>
                </Flex>

                {(isValidInputImages && isViewBiometricsStatus) && (
                    <BiometricsStatus
                        loadingMathImages={loadingMathImages}
                        valid={isValidMathImage}
                    />
                )}

            </HeaderGuests>

            <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 20, padding: 20, flexWrap: 'wrap' }}>
                <DocumentOrPicture
                    onChange={(newFile) => handleDocumentFront(guestGroup.uniqueId, newFile)}
                    defaultDocument={_form[`document-front-${guestGroup.uniqueId}`]}
                    name="Documento Frente"
                    type="document"
                    guest={_form[`name-${guestGroup.uniqueId}`]}
                />
                <DocumentOrPicture
                    onChange={(newFile) => setValue(`document-back-${guestGroup.uniqueId}`, newFile)}
                    defaultDocument={_form[`document-back-${guestGroup.uniqueId}`]}
                    name="Documento Verso"
                    type="document"
                    guest={_form[`name-${guestGroup.uniqueId}`]}
                />
                <DocumentOrPicture
                    onChange={(newFile) => handleDocumentSelfie(guestGroup.uniqueId, newFile)}
                    defaultDocument={_form[`document-selfie-${guestGroup.uniqueId}`]}
                    name="Selfie"
                    type="selfie"
                    guest={_form[`name-${guestGroup.uniqueId}`]}
                />
            </div>

        </ContainerGuests>
    );
})
