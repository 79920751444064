import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { getStatus } from "../../utils/columnsTables";
import { maskFunctions } from "../../services/maskServices";
import { notifications_status } from "../../utils/reservation_status";
import { correctDate, setUpConsultation } from "../../services/generalServices";
import { ActivityTypes, IActions, IFilters, INotificationProps } from "../../interfaces/index";
import { getFilterFields } from "../../utils/filterFields";
import { fail, secondary, success, tertiary } from "../../theme";

const initialFilters: IFilters[] = [getFilterFields("access_type")];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "number",
      name: "Telefone",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "email",
      name: "Email",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "status",
      name: "Status",
      style: { textAlign: 'center' },
      styleContent: { fontSize: 14, textAlign: 'center' },
    },
    {
      attr: "reservation_id",
      name: "Reserva",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "activity",
      name: "Atividade",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "rule_name",
      name: "Regra",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "send_data",
      name: "Data de Envio",
      style: {},
      styleContent: { fontSize: 14 },
    },
  ],
};

export const activity_mapper: { [key: string]: string } = {
  RESERVATION_CREATE: "Reserva Criada",
  RESERVATION_CANCEL: "Reserva Cancelada",
  RESERVATION_PAID: "Reserva Paga",
  CHECKIN_DATE: "Data de Check-in",
  CHECKOUT_DATE: "Data de Check-out",
  CHECKIN_CREATE: "Check-in Criado",
};

export const activity_colors_mapper: { [key: string]: string } = {
  RESERVATION_CREATE: secondary.dark,
  RESERVATION_CANCEL: fail,
  RESERVATION_PAID: success,
  CHECKIN_DATE: tertiary,
  CHECKOUT_DATE: tertiary,
  CHECKIN_CREATE: secondary.dark,
}

export const NotificationsHistory: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [numOfNotifications, setNumOfNotifications] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingNotificationRules, setLoadingNotificationRules] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [table, setTable] = useState<ITable>(initialTable);

  const searchNotifications = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingNotificationRules(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/notifications?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_notifications = result.data.data as INotificationProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_notifications.forEach((notification) => {
            new_table.data.push({
              id: notification.id,
              number: notification.number ? maskFunctions.phone.mask(notification.number) : "",
              email: notification.email,
              status: getStatus({
                ...notifications_status[notification.status],
                theme,
              }),
              reservation_id: notification.stays_reservation_id,
              activity: activity_mapper[notification.activity as ActivityTypes],
              rule_name: notification.rule_name,
              send_data: correctDate(notification.to_send_date, { month_in_extension: true }),
              this: notification,
            });
          });
          return { ...new_table };
        });

        setNumOfNotifications(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas notificações!", "error");
      }
      setLoadingNotificationRules(false);
    },
    [user, filters, searchString]
  );

  const actionsInitial: IActions[] = [];

  useEffect(() => {
    searchNotifications(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingNotificationRules}
      setLoading={setLoadingNotificationRules}
      numberOfItems={numOfNotifications}
      actions={actionsInitial}
      textHeader="Notificações"
      textItems="notificações encontradas"
      text_loading="Buscando Notificações"
      text_empty={"Nenhuma notificação encontrada"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      pages={numberOfPages}
      page={atualPage}
      search={searchNotifications}
      onChangeSearch={setSearchString}
      noActions
    />
  );
};
