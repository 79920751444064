import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormUser } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import {
  mapDefaultForm,
  userModalDefaultForm,
} from "../../../utils/defaultFormsUtils";

interface IModalPersonProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Usuário",
    edit: "Informações do Usuário",
  },
  subTitle: {
    new: "Informe os dados do usuário",
    edit: "Segue abaixo as informações do usuário",
  },
};

export const ModalUser: React.FC<IModalPersonProps> = (props) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);

  const searchUser = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const { data } = await api.get(`/users/${defaultId}`);
        const _data: any = mapDefaultForm({
          data,
          fields: userModalDefaultForm,
        });
        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar o usuário", "error");
        onCancel();
      }
    }
    setLoading(false);
  }, [defaultId]);

  useEffect(() => {
    searchUser();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          <FormUser
            onSave={onSave}
            onCancel={onCancel}
            defaultData={defaultData}
          />
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
