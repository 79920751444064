export const GuestCheck = ({ size = 56, color = "#00FE79", strokeColor = "#fff", strokeWidth = 3, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 56 56"
    {...props}
  >
    <path
      fill={color}
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="m15.398 28 8.4 8.4 16.8-16.8"
    />
  </svg>
);
