import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState, useRef } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { IOptionsProps } from "../../../../interfaces";
import { FixRequiredSelect, IFixRequiredSelectProps, ISelectRefProps } from "../FixRequiredSelect";

const debounceEvent = () => {
    let timer: any = null
    return (fn: any, wait: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), wait)
    }
}

const debounce = debounceEvent()

export interface ISelectCreateMultiRefProps {
    getValues: () => IOptionsProps[] | null
    focus: () => void
}

export interface ISelectCreateMultiProps extends IFixRequiredSelectProps {
    searchOptions: Function
    onCreateOption: Function
    defaultValue?: IOptionsProps[]
}

const SelectCreateMultiComponent: React.ForwardRefRenderFunction<ISelectCreateMultiRefProps, ISelectCreateMultiProps> = (props, ref) => {
    const { onFocus = () => { }, onChange = () => { }, onClick = () => { }, defaultValue = [] } = props
    const { searchOptions, error, isClearable, isDisabled = false, noOptionsMessage, onCreateOption } = props

    const [loading, setLoading] = useState(false)
    const [controlRender, setControlRender] = useState(false)
    const [options, setOptions] = useState<IOptionsProps[]>([])
    const [options_selected, setOptionsSelected] = useState<IOptionsProps[]>(defaultValue)

    const select_ref = useRef<ISelectRefProps>(null)

    const formatOptionLabel = useCallback((value: any, options) => {
        if (!!options?.find && !!options?.find((item: any) => item.value === value.value)) {
            return (
                <div onClick={() => onClick(value)} style={{ display: "flex", flexDirection: "row", marginRight: 10, color: "#FFF", alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    <div>{value.__isNew__ ? <FiPlusCircle /> : <AiOutlineUser />}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 600, color: "#FFF" }}>{value.label}</div>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    {value.__isNew__ ? <FiPlusCircle /> : <AiOutlineUser />}
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value.label}</div>
                </div>
            )
        }
    }, [isDisabled])

    const search = useCallback(async (name) => {
        setLoading(true)
        const result = await searchOptions(name)
        setOptions([...result.formatted])
        setLoading(false)
    }, [isDisabled])

    const onInputChange = useCallback((value) => {
        setOptions([])
        setLoading(true)
        debounce(() => search(value), 800)
    }, [isDisabled])

    const searchWithoutDebounce = useCallback((value) => {
        setOptions([])
        setLoading(true)
        search(value)
    }, [isDisabled])

    const onChangeOption = useCallback((options) => {
        setOptionsSelected([...options])
        onChange(options)
    }, [isDisabled])

    const onInputFocus = useCallback((e) => { onFocus(e) }, [])

    const getValues = useCallback(() => options_selected, [options_selected])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValues, focus }))

    useEffect(() => { searchWithoutDebounce("") }, [])

    useEffect(() => { setControlRender((atual) => !atual) }, [options_selected])

    const childrenDisabled = (
        <FixRequiredSelect
            ref={select_ref}
            onFocus={onInputFocus}
            onBlur={() => searchWithoutDebounce("")}
            onCreateOption={(data: any) => {
                const newEmails = [...options, { value: data, label: data }];
                setOptions(newEmails);
                setOptionsSelected([...options_selected, { value: data, label: data }])
                onChangeOption([...options_selected, { value: data, label: data }])
            }}
            formatCreateLabel={(value: any) => `Cadastrar "${value}"`}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isClearable={false}
            isLoading={loading}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            onInputChange={(text: any) => { onInputChange(text) }}
            value={options_selected}
            isMulti
            formatOptionLabel={(value: any) => formatOptionLabel(value, options_selected)}
            menuIsOpen={false}
            isSearchable={false}
            components={{ MultiValueRemove: () => null }}
            isDisabled
            isCreatable
            error={error}
        />
    )

    const childrenNotDisabled = (
        <FixRequiredSelect
            ref={select_ref}
            onFocus={onInputFocus}
            onBlur={() => searchWithoutDebounce("")}
            onCreateOption={(data: any) => {
                const newEmails = [...options, { value: data, label: data }];
                setOptions(newEmails);
                setOptionsSelected([...options_selected, { value: data, label: data }])
                onChangeOption([...options_selected, { value: data, label: data }])
            }}
            formatCreateLabel={(value: any) => `Cadastrar "${value}"`}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isClearable={isClearable}
            isCreatable
            isLoading={loading}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            onInputChange={(text: any) => { onInputChange(text) }}
            value={options_selected}
            isMulti
            formatOptionLabel={(value: any) => formatOptionLabel(value, options_selected)}
            error={error}
        />
    )

    return (
        <>
            {isDisabled &&
                <>
                    {controlRender && childrenDisabled}
                    {!controlRender && childrenDisabled}
                </>
            }
            {!isDisabled &&
                <>
                    {controlRender && childrenNotDisabled}
                    {!controlRender && childrenNotDisabled}
                </>
            }
        </>
    )
}

export const SelectCreateMulti = forwardRef(SelectCreateMultiComponent)