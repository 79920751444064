import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { IPermissionsTypes } from "../../utils/permissions";
import {
  correctDateDashboard,
  setUpConsultation,
} from "../../services/generalServices";
import { ModalCheckIn } from "../../components/Modals/ModalCheckIn";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ICheckInsProps, IActions, IFilters } from "../../interfaces/index";
import { getGuests } from "../../utils/columnsTables";
import { secondary } from "../../theme";
import { FaBook, FaEdit, FaWhatsapp } from "react-icons/fa";
import { MdDelete, MdHotel, MdOutlineEmail } from "react-icons/md";
import { getFilterFields } from "../../utils/filterFields";
import { TbRotateClockwise } from "react-icons/tb";
import { ModalSendWpp } from "../../components/Modals/ModalSendWpp";
import { ModalSendEmail } from "../../components/Modals/ModalSendEmail";
import { FiDownload } from "react-icons/fi";

const initialFilters: IFilters[] = [
  getFilterFields("check_in"),
  getFilterFields("check_out"),
  getFilterFields("created_at"),
];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "reservation_stays_id",
      name: "Reserva",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "accommodation_id",
      name: "Acomodação",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "guests",
      name: "Hóspedes",
      style: { width: "20%", textAlign: "center" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "check_in",
      name: "Entrada",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "check_out",
      name: "Saída",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "createdAt",
      name: "Criado em",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
  ],
};

export const CheckInsList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [selectedCheckInSendEmail, setSelectedCheckInSendEmail] = useState<ICheckInsProps | null>(null);
  const [selectedCheckInSendWpp, setSelectedCheckInSendWpp] = useState<ICheckInsProps | null>(null);
  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [numOfCheckIns, setNumOfCheckIns] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingCheckIns, setLoadingCheckIns] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [checkInSelected, setCheckInSelected] = useState<ICheckInsProps | null>(null);
  const [table, setTable] = useState<ITable>(initialTable);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [auxLoading, setAuxLoading] = useState(false);

  const selectCheckIn = useCallback((accomoddation: ICheckInsProps) => {
    setCheckInSelected({ ...accomoddation });
    setOpenModal(true);
  }, []);

  const searchCheckIns = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingCheckIns(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/check-ins?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_check_ins = result.data.data as ICheckInsProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_check_ins.forEach((check_in) => {
            const checkInDate = String(
              check_in.stays_reservations.check_in_date
            ).split("T")[0];
            const checkInTime = String(
              check_in.stays_reservations.check_in_time
            );
            const checkIn = correctDateDashboard(
              new Date(checkInDate + " " + checkInTime)
            );

            const checkOutDate = String(
              check_in.stays_reservations.check_out_date
            ).split("T")[0];
            const checkOutTime = String(
              check_in.stays_reservations.check_out_time
            );
            const checkOut = correctDateDashboard(
              new Date(checkOutDate + " " + checkOutTime)
            );

            new_table.data.push({
              id: check_in.id,
              reservation_stays_id: check_in.reservation_stays_id,
              accommodation_id:
                check_in?.accommodation?.id +
                " - " +
                check_in?.accommodation?.name,
              check_in: checkIn,
              check_out: checkOut,
              guests: getGuests({ guests: check_in.guests, theme }),
              createdAt: correctDateDashboard(check_in.createdAt),
              this: check_in,
            });
          });
          return { ...new_table };
        });

        setNumOfCheckIns(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas check-ins!", "error");
      }
      setLoadingCheckIns(false);
    },
    [user, filters, searchString, theme]
  );

  const openModalNewCheckIn = useCallback(() => {
    setCheckInSelected(null);
    setOpenModal(true);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewCheckIn();
  };

  const actionsInitial: IActions[] = [
    {
      main_option: { text: "Nova Check-in", id: "new_item" },
      className: "new_item",
      icon: <IoAddCircleOutline size={20} className="new_item" />,
      options: [],
      type: "solid",
      permissions: ["CHECK-INS-CREATE"],
    },
  ];

  const onSaveCheckIn = useCallback(() => {
    setOpenModal(false);
    searchCheckIns(1);
  }, [user, filters, searchString]);

  const handleConfirm = useCallback(
    async (id: string) => {
      setLoadingDelete(true);
      try {
        await api.delete(`/check-ins/${id}`);
        notify("Check-in excluído com sucesso!", "success");
        await searchCheckIns(1);
      } catch (error: any) {
        const default_message = "Erro ao excluir a check-in!";
        const message = error.response?.data?.detail ?? default_message;
        notify(message, message === default_message ? "error" : "alert");
      }
      setLoadingDelete(false);
    },
    [searchCheckIns]
  );

  const alertDelete = useCallback(
    async (type: ICheckInsProps): Promise<void> => {
      const result = window.confirm(
        "Tem certeza que deseja excluir o check-in da reserva " +
        type.reservation_stays_id
      );

      if (result) handleConfirm(type.id as unknown as string);
    },
    [handleConfirm]
  );

  const handleResendEmail = useCallback(
    async (id: string) => {
      setAuxLoading(true)
      try {
        await api.post(`/check-ins/resend-email/${id}`);
        notify("E-mail de check-in reenviado com sucesso!", "success");
      } catch (error: any) {
        notify("Erro ao reenviar o e-mail de check-in!", "error");
      }
      setAuxLoading(false)
    },
    [api]
  );

  const handleRecreateCheckIn = useCallback(
    async (id: string) => {
      setAuxLoading(true)
      try {
        await api.post(`/check-ins/create-new-password/${id}`);
        notify("Check-in recriado com sucesso!", "success");
        searchCheckIns(1);
      } catch (error: any) {
        notify("Erro ao recriar o check-in!", "error");
      }
      setAuxLoading(false)
    },
    [api]
  );

  const confirmResendEmail = useCallback(
    async (item: any): Promise<void> => {
      const result = window.confirm(
        "Tem certeza que deseja reenviar o e-mail de check-in da reserva " +
        item.reservation_stays_id
      );

      if (result) handleResendEmail(item.id as unknown as string);
    },
    [handleResendEmail]
  );

  const confirmRecreateCheckIn = useCallback(
    async (item: any): Promise<void> => {
      const result = window.confirm(
        "Tem certeza que deseja recriar o check-in da reserva " +
        item.reservation_stays_id
      );

      if (result) handleRecreateCheckIn(item.id as unknown as string);
    },
    [handleRecreateCheckIn]
  );

  const listAction = [
    {
      title: "Baixar ficha dos hóspedes",
      onClick: (item: any) => window.open(process.env.REACT_APP_API + '/pdf/' + item.reservation_stays_id, '_blank'),
      icon: <FiDownload color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
    {
      title: "Abrir reserva na Stays",
      onClick: (item: any) =>
        window.open(
          `https://mgli.stays.net/i/reservation/${item.reservation_stays_id}`,
          "_blank"
        ),
      icon: <FaBook color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
    {
      title: "Abrir acomodação na Stays",
      onClick: (item: any) => {
        window.open(
          `https://mgli.stays.net/i/apartment/${item.accommodation.id
          }/calendar?date=${item?.stays_reservations?.check_in_date?.split("T")?.[0]
          }`,
          "_blank"
        );
      },
      icon: <MdHotel color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
    {
      title: "Enviar email",
      onClick: (item: any) => setSelectedCheckInSendEmail(item),
      icon: <MdOutlineEmail color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("SEND_EMAILS" as IPermissionsTypes),
    },
    {
      title: "Enviar WhatsApp",
      onClick: (item: any) => setSelectedCheckInSendWpp(item),
      icon: <FaWhatsapp color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("SEND_WPP" as IPermissionsTypes),
    },
    {
      title: "Recriar check-in",
      onClick: confirmRecreateCheckIn,
      icon: <TbRotateClockwise color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("CHECK-INS-UPDATE" as IPermissionsTypes),
    },
    {
      title: "Editar",
      onClick: (accomoddation: ICheckInsProps) => selectCheckIn(accomoddation),
      icon: <FaEdit color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("CHECK-INS-UPDATE" as IPermissionsTypes),
    },
    {
      title: "Excluir",
      onClick: (accomoddation: ICheckInsProps) => alertDelete(accomoddation),
      icon: <MdDelete color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("CHECK-INS-DELETE" as IPermissionsTypes),
    },
  ];

  useEffect(() => {
    searchCheckIns(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingCheckIns}
      setLoading={setLoadingCheckIns}
      numberOfItems={numOfCheckIns}
      actions={actionsInitial}
      onClickOption={handleAction}
      textHeader="Check-ins"
      textItems="check-ins encontrados"
      text_loading={"Buscando Check-ins"}
      text_empty={"Nenhum check-in encontrado"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      pages={numberOfPages}
      page={atualPage}
      search={searchCheckIns}
      onChangeSearch={setSearchString}
      listActions={listAction}
    >
      {openModal && (
        <ModalCheckIn
          onCancel={() => setOpenModal(false)}
          onSave={onSaveCheckIn}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={checkInSelected?.id}
        />
      )}
      {(loadingDelete || auxLoading) && <ModalLoading loading={loadingDelete || auxLoading} theme={theme} />}
      {selectedCheckInSendWpp?.id && (
        <ModalSendWpp
          onCancel={() => setSelectedCheckInSendWpp(null)}
          onSave={() => setSelectedCheckInSendWpp(null)}
          openModal={!!selectedCheckInSendWpp?.id}
          id={selectedCheckInSendWpp?.reservation_stays_id as any}
        />
      )}
      {selectedCheckInSendEmail && (
        <ModalSendEmail
          onCancel={() => setSelectedCheckInSendEmail(null)}
          onSave={() => setSelectedCheckInSendEmail(null)}
          openModal={!!selectedCheckInSendEmail}
          id={selectedCheckInSendEmail.reservation_stays_id as any}
        />
      )}
    </PageList>
  );
};
