import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormAccommodationGroup } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalAccommodationGroupProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Grupo de Acomodações",
    edit: "Informações do Grupo de Acomodações",
  },
  subTitle: {
    new: "Informe os dados do grupo de acomodações",
    edit: "Segue abaixo as informações do grupo de acomodações",
  },
};

export const ModalAccommodationGroup: React.FC<IModalAccommodationGroupProps> = (
  props
) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);
  const [accommodationOptions, setAccommodationOptions] = useState<{ label: string; value: string }[]>([]);

  const searchAccommodations = useCallback(async () => {
    const { data } = await api.get("/accommodations/all");

    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.id + " - " + item.name,
      value: String(item.id),
    }));

    setAccommodationOptions(_data);

    return _data;
  }, []);

  const searchAccommodation = useCallback(async () => {
    const accommodations = await searchAccommodations();

    if (defaultId) {
      setLoading(true);
      try {

        const { data } = await api.get(`/accommodations-groups/${defaultId}`);

        const accommodation_ids = data?.accommodations.map((a: any) => String(a.id));

        const _data = {} as any;

        _data.id = data.id;
        _data.name = data.name;
        _data.accommodation_ids = accommodations?.filter(({ value }: any) => accommodation_ids?.includes(String(value)));

        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar a grupo de acomodações", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  useEffect(() => {
    searchAccommodation();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormAccommodationGroup
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              accommodationOptions={accommodationOptions}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
