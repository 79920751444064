import React from "react";
import { useApi } from "../../hooks/api";
import { Column, Line } from "../../style";
import { AiOutlineReload } from "react-icons/ai";
import { background, fail, info, secondary, success } from "../../theme";
import { CircularProgress } from "@material-ui/core";
import { IActions, themeTypes } from "../../interfaces";
import { DropdownButton } from "../Buttons/DropdownButton";
import { Container, ContainerLastUpdate, H4, Span } from "./style";
import { correctDateDashboard } from "../../services/generalServices";

interface IHeaderList {
  loading?: boolean;
  numberOfItems?: number;
  theme: themeTypes;
  onClickOption?: (option: string) => void;
  actions?: IActions[];
  textHeader: string;
  textItems?: string;
  updatedAt?: string;
  selecteds?: string[];
  onRefresh?: () => void;
  textDescritpion?: string;
  haveBorder?: boolean;
}

export const HeaderList: React.FC<IHeaderList> = (data) => {
  const {
    loading = false,
    haveBorder = false,
    numberOfItems = 0,
    textDescritpion,
    theme,
    textHeader,
    textItems = "itens",
    actions = [],
    selecteds = [],
    updatedAt,
    onClickOption = () => {},
    onRefresh = () => {},
  } = data;

  const { user } = useApi();

  const handleRefresh = () => {
    onRefresh();
  };

  return (
    <Container haveBorder={haveBorder} theme={theme}>
      <Column>
        <Line>
          <H4>{textHeader}</H4>
        </Line>
        <Span>
          {!textDescritpion && (
            <>
              {!loading && (
                <span>
                  <b style={{ fontWeight: 600 }}>{numberOfItems}</b> {textItems}
                </span>
              )}
              <Line
                style={{
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <ContainerLastUpdate>
                  {!loading ? (
                    <Line
                      style={{ gap: 5, marginRight: 10, alignItems: "center" }}
                    >
                      <div style={{ fontSize: 14, whiteSpace: "nowrap" }}>
                        Última atualização realizada em{" "}
                        <b>{correctDateDashboard(updatedAt)}</b>
                      </div>
                      <div
                        onClick={handleRefresh}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AiOutlineReload />
                      </div>
                    </Line>
                  ) : (
                    <Line
                      style={{ gap: 5, marginRight: 10, alignItems: "center" }}
                    >
                      <div style={{ fontSize: 14 }}>Atualizando listagem</div>
                      <CircularProgress
                        size={10}
                        variant="indeterminate"
                        style={{ color: secondary[theme] }}
                      />
                    </Line>
                  )}
                </ContainerLastUpdate>
                {["Propostas", "Oportunidades"].includes(textHeader) && (
                  <Line
                    style={{
                      background: background[theme],
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      gap: 10,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Line style={{ alignItems: "center", gap: 5 }}>
                      <div
                        style={{ width: 12, height: 12, background: success }}
                      ></div>
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.8,
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                          textTransform: "uppercase",
                        }}
                      >
                        Pendente analista
                      </div>
                    </Line>
                    <Line style={{ alignItems: "center", gap: 5 }}>
                      <div
                        style={{ width: 12, height: 12, background: info }}
                      ></div>
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.8,
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                          textTransform: "uppercase",
                        }}
                      >
                        Pendente corretor
                      </div>
                    </Line>
                  </Line>
                )}
                {["Imóveis"].includes(textHeader) && (
                  <Line
                    style={{
                      background: background[theme],
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      gap: 20,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Line style={{ alignItems: "center", gap: 5 }}>
                      <div
                        style={{ width: 12, height: 12, background: success }}
                      ></div>
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.8,
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                          textTransform: "uppercase",
                        }}
                      >
                        Imóvel Disponível
                      </div>
                    </Line>
                    <Line style={{ alignItems: "center", gap: 5 }}>
                      <div
                        style={{ width: 12, height: 12, background: fail }}
                      ></div>
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.8,
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                          textTransform: "uppercase",
                        }}
                      >
                        Imóvel Ocupado
                      </div>
                    </Line>
                  </Line>
                )}
              </Line>
            </>
          )}
          {textDescritpion && (
            <ContainerLastUpdate>
              {!loading ? (
                <>
                  <div style={{ fontSize: 14 }}>{textDescritpion}</div>
                  <div
                    onClick={handleRefresh}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AiOutlineReload />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ fontSize: 14 }}>Atualizando</div>
                  <CircularProgress
                    size={10}
                    variant="indeterminate"
                    style={{ color: secondary[theme] }}
                  />
                </>
              )}
            </ContainerLastUpdate>
          )}
        </Span>
      </Column>
      <Line
        style={{
          gap: 10,
          justifyContent: "flex-end",
          flex: 1,
          flexWrap: "wrap",
        }}
      >
        {actions.map((action) => {
          if (action.only_to_selecteds && selecteds.length === 0) return null;
          if (
            action.permissions &&
            !action.permissions.every((p) =>
              user.user_type.permissions.includes(p)
            )
          )
            return null;
          if (action.validateAccess && !action.validateAccess()) return null;
          for (const option of action.options) {
            if (option.validateAccess && !option.validateAccess()) return null;
          }
          return (
            <DropdownButton
              main_option={action.main_option}
              icon={action.icon}
              options={action.options}
              className={action.className}
              onClick={(id) => onClickOption(id)}
              theme={theme}
              loading={loading}
              selects_number={action.only_to_selecteds ? selecteds.length : 0}
              type={action.type ?? "outline"}
              zIndex={900}
            />
          );
        })}
      </Line>
    </Container>
  );
};
