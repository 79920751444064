import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../interfaces";
import {
  borderDisabled,
  disabledItems,
  inactiveItens,
  primary,
} from "../../theme";

export const Container = styled.div<IThemeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => css`
    .form-control {
      background-color: ${primary[props.theme as themeTypes]};
      color: ${props.theme === "light" ? "#000" : inactiveItens.dark};
      border-color: ${props.theme === "light" ? "#CCCCCC" : "rgb(68 68 68)"};
      :disabled {
        background-color: ${disabledItems[props.theme as themeTypes]};
        color: #999999;
        border-color: ${borderDisabled[props.theme as themeTypes]};
      }
    }
  `}
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
  flex: 1;
  overflow: auto;
`;
