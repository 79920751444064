import styled from "styled-components";
import { divider } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
`;

export const ContainerForm = styled.div<{ open: boolean }>`
  flex-direction: column;
  flex: 1;
  margin-top: -20px;
  display: ${(props) => (props.open ? "flex" : "none")};
`;

export const ContainerDocuments = styled.div<{ open: boolean }>`
  flex-direction: column;
  flex: 1;
  margin: -16px;
  margin-top: -16px;
  display: ${(props) => (props.open ? "flex" : "none")};
`;

export const ContainerButtons = styled.div<IThemeProps>`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 20px;
  border-top: 1px solid ${(props) => divider[props.theme as themeTypes]};
`;
