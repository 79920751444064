import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

interface IMyCircularProgress {
  color: string;
  size?: number;
}

const Container = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    stroke: ${(props) => props.color} !important;
  }
`;

export const MyCircularProgress = ({ color, size }: IMyCircularProgress) => {
  return (
    <Container color={color}>
      <CircularProgress
        variant="indeterminate"
        style={{
          color: `${color} !important`,
          fill: `${color} !important`,
          stroke: `${color} !important`,
        }}
        size={size}
      />
    </Container>
  );
};
