export const LogoDark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <path
      fill="#00FE79"
      d="M33.919 39.716H6.083A6.084 6.084 0 0 1 0 33.632V18.55a6.08 6.08 0 0 1 2.025-4.531L15.943 1.552a6.08 6.08 0 0 1 8.114 0l13.918 12.465A6.08 6.08 0 0 1 40 18.55V33.63a6.084 6.084 0 0 1-6.083 6.083l.002.002Z"
    />
    <path
      fill="#FBFDFD"
      d="M30.274 27.8H28.33a.424.424 0 0 1-.424-.425v-1.052a.43.43 0 0 1 .143-.318l.975-.873a.429.429 0 0 1 .566 0l.975.873a.43.43 0 0 1 .143.318v1.052a.424.424 0 0 1-.424.424h-.009Z"
    />
    <path
      fill="#3A3A3A"
      d="M20.296 21.328 17.972 27.8h-1.7l-1.423-4.493-1.424 4.493h-1.7l-2.329-6.47h1.893l1.333 4.39 1.407-4.39h1.688l1.407 4.39 1.362-4.39h1.81ZM25.654 27.8v-3.58c0-.877-.558-1.448-1.406-1.448-.914 0-1.521.583-1.521 1.472v3.552h-1.652V18.16h1.652v4.017c.493-.66 1.207-1.003 2.055-1.003 1.52 0 2.524.99 2.524 2.512v4.11h-1.647l-.005.004Z"
    />
  </svg>
);
