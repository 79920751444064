import { GiMirrorMirror } from "react-icons/gi";
import { ModalGeneric } from "../../../../ModalGeneric";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineRotateLeft } from "react-icons/ai";

interface IModalEditPicture {
    onClose: () => void
    onTakePicture: (file: string) => void
    type: 'document' | 'selfie'
    defaultImage: string
}

export const ModalEditPicture: React.FC<IModalEditPicture> = ({ onClose, defaultImage }) => {
    const [image, setImage] = useState(defaultImage);
    const [rotate, setRotate] = useState(0);
    const [mirrored, setMirrored] = useState(false);
    const [hoverImage, setHoverImage] = useState(false);

    useEffect(() => {
        let rotateMod = rotate < 0 ? rotate * -1 : rotate;
        if (rotateMod >= 360) {
            while (rotateMod >= 360) {
                rotateMod -= 360;
            }
            setRotate(rotate > 0 ? rotateMod : -1 * rotateMod);
        }
    }, [rotate])

    return (
        <ModalGeneric open onClose={() => onClose()}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minHeight: "calc(100vh)",
                    background: "#3D3D3D",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ position: "relative", display: "flex", flex: 1, width: "100%" }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        padding: 20,
                        overflow: 'hidden', justifyContent: "center", alignItems: "center"
                    }}>
                        <img
                            src={image}
                            style={{
                                objectFit: 'cover',
                                height: "calc(100vh)",
                                transform: mirrored ? `scaleX(-1) rotate(${rotate}deg)` : `scaleX(1) rotate(${rotate}deg)`,
                                userSelect: 'none'
                            }}
                            onMouseEnter={() => setHoverImage(true)}
                            onMouseLeave={() => setHoverImage(false)}
                        />
                    </div>
                    <div onMouseEnter={() => setHoverImage(true)} style={{
                        gap: 100,
                        bottom: 30,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        position: "absolute",
                        opacity: hoverImage ? 1 : 0,
                        transition: "opacity 0.3s",
                    }}>
                        <div
                            style={{
                                padding: 16,
                                borderRadius: "50%",
                                background: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                width: 50,
                                height: 50
                            }}
                            onClick={() => setMirrored((prev) => !prev)}
                        >
                            <GiMirrorMirror color="#000" />
                        </div>
                        <div
                            style={{
                                padding: 16,
                                borderRadius: "50%",
                                background: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                width: 50,
                                height: 50
                            }}
                            onClick={() => setRotate((prev) => prev - (!mirrored ? 90 : -90))}
                        >
                            <AiOutlineRotateLeft color="#000" />
                        </div>
                        <div
                            style={{
                                padding: 16,
                                borderRadius: "50%",
                                background: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onClick={() => onClose()}
                        >
                            <AiOutlineCloseCircle color="#000" />
                        </div>
                    </div>
                </div>
            </div>

        </ModalGeneric >
    )
}