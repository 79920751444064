import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useCallback, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBook, FaHistory, FaUserTie } from "react-icons/fa";
import { BsFillHouseFill } from 'react-icons/bs';
import { MdAppSettingsAlt, MdNotificationsActive } from "react-icons/md";
import { useGlobal } from "../../hooks/global";
import { VscSettings } from "react-icons/vsc";
import { RiDashboardFill, RiHistoryLine } from "react-icons/ri";
import {
  Container,
  Item,
  MenuList,
  Link,
  IconLink,
  LinkName,
  ActiveIndicator,
  SubMenuList,
  ItemSubMenu,
  IconSubMenu,
  IconArrow,
  ContainerSubSubMenuItems,
} from "./style";
import { IPermissionsTypes } from "../../utils/permissions";
import { useApi } from "../../hooks/api";
import { MdFactCheck, MdHotel, MdOutlineDocumentScanner } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { BsFilePersonFill } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { AppConstants } from "../../constants";

interface menuItemsProps {
  name: string;
  to: string;
  icon: string;
  iconComponent?: React.ReactElement;
  package: string;
  subMenus: menuItemsProps[];
  permissions?: IPermissionsTypes[];
}

const menus: menuItemsProps[] = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: "i-Home1",
    iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
    package: "Dashboard",
    subMenus: [],
    permissions: ['DASHBOARD']
  },
  {
    name: "Fechaduras",
    to: "/smartlocks",
    icon: "i-Management",
    iconComponent: <IoMdLock size={30} style={{ marginBottom: 10 }} />,
    package: "Fechaduras",
    subMenus: [],
    permissions: ["SMARTLOCKS-LIST"],
  },
  {
    name: "Acomodações",
    to: "/accommodations",
    icon: "i-Management",
    iconComponent: <MdHotel size={30} style={{ marginBottom: 10 }} />,
    package: "Acomodações",
    subMenus: [],
    permissions: ["ACCOMMODATIONS-LIST"],
  },
  {
    name: "Check-ins",
    to: "/check-ins",
    icon: "i-Management",
    iconComponent: <MdFactCheck size={30} style={{ marginBottom: 10 }} />,
    package: "Check-ins",
    subMenus: [],
    permissions: ["CHECK-INS-LIST"],
  },
  {
    name: "Reservas",
    to: "/reservations",
    icon: "i-Management",
    iconComponent: <FaBook size={30} style={{ marginBottom: 10 }} />,
    package: "Reservas",
    subMenus: [],
    permissions: ["STAYS-RESERVATIONS-LIST"],
  },
  {
    name: "Clientes App",
    to: "/clients-app",
    icon: "i-Management",
    iconComponent: <BsFilePersonFill size={30} style={{ marginBottom: 10 }} />,
    package: "Clientes App",
    subMenus: [],
    permissions: ["CUSTOMER-LIST"],
  },
  {
    name: "Usuários",
    to: "/users",
    icon: "i-Management",
    iconComponent: <FaUserTie size={30} style={{ marginBottom: 10 }} />,
    package: "Usuários",
    subMenus: [],
    permissions: ["USERS-LIST"],
  },
  {
    name: "Configurações",
    to: "/settings",
    icon: "i-Management",
    iconComponent: <IoSettingsOutline size={30} style={{ marginBottom: 10 }} />,
    package: "Configurações",
    subMenus: [
      {
        name: "Geral",
        to: "/general",
        icon: "i-Gears",
        package: "Configurações",
        subMenus: [],
        permissions: ["SETTINGS-LIST"],
      },
      {
        name: "Tipos de Usuários",
        to: "/users-types",
        icon: "i-Management",
        package: "Configurações",
        iconComponent: <FaUserTie size={19} />,
        subMenus: [],
        permissions: ["USER-TYPES-LIST"],
      },
      {
        name: "Grupos de Acomodações",
        to: "/accommodations-types",
        iconComponent: <BsFillHouseFill size={19} />,
        icon: "i-Management",
        package: "Configurações",
        subMenus: [],
        permissions: ['ACCOMMODATIONS-GROUPS-LIST']
      },
      {
        name: "Regras de notificação",
        to: "/notification-rules",
        iconComponent: <MdNotificationsActive size={19} />,
        icon: "i-Management",
        package: "Configurações",
        subMenus: [],
        permissions: ['NOTIFICATIONS']
      },
      {
        name: "Histórico de Notificações",
        to: "/notifications-history",
        iconComponent: <FaHistory size={19} />,
        icon: "i-Management",
        package: "Configurações",
        subMenus: [],
        permissions: ['NOTIFICATIONS']
      },
      {
        name: "Aplicativo",
        to: "/mobile-app",
        icon: "i-Management",
        iconComponent: <MdAppSettingsAlt size={19} />,
        package: "Configurações",
        subMenus: [],
        permissions: ["SETTINGS-LIST"]
      },
      {
        name: "Modelos de mensagens",
        to: "/message-models",
        icon: "i-Management",
        iconComponent: <MdOutlineDocumentScanner size={19} />,
        package: "Configurações",
        subMenus: [],
        permissions: ['MESSAGE-MODELS-LIST']
      },
      {
        name: "Integrações",
        to: "/integrations",
        icon: "i-Management",
        package: "Configurações",
        iconComponent: <VscSettings size={19} />,
        subMenus: [],
        permissions: ['INTEGRATIONS']
      },
    ],
  },
];

export const Sidebar: React.FC = () => {
  const {
    menuOpen,
    subMenuOpen,
    setSubMenuOpen,
    theme,
    menuItemSelected,
    setMenuItemSelected,
    setSubMenuItemSelected,
    subMenuItemSelected,
    isMobile,
  } = useGlobal();

  const { user } = useApi();

  const clickLink = useCallback(
    (event, menuItem: menuItemsProps) => {
      if (isMobile && menuItem.subMenus.length > 0) {
        if (subMenuOpen) {
          if (
            menuItemSelected.name === menuItem.name &&
            menuItemSelected.package === menuItem.package
          )
            setSubMenuOpen(false);
          else {
            setMenuItemSelected(menuItem);
            setSubMenuOpen(true);
          }
        } else {
          setMenuItemSelected(menuItem);
          setSubMenuOpen(true);
        }
        event.preventDefault();
      } else {
        if (
          menuItemSelected.name === menuItem.name &&
          menuItemSelected.package === menuItem.package
        ) {
          setSubMenuOpen(!subMenuOpen);
        }
      }
    },
    [menuItemSelected, subMenuOpen, isMobile]
  );

  const clickSubLink = useCallback((event, menuItem: menuItemsProps) => {
    if (menuItem.subMenus.length > 0) {
      setSubMenuItemSelected((atual) => {
        return atual.name === menuItem.name &&
          atual.package === menuItem.package
          ? ({} as menuItemsProps)
          : menuItem;
      });
      event.preventDefault();
    } else setSubMenuOpen(false);
  }, []);

  const hoverLink = useCallback(
    (menuItem: menuItemsProps) => {
      if (!isMobile) {
        if (menuItem.subMenus.length > 0) {
          setMenuItemSelected(menuItem);
          setSubMenuOpen(true);
        } else setSubMenuOpen(false);
      }
    },
    [isMobile]
  );

  const clickActiveIndicator = useCallback((menuItemId) => {
    document.getElementById(menuItemId)?.click();
  }, []);

  const getHeight = useCallback(() => {
    const header = document.getElementById("header-content");
    return `calc(calc(100 * var(--vh)) - ${header?.clientHeight}px)`;
  }, []);

  useEffect(() => {
    setSubMenuItemSelected({} as menuItemsProps);
  }, [menuItemSelected]);

  return (
    <Container theme={theme} menuOpen={menuOpen}>
      <div
        id="nav-sidebar"
        style={{ display: "flex", flexDirection: "row", height: "100%" }}
      >
        <MenuList theme={theme}>
          <PerfectScrollbar
            style={{ maxHeight: getHeight() }}
            options={{ suppressScrollY: false }}
          >
            {menus
              .filter(({ permissions = [], subMenus = [] }) => {
                return (
                  !!permissions.every((p) => user.user_type.permissions.includes(p))
                  && (
                    subMenus.length === 0
                    || subMenus.some(({ permissions = [] }) => permissions.every((p) => user.user_type.permissions.includes(p)))
                  )
                )
              })
              .filter(({ name }) => name === 'Clientes' ? AppConstants.HAS_APP : true)
              .map((item, index) => (
                <Item
                  theme={theme}
                  onMouseEnter={() => hoverLink(item)}
                  noBorder={index === menus.length - 1}
                  key={`${item.name}-${item.icon}`}
                >
                  <Link
                    style={{ maxHeight: 109.5, justifyContent: "center" }}
                    id={`link-to-${item.name}`}
                    theme={theme}
                    active={
                      item.package === menuItemSelected.package && subMenuOpen
                    }
                    to={item.to}
                    onClick={(event) => clickLink(event, item)}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item.iconComponent && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: 30,
                          }}
                        >
                          {item.iconComponent}
                        </div>
                      )}
                      {!item.iconComponent && (
                        <IconLink className={item.icon} />
                      )}
                    </div>
                    <LinkName>{item.name}</LinkName>
                  </Link>
                  <ActiveIndicator
                    active={
                      item.package === menuItemSelected.package && subMenuOpen
                    }
                    onClick={() => clickActiveIndicator(`link-to-${item.name}`)}
                    theme={theme}
                  />
                </Item>
              ))}
          </PerfectScrollbar>
        </MenuList>
        <SubMenuList
          open={
            subMenuOpen &&
            menuItemSelected.subMenus &&
            menuItemSelected.subMenus.length > 0
          }
          theme={theme}
        >
          <PerfectScrollbar
            style={{
              maxHeight: "calc(calc(100 * var(--vh)) - 82px)",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            options={{ suppressScrollY: false }}
          >
            {menuItemSelected.subMenus &&
              menuItemSelected.subMenus.filter(
                ({ permissions = [] }) =>
                  !!permissions.every((p) =>
                    user.user_type.permissions.includes(p)
                  )
              ).map((subMenu) => (
                <>
                  <ItemSubMenu
                    active={
                      subMenuItemSelected.name === subMenu.name &&
                      subMenu.package === subMenuItemSelected.package
                    }
                    key={subMenu.name}
                  >
                    <Link
                      id={`link-to-${subMenu.name}`}
                      active={false}
                      theme={theme}
                      to={`${menuItemSelected.to}${subMenu.to}`}
                      style={{
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        flexDirection: "row",
                      }}
                      onClick={(event) => clickSubLink(event, subMenu)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: 29,
                            marginRight: 10
                          }}
                        >
                          {subMenu.iconComponent && subMenu.iconComponent}
                          {!subMenu.iconComponent && (
                            <IconSubMenu className={subMenu.icon} />
                          )}
                        </div>

                        <span style={{ textAlign: "left" }}>
                          {subMenu.name}
                        </span>

                      </div>
                      {subMenu.subMenus.length > 0 && (
                        <IconArrow
                          open={
                            subMenuItemSelected.name === subMenu.name &&
                            subMenu.package === subMenuItemSelected.package
                          }
                          theme={theme}
                          className="i-Arrow-Down"
                          data-fullscreen
                        />
                      )}
                    </Link>
                  </ItemSubMenu>
                  <ContainerSubSubMenuItems
                    maxHeight={subMenu.subMenus.length * 60}
                    open={
                      subMenuItemSelected.name === subMenu.name &&
                      subMenu.package === subMenuItemSelected.package
                    }
                  >
                    {subMenu.subMenus.map((subSubMenu) => (
                      <ItemSubMenu>
                        <Link
                          active={false}
                          theme={theme}
                          to={`${menuItemSelected.to}${subMenuItemSelected.to}${subSubMenu.to}`}
                          style={{
                            padding: 10,
                            paddingLeft: 49,
                            paddingRight: 20,
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ textAlign: "left" }}>
                            {subSubMenu.name}
                          </span>
                        </Link>
                      </ItemSubMenu>
                    ))}
                  </ContainerSubSubMenuItems>
                </>
              ))}
          </PerfectScrollbar>
        </SubMenuList>
      </div>
    </Container>
  );
};
