import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { IPermissionsTypes } from "../../utils/permissions";
import { setUpConsultation } from "../../services/generalServices";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { IActions, IFilters, IUserTypesProps } from "../../interfaces/index";
import { ModalAccommodationGroup } from "../../components/Modals/ModalAccommodationGroup";
import { FaEdit } from "react-icons/fa";
import { secondary } from "../../theme";
import { MdDelete } from "react-icons/md";

const initialFilters: IFilters[] = [];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "name",
      name: "Nome",
      style: { width: "100%" },
      styleContent: { width: "100%", fontSize: 16, fontWeight: 600 },
    },
  ],
};

export const AccommodationTypesList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [numOfPersons, setNumOfPersons] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingPersons, setLoadingPersons] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [accommodationGroupSelected, setAccommodatonGroupSelected] =
    useState<IUserTypesProps | null>(null);
  const [table, setTable] = useState<ITable>(initialTable);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const selectAccommodationGroup = useCallback((userType: IUserTypesProps) => {
    setAccommodatonGroupSelected({ ...userType });
    setOpenModal(true);
  }, []);

  const searchAccommodationTypes = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingPersons(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/accommodations-groups?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_users_types = result.data.data as IUserTypesProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_users_types.forEach((userType) => {
            new_table.data.push({
              id: userType.id,
              name: userType.name,
              this: userType,
            });
          });
          return { ...new_table };
        });

        setNumOfPersons(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelos grupos de acomodações!", "error");
      }
      setLoadingPersons(false);
    },
    [user, filters, searchString]
  );

  const openModalNewUserType = useCallback(() => {
    setAccommodatonGroupSelected(null);
    setOpenModal(true);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewUserType();
  };

  const actionsInitial: IActions[] = [
    {
      main_option: { text: "Novo Grupo de Acomodações", id: "new_item" },
      className: "new_item",
      icon: <IoAddCircleOutline size={20} className="new_item" />,
      options: [],
      type: "solid",
      permissions: ["ACCOMMODATIONS-GROUPS-CREATE"],
    },
  ];

  const handleConfirm = useCallback(async (id: string) => {
      setLoadingDelete(true);
      try {
        await api.delete(`/accommodations-groups/${id}`);
        notify("Grupo de acomodações excluído com sucesso!", "success");
        await searchAccommodationTypes(1);
      } catch (error: any) {
        const default_message = "Erro ao excluir o grupo de acomodações!";
        const message = error.response?.data?.detail ?? default_message;
        notify(message, message === default_message ? "error" : "alert");
      }
      setLoadingDelete(false);
  }, [searchAccommodationTypes]);

  const alertDelete = useCallback(async (type: IUserTypesProps): Promise<void> => {
    const result = window.confirm("Tem certeza que deseja excluir o grupo " + type.name);

      if (result) handleConfirm(type.id as unknown as string);
  }, [handleConfirm]);

  const onSaveUserType = useCallback(() => {
    setOpenModal(false);
    searchAccommodationTypes(1);
  }, [user, filters, searchString]);

  const listActions = [
    {
      title: "Editar",
      onClick: (userType: IUserTypesProps) =>
        selectAccommodationGroup(userType),
      icon: <FaEdit color={secondary[theme]} size={20} />,
      disabled: () =>
        !user.user_type.permissions.includes(
          "ACCOMMODATIONS-GROUPS-LIST" as IPermissionsTypes
        ),
    },
    {
      title: "Excluir",
      onClick: (userType: IUserTypesProps) => alertDelete(userType),
      icon: <MdDelete color={secondary[theme]} size={20} />,
      disabled: () =>
        !user.user_type.permissions.includes(
          "ACCOMMODATIONS-GROUPS-DELET" as IPermissionsTypes
        ),
    },
  ];

  useEffect(() => {
    searchAccommodationTypes(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingPersons}
      setLoading={setLoadingPersons}
      numberOfItems={numOfPersons}
      actions={actionsInitial}
      onClickOption={handleAction}
      textHeader="Grupos de Acomodações"
      textItems="grupos de acomodações encontradas"
      text_loading={"Buscando Grupos de Acomodações"}
      text_empty={"Nenhum grupo de acomodações encontrado"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      listActions={listActions}
      pages={numberOfPages}
      page={atualPage}
      search={searchAccommodationTypes}
      onChangeSearch={setSearchString}
    >
      {openModal && (
        <ModalAccommodationGroup
          onCancel={() => setOpenModal(false)}
          onSave={onSaveUserType}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={accommodationGroupSelected?.id}
        />
      )}
      {loadingDelete && <ModalLoading loading={loadingDelete} theme={theme} />}
    </PageList>
  );
};
