import Button from "../../../components/Buttons/Button";
import { Form } from "./style";
import { useForm } from "react-hook-form";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { FormEvent, useCallback, useRef, useState } from "react";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import { IGroupProps, IOptionsProps, franchiseProps } from "../../../interfaces";
import { GenericForm, IGenericFormRefProps } from "../../../components/GenericForm";
import { validateCEP, validateCNPJ, validateEmail, validatePhone } from "../../../utils/validatesFields";

interface ICompanyDataFormProps {
    defaultData?: franchiseProps | null;
    defaultFranchise?: franchiseProps | null;
}

const getDefaultResponsible = (props: any) => {
    const { defaultFranchise, user } = props
    if (defaultFranchise) return { label: defaultFranchise.responsible?.registration_data.name, value: defaultFranchise.responsible?.id } as IOptionsProps
    else if (user && ["responsible"].includes(user.type)) return { label: user?.person?.registration_data.name, value: user?.person_id } as IOptionsProps
    else return undefined
}

export const CompanyDataForm: React.FC<ICompanyDataFormProps> = (props) => {
    const { defaultData, defaultFranchise } = props

    const navigate = useNavigate()
    const { notify, theme } = useGlobal()
    const { user, api } = useApi()

    const [loadingCEP, setLoadingCEP] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)

    const _defaultData = {
        type: { label: "Pessoa Jurídica", value: "PJ" },
        responsible_id: getDefaultResponsible({ user, defaultFranchise })
    }

    const defaultValues = defaultData?.id ? defaultData : _defaultData as any

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({ defaultValues, mode: "all" });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)

    const onSubmit = useCallback(async () => {

        setLoadingSave(true)

        try {

            const form = form_ref.current?.getForm()

            const data = { ...(form?.address ?? {}), ...(form?.registration_data ?? {}) }

            await api.put('/company-constants', data)

            notify("Dados da empresa atualizados com sucesso.", "success")

            navigate("/")

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingSave(false)

    }, [api, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "registration_data",
            label: "Dados de Registro",
            fields: [
                [
                    {
                        name: "COMPANY_NAME",
                        label: "Razão Social",
                        type: "input",
                        required: true
                    },
                    {
                        name: "COMPANY_FANTASY_NAME",
                        label: "Nome Fantasia",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "COMPANY_CNPJ",
                        label: "CNPJ",
                        type: "input",
                        mask: "cnpj",
                        required: true,
                        validate: validateCNPJ
                    },
                    {
                        name: "COMPANY_PHONE",
                        label: "Telefone",
                        type: "input",
                        mask: "phone",
                        validate: validatePhone,
                        required: true
                    }
                ],
                [
                    {
                        name: "NOTIFICATIONS_EMAIL",
                        label: "Email",
                        type: "input",
                        validate: validateEmail,
                        required: true
                    }
                ]
            ]
        },
        {
            name: "address",
            label: "Dados de Endereço",
            fields: [
                [
                    {
                        name: "COMPANY_CEP",
                        label: "CEP",
                        type: "input",
                        mask: "cep",
                        validate: validateCEP,
                        required: true
                    },
                    {
                        name: "COMPANY_STATE",
                        label: "Estado",
                        type: "input",
                        required: true
                    },
                    {
                        name: "COMPANY_CITY",
                        label: "Cidade",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "COMPANY_DISTRICT",
                        label: "Bairro",
                        type: "input",
                        required: true
                    },
                    {
                        name: "COMPANY_STREET",
                        label: "Logradouro",
                        type: "input",
                        required: true
                    },
                    {
                        name: "COMPANY_COMPLEMENT",
                        label: "Complemento",
                        type: "input"
                    },
                    {
                        name: "COMPANY_NUMBER",
                        label: "Número",
                        type: "input",
                        mask: "onlyNumber",
                        required: true
                    }
                ]
            ]
        }
    ]

    return (
        <>

            <Form onSubmit={handleStopPropagation} theme={theme}>

                <GenericForm
                    ref={form_ref}
                    groups={groups}
                    _form={_form}
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    disabled={loadingCEP}
                    setValue={setValue}
                    register={register}
                />

                <Button
                    type="submit"
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, fontSize: 14 }}
                    children={"Salvar"}
                />

            </Form>

            <ModalLoading
                loading={loadingSave}
                theme={theme}
            />

        </>

    )
}