import { BiDislike, BiHomeAlt, BiMedal } from "react-icons/bi";
import { Column, Line } from "../style";
import { DashboardCard } from "../DashboardCard";
import { useGlobal } from "../../../hooks/global";
import { DashboardChartDoughnut } from "../DashboardChartDoughnut";
import { inactiveItens, primary, secondary } from "../../../theme";
import { returnNumberWithDot } from "../utils";
import { Skeleton } from "@mui/material";
import { title } from "process";
import { FaCheckCircle, FaHourglassHalf } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";

interface IReservationsCardProps {
    statisticsPerStatus: { [key: string]: number }
    statisticsPerOrigin: { [key: string]: number }
    reservationInfos: {
        max: number;
        min: number;
        avg: number;
    }
    loading: boolean
}

const CardWithIcon = ({ icon, subTitle, value, background }: any) => {
    const { theme } = useGlobal();

    return (
        <Line style={{ gap: 10, alignItems: "center" }}>
            <div>
                <div
                    style={{
                        background,
                        padding: 10,
                        borderRadius: "50%",
                        height: 60,
                        width: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                </div>
            </div>
            <Column style={{ color: inactiveItens[theme] }}>
                <div style={{ fontSize: 35, fontWeight: 600 }}>
                    {value}
                </div>
                <div style={{ fontSize: 14, marginTop: -5 }}>{subTitle}</div>
            </Column>
        </Line>
    );
};

const reservation_infos = [
    {
        subTitle: "Realizadas",
        attribute: "realized",
        color: "#52C41A",
        icon: <FaCheckCircle color="#FFF" size={26} />,
    },
    {
        subTitle: "Pendentes",
        attribute: "pending",
        color: "#FAAD14",
        icon: <FaHourglassHalf color="#FFF" size={26} />,
    },
    {
        subTitle: "Em Atraso",
        attribute: "late",
        color: "#FF6B6B",
        icon: <FiAlertTriangle color="#FFF" size={26} />,
    },
];

const status_mapper: any = {
    'BOOKED': {
        'title': 'Reservado',
        'color': '#52C41A',
    },
    'CANCELED': {
        'title': 'Cancelado',
        'color': '#CCCCCC',
    },
    'RESERVED': {
        'title': 'Pré-reserva',
        'color': '#FAAD14',
    },
    'BLOCKED': {
        'title': 'Bloqueado',
        'color': '#FF6B6B',
    }
}

const origin_mapper: any = {
    'EXTERNAL API': {
        title: 'WE.HOUSY APP',
        color: '#673AB7',
    },
    'API AIRBNB': {
        title: 'AIRBNB',
        color: '#ED6665',
    },
    'API BOOKING.COM': {
        title: 'BOOKING',
        color: '#183B79',
    },
    'API DECOLAR': {
        title: 'DECOLAR',
        color: '#5924E9',
    },
    'API EXPEDIA': {
        title: 'EXPEDIA',
        color: '#F3C244',
    },
    'CASA TEMPORADA': {
        color: '#C23041',
    },
    'API GOOGLEVR': {
        color: '#34A853',
    },
    'TEMPORADA LIVRE': {
        color: '#439AC5',
    },
    'VRBO': {
        color: '#122248',
    },
    'YUCA': {
        color: '#333333',
    },
    'HOUSI': {
        color: '#DD4B70',
    },
    'HOMES & VILLAS': {
        color: '#EA996E',
    },
    'DESCONHECIDO': {
        title: 'STAYS',
        color: '#3EBCE2',
    },
    'WEBSITE': {
        color: '#FF3F05',
    },
}

export const ReservationsCard: React.FC<IReservationsCardProps> = ({ reservationInfos, statisticsPerOrigin, statisticsPerStatus, loading }) => {
    const { theme } = useGlobal();


    if (loading) return (
        <Line style={{ gap: 30, flexWrap: "wrap" }}>
            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />

            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />

            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />
        </Line>
    )

    return (
        <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
            <DashboardChartDoughnut
                theme={theme}
                title={'Reservas por Origem'}
                infos={Object.values(statisticsPerOrigin)}
                labels={Object.keys(statisticsPerOrigin).map((key) => origin_mapper?.[key?.toUpperCase().trim()]?.title ?? key)}
                colors={Object.keys(statisticsPerOrigin).map((key) => origin_mapper?.[key?.toUpperCase().trim()]?.color ?? key)}
                minWidth={250}
            />
            <DashboardChartDoughnut
                theme={theme}
                title={'Reservas por Status'}
                infos={Object.values(statisticsPerStatus)}
                labels={Object.keys(statisticsPerStatus).map((key) => status_mapper?.[key]?.title ?? key)}
                colors={Object.keys(statisticsPerStatus).map((key) => status_mapper?.[key]?.color ?? key)}
                minWidth={250}
            />
            <DashboardCard
                minWidth={250}
                title="Check-ins por status"
                theme={theme}
            >
                <Column
                    style={{
                        background: primary[theme],
                        padding: 20,
                        gap: 20,
                        flex: 1,
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        justifyContent: "space-between",
                    }}
                >
                    {reservation_infos.map(({ subTitle, attribute, icon, color }) => (
                        <CardWithIcon
                            key={subTitle}
                            icon={icon}
                            subTitle={subTitle}
                            value={returnNumberWithDot(reservationInfos[attribute as 'max' | 'min' | 'avg']).split(',')[0]}
                            background={color}
                        />
                    ))}
                </Column>
            </DashboardCard>
        </Line>
    )
}