import React from "react";
import { useApi } from "../hooks/api";
import { Navigate, Outlet } from "react-router-dom";
import { IPermissionsTypes } from "../utils/permissions";

interface PrivateRouteProps {
  permissions?: IPermissionsTypes[];
  redirectTo?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  permissions = [],
  redirectTo = "/",
}) => {
  const { user } = useApi();

  if (
    permissions.every((permission) =>
      user.user_type.permissions.includes(permission)
    )
  ) return <Outlet />;

  return <Navigate to={redirectTo} />;
};
