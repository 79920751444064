import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, shadowColor } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`