import Button from "../../../Buttons/Button";
import React from "react";
import { inactiveItens, secondary } from "../../../../theme";
import { AddButton, Column } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { IGroupProps } from "../../../../interfaces";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { ContainerButtonAdd, ContainerGroups, ContainerGuests, ContainerRemove, DescriptionGuests, EmptyText, Group, HeaderGuests, TitleGuests } from "./style";
import { Tooltip } from "../../../Tooltip";
import { FaUsers } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { ContainerButtons } from "../form/style";
import { Control, FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { GuestsDocumentsCard } from "../guestsDocumentsCard";

export interface IGetGuests {
  name: string;
  email: string;
  phone: string;
  document_type: string;
  document_value: string;
  document_image_front: string;
  document_image_back: string;
  selfie_image: string;
  uniqueId: string;
}

interface IFormReservationModalProps {
  guestsGroups: IGroupProps[];
  _form: any;
  addGuestGroup: VoidFunction;
  removeOwnerGroup: (index: number) => void;
  getGuests: () => IGetGuests[];
  guestsRefs: React.MutableRefObject<IGenericFormRefProps[]>;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  trigger: UseFormTrigger<any>;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  buttonSubmitRef: React.RefObject<HTMLButtonElement>;
  loadingSave: boolean;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  guestsDocumentsRefs: any
}

export const FormCheckIn: React.FC<IFormReservationModalProps> = (props) => {
  const {
    guestsGroups,
    _form,
    addGuestGroup,
    removeOwnerGroup,
    control,
    errors,
    guestsRefs,
    register,
    setValue,
    trigger,
    buttonSubmitRef,
    loadingSave,
    setError,
    clearErrors,
    guestsDocumentsRefs
  } = props;
  const { theme } = useGlobal();

  return (
    <>
      <ContainerGuests theme={theme}>
        <HeaderGuests theme={theme}>
          <FaUsers size={24} color={secondary[theme]} />
          <Column>
            <TitleGuests>Hóspedes</TitleGuests>
            <DescriptionGuests>Informe os dados dos hóspedes</DescriptionGuests>
          </Column>
        </HeaderGuests>

        <ContainerGroups>
          {guestsGroups.map((guest_group, index) => (
            <Group key={guest_group.uniqueId}>
              {guestsGroups.length > 1 && (
                <ContainerRemove>
                  <Tooltip
                    onClick={() => removeOwnerGroup(index)}
                    style={{ height: 36, width: 36 }}
                    title="Excluir"
                    children={
                      <div>
                        <BsTrash color={inactiveItens[theme]} size={20} />
                      </div>
                    }
                  />
                </ContainerRemove>
              )}
              <Column style={{ flex: 1 }}>
                <GenericForm
                  ref={(ref: any) => (guestsRefs.current[index] = ref)}
                  groups={[guest_group]}
                  _form={_form}
                  control={control}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                  register={register}
                />
              </Column>
            </Group>
          ))}
          {guestsGroups.length === 0 && (
            <EmptyText>Nenhum hóspede informado</EmptyText>
          )}
          {_form?.reservation_stays_id?.value && (
            <ContainerButtonAdd>
              <AddButton
                noHaveDelete={!(guestsGroups.length > 1)}
                onClick={addGuestGroup}
              >
                Incluir+
              </AddButton>
            </ContainerButtonAdd>
          )}
        </ContainerGroups>
      </ContainerGuests>

      {guestsGroups.map((guest_group, index) => (
        <GuestsDocumentsCard
          key={guest_group.uniqueId}
          guestGroup={guest_group}
          setError={setError}
          clearErrors={clearErrors}
          _form={_form}
          setValue={setValue}
          ref={(el) => {
            if (el) {
              guestsDocumentsRefs.current[index] = el; 
            }
          }}
          />
      ))}

      <button
        ref={buttonSubmitRef}
        type="submit"
        style={{ display: "none" }}
      />

      <ContainerButtons theme={theme}>
        <Button
          onClick={() => buttonSubmitRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingSave} theme={theme} />
    </>
  );
};
