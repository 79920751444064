import { useCallback, useEffect, useRef, useState } from "react"
import { Line } from "../../../../style"
import { borderColors, secondary } from "../../../../theme"
import { useGlobal } from "../../../../hooks/global"
import { IconButton, Tooltip } from "@material-ui/core"
import { MdOutlineCancel } from "react-icons/md"
import { IInputDocumentRefProps, InputDocument } from "../../../Inputs/InputFile"
import { useApi } from "../../../../hooks/api"
import { fileProps } from "../../../../interfaces"
import { UploadIcon } from "../../../SvgComponents/Icons/UploadIcon"
import { MyCircularProgress } from "../../../MyCircularProgress"
import { ModalTakePicture } from "./ModalTakePicture"
import { ModalEditPicture } from "./ModalEditPicture"
import { FiDownload } from "react-icons/fi"

interface IDocumentOrPicture {
    guest: string;
    name: string
    defaultDocument: string | null
    onChange: (file: string | null) => void
    type: 'document' | 'selfie'
}

export const DocumentOrPicture: React.FC<IDocumentOrPicture> = ({ name, defaultDocument, onChange, type, guest }) => {
    const [doc, setDoc] = useState<string | null>(defaultDocument)
    const { theme } = useGlobal()
    const { api } = useApi()
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<fileProps | null>(null);

    const [documentHover, setDocumentHover] = useState<boolean>(false)

    const anchor_ref = useRef<HTMLAnchorElement>(null)
    const inputFileRef = useRef<IInputDocumentRefProps>(null);
    const [openModal, setOpenModal] = useState<'document' | 'selfie' | null>(null)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [mirrored, setMirrored] = useState<boolean>(false)

    const transformImageInBase64 = useCallback(async (image: any) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("photo", image.file);
            const { data } = await api.post("/photos/transform", formData);
            setDoc(data.complete);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }, []);

    const handleDocumentDestroy = (document: any) => {
        const confirmed = window.confirm("Deseja realmente excluir este documento?")
        if (confirmed) setDoc(null)
    }

    const handleChangeDocument = (_isloading: boolean, type: 'document' | 'selfie') => {
        if (_isloading) return;
        const confirmedSelfie = window.confirm("Deseja utilizar a câmera para tirar a foto?\n\nClique em 'OK' para tirar a foto.\n\nClique em 'Cancelar' para escolher um arquivo.");
        if (!confirmedSelfie) inputFileRef.current?.focus()
        else setOpenModal(type)
    }

    useEffect(() => {
        if (file) transformImageInBase64(file);
    }, [file]);

    useEffect(() => {
        onChange(doc)
    }, [doc])

    return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 10, minWidth: 300 }}>
            <div style={{ display: "flex" }}>
                <div style={{ padding: "5px 10px", borderRadius: 5, background: secondary[theme], color: "#FFF", fontWeight: 600, textTransform: "uppercase", fontSize: 12 }}>
                    {name}
                </div>
            </div>
            {!!doc && (
                <div onMouseMove={() => setDocumentHover(true)} onMouseLeave={() => setDocumentHover(false)} style={{ minHeight: 232, position: "relative", maxHeight: 232, minWidth: 200, flex: 1, cursor: "pointer", border: `1px solid ${borderColors[theme]}`, borderRadius: 5 }}>
                    {documentHover &&
                        <Line style={{ gap: 10, position: "absolute", top: -10, right: -10, zIndex: 99 }}>
                            <Tooltip onClick={() => anchor_ref?.current?.click()} style={{ margin: 0, padding: 10, borderRadius: 5, background: "#007BFF" }} title="Baixar">
                                <IconButton aria-label="Baixar">
                                    <FiDownload
                                        color={"#FFF"}
                                        size={20}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip onClick={handleDocumentDestroy} style={{ margin: 0, padding: 10, borderRadius: 5, background: "#FF6B6B" }} title="Excluir">
                                <IconButton aria-label="Excluir">
                                    <MdOutlineCancel
                                        color={"#FFF"}
                                        size={20}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Line>
                    }
                    <div onClick={() => setEditModal(true)} style={{ minHeight: 230, maxHeight: 230, transform: mirrored ? 'scale(-1,1)' : undefined, display: "flex", flexDirection: "column", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundImage: `url("${doc}")`, borderRadius: 5 }}>
                    </div>
                    <a ref={anchor_ref} style={{ display: "none" }} target="_blank" href={`${doc}`} download={`${name.toUpperCase()} - ${guest.toUpperCase()}`}>Download</a>
                </div >
            )}
            {!doc && (
                <div style={{ display: "flex", flex: 1, minHeight: 250, maxHeight: 250, }}>
                    <div style={{ display: "none" }}>
                        <InputDocument
                            ref={inputFileRef}
                            onChange={(e) => setFile(e)}
                            fileTypes={["jpeg", "jpg"]}
                        />
                    </div>
                    <div
                        onClick={() => handleChangeDocument(loading, type)}
                        style={{
                            display: "flex",
                            padding: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px dashed #E5E5E5",
                            borderRadius: 5,
                            flexDirection: "column",
                            flex: 1,
                            cursor: !loading ? "pointer" : "default",
                            marginBottom: 20,
                        }}
                    >
                        {!loading && (
                            <>
                                <UploadIcon />
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 18,
                                        textAlign: "center",
                                        maxWidth: 280,
                                        marginTop: 20,
                                    }}
                                >
                                    Clique aqui ou arraste um arquivo para carregar
                                </div>
                                <div style={{ fontSize: 14 }}>Formato JPG ou JPEG.</div>
                            </>
                        )}
                        {loading && (
                            <>
                                <MyCircularProgress color="#00FE79" size={30} />
                                <div style={{ marginTop: 20, fontWeight: "bold" }}>
                                    Carregando documento
                                </div>
                                <div style={{ fontSize: 14 }}>Processando a imagem...</div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {openModal && (
                <ModalTakePicture
                    onClose={() => setOpenModal(null)}
                    onTakePicture={(image) => {
                        setDoc(image);
                        setOpenModal(null);
                    }}
                    type={openModal}
                />
            )}
            {editModal && defaultDocument && (
                <ModalEditPicture
                    onClose={() => setEditModal(false)}
                    onTakePicture={(image) => {
                        setDoc(image);
                        setEditModal(false);
                    }}
                    type={type}
                    defaultImage={defaultDocument}
                />
            )}
        </div>
    )
}