import styled, { css } from "styled-components";
import { IThemeProps } from "../../../interfaces";

export const Title = styled.div<IThemeProps>`
  padding-bottom: 10px;
  align-items: center;
  display: flex;
  ${(props) => css`
    border-bottom: 1px solid ${props.theme === "light" ? "#CCCCCC" : "#545454"};
  `}
`;

export const ContainerLastUpdate = styled.div<{ menuIsOpen: boolean }>`
  gap: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  ${(props) =>
    !props.menuIsOpen &&
    css`
      @media (max-width: 440px) {
        display: none;
      }
    `}
  ${(props) =>
    props.menuIsOpen &&
    css`
      @media (max-width: 550px) {
        display: none;
      }
    `}
`;
