import {
  documentEletronicBondsOptions,
  documentsTypesOptions,
  paymentStatusOptions,
  personsTypesOptions,
  reservationStatusOptions,
  statusProposalOptions,
  warrantyNameOptions,
} from "./options";

const fields = {
  franchise_id: {
    key: "franchise_id",
    name: "Imobiliária",
    type: "select_search",
    searchOptionsKey: "franchise_id",
    loading: true,
  },
  broker_id: {
    key: "broker_id",
    name: "Corretor",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
    moreQueries: { person_types: ["broker"] },
  },
  user_type_id: {
    key: "user_type_id",
    name: "Tipo",
    type: "select_search",
    searchOptionsKey: "user_type_id",
    loading: true,
    noIcon: true,
  },
  access_type: {
    key: "access_type",
    name: "Tipo de acesso",
    type: "select",
    options: [
      { label: "Fechadura comum", value: "normal" },
      { label: "Fechadura eletrônica online", value: "on" },
      { label: "Fechadura eletrônica offline", value: "off" },
    ],
    noIcon: true,
  },
  order_by: {
    key: "order_by",
    name: "Ordenar por",
    type: "select",
    options: [
      { label: "Entrada", value: "check_in" },
      { label: "Saída", value: "check_out" },
    ],
    noIcon: true,
  },
  smartlock_status: {
    key: "status",
    name: "Status",
    type: "select",
    options: [
      { label: "Online", value: "on" },
      { label: "Offline", value: "off" },
    ],
    noIcon: true,
  },
  contract_author_id: {
    key: "person_id",
    name: "Autor do documento",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
  },
  query_author_id: {
    key: "person_id",
    name: "Autor da consulta",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
  },
  analyst_id: {
    key: "analyst_id",
    name: "Analista",
    type: "select_search",
    searchOptionsKey: "analyst_id",
    loading: true,
  },
  proposal_status: {
    key: "status",
    name: "Status",
    type: "select",
    options: statusProposalOptions,
  },
  check_in_status: {
    key: "check_in_status",
    name: "Status",
    type: "select",
    noIcon: true,
    options: [
      { label: "Pendente", value: "pending" },
      { label: "Realizado", value: "done" },
    ],
  },
  payment_status: {
    key: "payment_status",
    name: "Status do pagamento",
    type: "select",
    noIcon: true,
    options: paymentStatusOptions,
  },
  reservation_status: {
    key: "reservation_status",
    name: "Status da reserva",
    type: "select",
    noIcon: true,
    options: reservationStatusOptions,
  },
  check_in: {
    key: "check_in",
    name: "Período (entrada)",
    type: "range_picker",
  },
  check_out: {
    key: "check_out",
    name: "Período (saída)",
    type: "range_picker",
  },
  created_at: {
    key: "created_at",
    name: "Período (criação)",
    type: "range_picker",
  },
  created_at_histories: {
    key: "created_at",
    name: "Criado a partir de",
    type: "input",
    mask: "date",
    noIcon: true,
  },
  state_id: {
    key: "state_id",
    name: "Estado",
    type: "select",
    loading: true,
  },
  city_id: {
    key: "city_id",
    name: "Cidade",
    type: "select",
    loading: false,
    options: [],
  },
  person_type: {
    key: "type",
    name: "Tipo",
    type: "select",
    options: personsTypesOptions,
    noIcon: true,
  },
  type_consult: {
    key: "type_consult",
    name: "Tipo",
    type: "select",
    options: personsTypesOptions,
  },
  type: {
    key: "type",
    name: "Tipo",
    type: "select",
    noIcon: true,
    options: [
      { label: "WhatsApp", value: "wpp" },
      { label: "Email", value: "email" },
    ],
  },
  property_type_id: {
    key: "property_type_id",
    name: "Tipo de imóvel",
    type: "select_search",
    searchOptionsKey: "property_type_id",
    loading: true,
  },
  value_min: {
    key: "value_min",
    name: "Valor mínimo",
    type: "input",
    mask: "currency",
  },
  value_max: {
    key: "value_max",
    name: "Valor máximo",
    type: "input",
    mask: "currency",
  },
  occupation: {
    key: "occupation",
    name: "Status",
    type: "select",
    noIcon: true,
    options: [
      { label: "Disponível", value: "not_have" },
      { label: "Ocupado", value: "have" },
    ],
  },
  bond_type: {
    key: "bond_type",
    name: "Vínculo",
    type: "select",
    options: documentEletronicBondsOptions,
  },
  document_eletronic_status: {
    key: "status",
    name: "Status",
    type: "select",
    loading: false,
    options: [
      { label: "Aguardando assinatura", value: "pending" },
      { label: "Cancelado", value: "canceled" },
      { label: "Assinado", value: "signed" },
    ],
  },
  user_type: {
    key: "person_type",
    name: "Tipo",
    type: "select",
    noIcon: true,
    options: [
      { value: "broker", label: "Corretor" },
      { value: "analyst", label: "Analista" },
      { value: "manager", label: "Gerente" },
    ],
  },
  document_types_type: {
    key: "type",
    name: "Tipo",
    type: "select",
    noIcon: true,
    options: documentsTypesOptions,
  },
  warranty_type: {
    key: "warranty_type",
    name: "Garantia",
    type: "select",
    noIcon: true,
    options: warrantyNameOptions,
  },
  battery_max: {
    key: "battery_max",
    name: "Bateria máxima",
    type: "input",
    mask: "onlyNumber",
    noIcon: true,
  },
  battery_min: {
    key: "battery_min",
    name: "Bateria mínima",
    type: "input",
    mask: "onlyNumber",
    noIcon: true,
  },
  is_managed: {
    key: "is_managed",
    name: "Administrado?",
    type: "select",
    noIcon: true,
    options: [
      { value: "true", label: "Sim" },
      { value: "false", label: "Não" },
    ],
  },
  accommodation_status: {
    key: "status",
    name: "Status",
    type: "select",
    options: [
      { label: "Ativo", value: "active" },
      { label: "Ativo porém oculto", value: "hidden" },
      { label: "Inativo", value: "inactive" },
      { label: "Rascunho", value: "draft" },
    ],
  },
};

type FieldsKeys = keyof typeof fields;

export const getFilterFields = (type: FieldsKeys) => {
  const field: any = fields?.[type] ?? {};

  return field;
};
