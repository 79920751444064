import styled from "styled-components";

export const BallonCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  width: 80%;
  justify-content: center;
  align-items: center;
  position: relative;
  ::before {
    border-style: solid;
    border-color: #f6f6f6 transparent;
    border-width: 16px 16px 0 16px;
    bottom: -10px;
    content: "";
    position: absolute;
    rotation: 90deg;
  }
`;
