import { DashboardPage } from "../../components/DashboardPage"
import DashboardImage from "../../assets/home.svg"
import { Image } from "@mantine/core";
import { useGlobal } from "../../hooks/global";

export const Home: React.FC = () => {
    const { theme } = useGlobal()
    return (
        <DashboardPage>
            <div className="home-container">
                <img height={300} src={DashboardImage} style={{ objectFit: 'contain' }} />
                <h1 className="home-message" style={{ color: theme === 'dark' ? '#FFF' : '#000' }}>
                    Bem vindo ao painel administrativo da We.Housy
                </h1>
            </div>
        </DashboardPage>
    )
}