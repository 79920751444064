import styled, { css } from "styled-components";
import { themeTypes } from "../../../interfaces";
import {
  inactiveItens,
  primary,
  rankingBackgroundOne,
  rankingBackgroundTwo,
} from "../../../theme";

export const TH = styled.th`
  white-space: nowrap;
  padding: 6px 26px 16px 26px;
  font-size: 12px;
  opacity: 0.7;
`;

export const TD = styled.td`
  padding: 10px 26px;
  white-space: nowrap;
  cursor: default !important;
`;

export const Content = styled.div<{ theme: themeTypes; maxWidth: string }>`
  text-transform: uppercase;
  overflow: auto;
  height: 100%;
  padding: 10px 20px;
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-width: ${(props) => props.maxWidth};
  background: ${(props) => primary[props.theme as themeTypes]};
`;

export const ContainerRanking = styled.div<{ theme: themeTypes }>`
  display: flex;
  flex-direction: column;
  background: ${(props) => primary[props.theme as themeTypes]};
  flex: 1;
`;

export const ContainerLogo = styled.div<{ theme: themeTypes }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${(props) => inactiveItens[props.theme as themeTypes]};
`;

export const Img = styled.img`
  min-width: 30px;
  min-height: 30px;
`;

export const ContainerLogoDefault = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableLine = styled.tr<{
  isPair: boolean;
  isPodium: boolean;
  theme: themeTypes;
}>`
  background: ${(props) =>
    props.isPair
      ? rankingBackgroundOne[props.theme as themeTypes]
      : rankingBackgroundTwo[props.theme as themeTypes]};
  font-weight: ${(props) => (props.isPodium ? 600 : "normal")};
`;

export const ContainerPagination = styled.div<{ theme: themeTypes }>`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
  background: ${(props) => primary[props.theme as themeTypes]};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ContainerArrow = styled.div<{ invert: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -26px;
  top: 50%;
  ${(props) =>
    props.invert &&
    css`
      transform: rotate(180deg) translateY(50%);
    `}
  ${(props) =>
    !props.invert &&
    css`
      transform: translateY(-50%);
    `}
`;
