import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../interfaces";
import { activeItens, borderColors, primary, separator } from "../../theme";

interface ISwitchContainerProps extends IThemeProps {
    open: boolean;
}

export const SwitchContainer = styled.div<ISwitchContainerProps>`
    justify-content: flex-end;
    display: ${props => props.open ? "flex" : "none"};
    ${props => css`
        .custom-switch {
            display: block !important;
            background: ${separator[props.theme as themeTypes]} !important;
            width: 58px !important;
            height: 27px !important;
            border:none;
            :after {
                width: 18px !important;
                height: 18px !important;
                top: 4px !important;
                margin-left: 3px !important;
                box-shadow: initial;
                transform: initial !important;
                animation-name: unset !important;
                margin-top:1px;
                background: ${primary[props.theme as themeTypes]} !important;
            }
            &.rc-switch:after {
                box-shadow: none !important;
            }
        
            &.rc-switch-checked:after {
                left: 30px !important;
                box-shadow: none !important;
            }
        
            &.rc-switch-checked.custom-switch-primary {
                background: ${activeItens["dark"]} !important;
                border: 1px solid $theme-color-1 !important;
            }
        
            &.rc-switch-checked.custom-switch-secondary {
                background: $theme-color-2 !important;
                border: 1px solid $theme-color-2 !important;
            }
        
            &.custom-switch-primary-inverse {
                border: 1px solid $separator-color !important;
            }
        
            &.rc-switch-checked.custom-switch-primary-inverse {
                background: $input-background !important;
                border: 1px solid $theme-color-1 !important;
                &:after {
                    background: $theme-color-1 !important;
                }
            }
        
            &.custom-switch-secondary-inverse {
                border: 1px solid $separator-color !important;
            }
        
            &.rc-switch-checked.custom-switch-secondary-inverse {
                background: $input-background !important;
                border: 1px solid $theme-color-2 !important;
                &:after {
                    background: $theme-color-2 !important;
                }
            }
        }
  
        .rc-switch:focus {
            box-shadow: initial !important;
        }
        
        .rc-switch.custom-switch.custom-switch-small {
            width: 34px !important;
            height: 19px !important;
        }
        
        .rc-switch.custom-switch.custom-switch-small:after {
            width: 12px !important;
            height: 12px !important;
            left: 0px !important;
            top: 2px !important;
        }
        
        .rc-switch-checked.custom-switch.custom-switch-small:after {
            left: 14px !important;
        }
  `}
  
`

export const IntegrationDescription = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    flex: 1;
`

export const ContainerIntegrationLogo = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 22px;
`

export const IntegrationContainer = styled.div<IThemeProps>`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    min-width: 450px;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]} ;
        background: ${primary[props.theme as themeTypes]};
    `}
`

export const ContainerIntegrations = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
`