import React from "react";
import { GlobalProvider } from "./global";
import { ApiProvider } from "./api";

const AppProvider: React.FC = ({ children }) => {
  return (
    <GlobalProvider>
      <ApiProvider>{children}</ApiProvider>
    </GlobalProvider>
  );
};

export default AppProvider;
