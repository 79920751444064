import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { IOptionsProps } from "../../../interfaces";
import { FormModalNotificationRule } from "./form/index";
import { activityOptions } from "./options/activityOptions";
import { sendTypeOptions } from "./options/sendTypeOptions";
import { timeModeOptions } from "./options/timeModeOptions";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalNotificationRuleProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Regra de notificação",
    edit: "Informações da Regra de notificação",
  },
  subTitle: {
    new: "Informe os dados da regra de notificação",
    edit: "Segue abaixo as informações da regra de notificação",
  },
};

export const ModalNotificationRule: React.FC<IModalNotificationRuleProps> = (
  props
) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);
  const [accommodationOptions, setAccommodationOptions] = useState<{ label: string; value: string }[]>([]);
  const [accommodationGroupOptions, setAccommodationGroupOptions] = useState<{ label: string; value: string }[]>([]);
  const [wppModelsOptions, setWppModelsOptions] = useState<{ label: string; value: string }[]>([]);
  const [emailModelsOptions, setEmailModelsOptions] = useState<{ label: string; value: string }[]>([]);

  const searchAccommodations = useCallback(async () => {
    const { data } = await api.get("/accommodations/all");

    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.id + " - " + item.name,
      value: String(item.id),
    }));

    setAccommodationOptions(_data);

    return _data as IOptionsProps[];
  }, []);

  const searchAccommodationsGroups = useCallback(async () => {
    const { data } = await api.get("/accommodations-groups/all");

    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.name,
      value: String(item.id),
    }));

    setAccommodationGroupOptions(_data);

    return _data as IOptionsProps[];
  }, []);

  const searchModelsWpp = useCallback(async () => {
    const { data } = await api.get("/message-models/all", { params: { type: "wpp" } });

    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.name,
      value: String(item.id),
    }));

    setWppModelsOptions(_data);

    return _data as IOptionsProps[];
  }, []);

  const searchModelsEmail = useCallback(async () => {
    const { data } = await api.get("/message-models/all", { params: { type: "email" } });

    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.name,
      value: String(item.id),
    }));

    setEmailModelsOptions(_data);

    return _data as IOptionsProps[];
  }, []);

  const searchNotificationRule = useCallback(async () => {
    const [accommodations, accommodationsGroups, wppModels, emailModels] = await Promise.all([
      searchAccommodations(),
      searchAccommodationsGroups(),
      searchModelsWpp(),
      searchModelsEmail()
    ]);

    if (defaultId) {
      setLoading(true);
      try {

        const { data } = await api.get(`/notification-rules/${defaultId}`);

        const _data = {} as any;

        _data.id = data.id;
        _data.name = data.name;
        _data.send_type = sendTypeOptions.find((item) => item.value === data.send_type) ?? null;
        _data.group_id = accommodationsGroups.find((item) => item.value === data.group_id) ?? null;
        _data.accommodation_id = accommodations.find((item) => item.value === data.accommodation_id) ?? null;
        _data.model_id = (wppModels.find((item) => item.value === data.model_id) || emailModels.find((item) => item.value === data.model_id)) ?? null;
        _data.activity = activityOptions.find((item) => item.value === data.activity) ?? null;
        _data.time_mode = timeModeOptions.find((item) => item.value === data.time_mode) ?? null;

        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar a regra de notificação", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  useEffect(() => {
    searchNotificationRule();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormModalNotificationRule
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              accommodationOptions={accommodationOptions}
              groupOptions={accommodationGroupOptions}
              wppModels={wppModelsOptions}
              emailModels={emailModelsOptions}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
