import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { FormMessageModel } from "./form";

interface IModalMessageModelProps {
    onCancel: Function;
    onSave: Function;
    defaultId?: string;
    openModal: boolean;
    backgroundVisible?: boolean;
}

const texts: any = {
    title: {
        new: "Cadastro de Modelo de mensagem",
        edit: "Informações do Modelo de mensagem",
    },
    subTitle: {
        new: "Informe os dados do modelo de mensagem",
        edit: "Segue abaixo as informações do modelo de mensagem",
    },
};

export const ModalMessageModel: React.FC<IModalMessageModelProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

    const { api } = useApi();
    const { theme, notify } = useGlobal();

    const [loading, setLoading] = useState(!!defaultId);
    const [defaultData, setDefaultData] = useState<any>(null);

    const searchMessageModel = useCallback(async () => {
        if (defaultId) {
            setLoading(true);
            try {
                const { data } = await api.get(`/message-models/${defaultId}`);
                const _data = data as any;

                _data.message = data.text;

                setDefaultData({ ..._data });
            } catch (err) {
                notify("Erro ao buscar o tipo de modelo de mensagem", "error");
                onCancel();
            } finally {
                setLoading(false);
            }
        }
    }, [defaultId]);

    useEffect(() => {
        searchMessageModel();
    }, []);

    return (
        <ModalGeneric
            backgroundVisible={backgroundVisible}
            open={openModal}
            onClose={() => { }}
        >
            <Container theme={theme}>
                <ModalHeader
                    title={texts.title[defaultId ? "edit" : "new"]}
                    subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    {!loading && (
                        <FormMessageModel
                            onSave={onSave}
                            onCancel={onCancel}
                            defaultData={defaultData}
                        />
                    )}
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    );
};
