import React from "react";
import {
  Calendar as BaseCalendar,
  utils,
} from "react-modern-calendar-datepicker";
import { useGlobal } from "../../../hooks/global";
import { secondary } from "../../../theme";
import { BaseFooter, Container } from "./style";

interface IDateRange {
  from: any;
  to: any;
}

const myCustomLocale = {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  weekDays: [
    {
      name: "Domingo",
      short: "DOM",
      isWeekend: true,
    },
    {
      name: "Segunda",
      short: "SEG",
    },
    {
      name: "Terça",
      short: "TER",
    },
    {
      name: "Quarta",
      short: "QUA",
    },
    {
      name: "QUINTA",
      short: "QUI",
    },
    {
      name: "SEXTA",
      short: "SEX",
    },
    {
      name: "SÁBADO",
      short: "SAB",
      isWeekend: true,
    },
  ],

  weekStartingIndex: 0,

  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject;
  },

  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit: any) {
    return digit;
  },

  nextMonth: "Próximo mês",
  previousMonth: "Mês anterior",
  openMonthSelector: "Abrir seletor de mês",
  openYearSelector: "Abrir seletor de ano",
  closeMonthSelector: "Fechar seletor de mês",
  closeYearSelector: "Fechar seletor de ano",
  defaultPlaceholder: "",

  from: "de",
  to: "até",

  digitSeparator: ",",

  yearLetterSkip: 0,

  isRtl: false,
};

interface ICalendar {
  apply: Function;
  initialRange?: IDateRange;
}

export const Calendar: React.FC<ICalendar> = ({
  apply,
  initialRange = { from: null, to: null },
}) => {
  const [range, setRange] = React.useState<IDateRange>(initialRange);
  const { theme } = useGlobal();
  return (
    <Container theme={theme}>
      <BaseCalendar
        value={range}
        onChange={(e: any) => setRange({ ...e })}
        colorPrimary={secondary[theme]}
        colorPrimaryLight="transparent"
        calendarClassName={`custom-calendar background-${theme}`}
        locale={myCustomLocale}
        renderFooter={() => (
          <BaseFooter onClick={() => apply(range)}>Aplicar</BaseFooter>
        )}
      />
    </Container>
  );
};
