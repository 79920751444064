import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../interfaces";
import {
  primary,
  hover,
  separator,
  activeItens,
  inactiveItens,
  shadowColor,
} from "../../theme";

interface ButtonProps {
  isCollapse?: boolean;
}

export const Container = styled.div<IThemeProps>`
  min-height: 82px;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100%;
  padding-right: 12px;
  z-index: 201;
  ${(props) => css`
    background-color: ${primary[props.theme as themeTypes]};
    box-shadow: 0.125rem 0.125rem 0.5rem
      ${shadowColor[props.theme as themeTypes]};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px;
  padding-right: 20px;
  justify-content: space-between;
`;

export const Logo = styled.div<{ chooseFranchise: boolean }>`
  margin-right: 30px;
  ${(props) =>
    !props.chooseFranchise &&
    css`
      @media (max-width: 680px) {
        display: none;
      }
    `}
`;

export const ContainerLogo = styled.div<{ chooseFranchise: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 30px;
  @media (max-width: 680px) {
    margin-left: 36px;
  }
  ${(props) =>
    props.chooseFranchise &&
    css`
      margin-left: 20px !important;
    `}
`;

export const Button = styled.div<ButtonProps>`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  position: relative;
  ${(props) => css`
    :hover {
      background: ${hover[props.theme as themeTypes]};
    }
  `}
  ${(props) =>
    props.isCollapse &&
    css`
      @media (max-width: 680px) {
        display: none;
      }
    `}
`;

export const Perfil = styled.div`
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 40px;
  overflow: hidden;
`;

export const ButtonFranchise = styled.div<{ theme: themeTypes }>`
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  gap: 5px;
  height: 62px;
  ${(props) => css`
    color: ${inactiveItens[props.theme as themeTypes]};
    :hover {
      background: ${hover[props.theme as themeTypes]};
      color: ${inactiveItens[props.theme === "dark" ? "light" : "dark"]};
    }
  `}
`;

export const ButtonMaxCoins = styled.div<{ theme: themeTypes }>`
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  height: 62px;
  color: #fff;
  background: linear-gradient(to right, #e20055, #000094);
  @media (max-width: 550px) {
    display: none;
  }
`;

export const SwitchContainer = styled.div<IThemeProps>`
  padding: 10px;
  @media (max-width: 680px) {
    display: none;
  }
  ${(props) => css`
    .custom-switch {
      display: block !important;
      background: ${separator[props.theme as themeTypes]} !important;
      width: 58px !important;
      height: 27px !important;
      border: none;
      :after {
        width: 18px !important;
        height: 18px !important;
        top: 4px !important;
        margin-left: 3px !important;
        box-shadow: initial;
        transform: initial !important;
        animation-name: unset !important;
        margin-top: 1px;
        background: ${primary[props.theme as themeTypes]} !important;
      }
      &.rc-switch:after {
        box-shadow: none !important;
      }

      &.rc-switch-checked:after {
        left: 30px !important;
        box-shadow: none !important;
      }

      &.rc-switch-checked.custom-switch-primary {
        background: ${activeItens["dark"]} !important;
        border: 1px solid $theme-color-1 !important;
      }

      &.rc-switch-checked.custom-switch-secondary {
        background: $theme-color-2 !important;
        border: 1px solid $theme-color-2 !important;
      }

      &.custom-switch-primary-inverse {
        border: 1px solid $separator-color !important;
      }

      &.rc-switch-checked.custom-switch-primary-inverse {
        background: $input-background !important;
        border: 1px solid $theme-color-1 !important;
        &:after {
          background: $theme-color-1 !important;
        }
      }

      &.custom-switch-secondary-inverse {
        border: 1px solid $separator-color !important;
      }

      &.rc-switch-checked.custom-switch-secondary-inverse {
        background: $input-background !important;
        border: 1px solid $theme-color-2 !important;
        &:after {
          background: $theme-color-2 !important;
        }
      }
    }

    .rc-switch:focus {
      box-shadow: initial !important;
    }

    .rc-switch.custom-switch.custom-switch-small {
      width: 34px !important;
      height: 19px !important;
    }

    .rc-switch.custom-switch.custom-switch-small:after {
      width: 12px !important;
      height: 12px !important;
      left: 0px !important;
      top: 2px !important;
    }

    .rc-switch-checked.custom-switch.custom-switch-small:after {
      left: 14px !important;
    }
  `}
`;
