export const timeModeOptions = [
    { label: "10 dias antes", value: "10 before" },
    { label: "9 dias antes", value: "9 before" },
    { label: "8 dias antes", value: "8 before" },
    { label: "7 dias antes", value: "7 before" },
    { label: "6 dias antes", value: "6 before" },
    { label: "5 dias antes", value: "5 before" },
    { label: "4 dias antes", value: "4 before" },
    { label: "3 dias antes", value: "3 before" },
    { label: "2 dias antes", value: "2 before" },
    { label: "1 dia antes", value: "1 before" },
    { label: "No dia", value: "on day" },
    { label: "1 dia depois", value: "1 after" },
    { label: "2 dias depois", value: "2 after" },
    { label: "3 dias depois", value: "3 after" },
    { label: "4 dias depois", value: "4 after" },
    { label: "5 dias depois", value: "5 after" },
    { label: "6 dias depois", value: "6 after" },
    { label: "7 dias depois", value: "7 after" },
    { label: "8 dias depois", value: "8 after" },
    { label: "9 dias depois", value: "9 after" },
    { label: "10 dias depois", value: "10 after" }
]

export const mapperTimeModes: { [key: string]: { value: string; label: string }[] } = {
    "RESERVATION_CREATE": timeModeOptions.filter((item) => !item.value.includes("before")),
    "RESERVATION_CANCEL": timeModeOptions.filter((item) => !item.value.includes("before")),
    "CHECKIN_DATE": timeModeOptions,
    "CHECKOUT_DATE": timeModeOptions,
    "CHECKIN_CREATE": timeModeOptions.filter((item) => !item.value.includes("before")),
}