import { Flex, Popover, Text } from "@mantine/core"
import { useDisclosure } from '@mantine/hooks';
import { MyCircularProgress } from "../MyCircularProgress"
import { BadQuality } from "../SvgComponents/Icons/BadQuality"
import { GuestCheck } from "../SvgComponents/Icons/GuestCheck"
import { useGlobal } from "../../hooks/global";
import { primary } from "../../theme";

interface IBiometricsStatusProps {
    loadingMathImages: boolean
    valid: boolean
}

const BiometricsStatus = ({
    loadingMathImages,
    valid
}: IBiometricsStatusProps) => {
    const { theme } = useGlobal();

    const [opened, { close, open }] = useDisclosure(false);

    return (
        <Popover width={350} position="bottom" shadow="lg" opened={opened}>
            <Popover.Target>
                <div
                    onMouseEnter={open}
                    onMouseLeave={close}
                >
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 14,
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            position: "relative",
                            justifyContent: "end",
                            flex: 1,
                        }}
                    >
                        {loadingMathImages && (
                            <>
                                <Text weight='bolder'>
                                    Verificação de biometria em andamento
                                </Text>
                                <MyCircularProgress color="#00FE79" size={30} />
                            </>
                        )}
                        {!loadingMathImages && (
                            <>
                                <Text weight='bolder'>Verificação da biometria</Text>
                                {valid && (
                                    <GuestCheck size={30} />
                                )}
                                {!valid && (
                                    <BadQuality size={30} />
                                )}
                            </>
                        )}

                    </div>
                </div>
            </Popover.Target>

            {!loadingMathImages && (
                <Popover.Dropdown sx={{ pointerEvents: 'none' }} bg={primary[theme]} style={{border: `1px solid ${valid ? 'green' : 'red'}` }}>
                    {valid && (
                        <Flex gap='sm' direction='column'>
                            <Text weight='bolder'>
                                Biometria confirmada
                            </Text>
                            <Text weight='bolder'>
                                A biometria do hóspede foi confirmada com sucesso.
                            </Text>
                        </Flex>
                    )}
                    {!valid && (
                        <Flex gap='sm' direction='column'>
                            <Text weight='bolder'>
                                Biometria não confirmada
                            </Text>
                            <Text size='sm'>
                                A biometria do hóspede não foi confirmada.
                            </Text>
                        </Flex>
                    )}
                </Popover.Dropdown>
            )}
        </Popover>
    )
}

export default BiometricsStatus