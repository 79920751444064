import styled from "styled-components";
import { Line } from "../../style";

export const Container = styled(Line)`
  justify-content: flex-end;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 13px;
  background: #fff;
  z-index: 10;
  @media (min-width: 1000px) {
    max-width: 600px;
  }
`;

export const ContainerImage = styled.div`
  background-image: url(/assets/bg-6.png);
  background-size: cover;
  background-position: left;
  flex: 1;
  width: calc(100vw - 600px);
  height: calc(100 * var(--vh));
  position: fixed;
  left: 0;
`;

export const Side = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
