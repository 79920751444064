import BatteryGauge from "react-battery-gauge";
import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { fail, secondary, success } from "../../theme";
import { useGlobal } from "../../hooks/global";
import { BiWifi, BiWifiOff } from "react-icons/bi";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { setUpConsultation } from "../../services/generalServices";
import {
  IAccomodationsProps,
  IActions,
  IFilters,
} from "../../interfaces/index";
import { TbKey } from "react-icons/tb";
import { ModalLoading } from "../../components/Loadings/ModalLoading";

const initialFilters: IFilters[] = [
  getFilterFields("smartlock_status"),
  getFilterFields("battery_max"),
  getFilterFields("battery_min"),
];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "alias",
      name: "ALIAS",
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "name",
      name: "Nome",
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "battery",
      name: "Bateria",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "status",
      name: "Status",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
  ],
};

export const propertyInExtension = (address: any) => {
  if (!address) return "-";
  return `${address?.street}, ${address?.region}, Nº ${address?.streetNumber}, ${address?.city} - ${address?.stateCode}`;
};

export const SmartlocksList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [numOfSmartlocks, setNumOfSmartlocks] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingSmartlocks, setLoadingSmartlocks] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [table, setTable] = useState<ITable>(initialTable);
  const [loadingOpenSmartlock, setLoadingOpenSmartlock] = useState(false);

  const searchSmartlocks = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingSmartlocks(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/smartlocks?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_smartlocks = result.data.data as {
          alias: number;
          name: string;
          mac_address: string;
          battery: number;
          online: boolean;
        }[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_smartlocks.forEach((smartlock) => {
            new_table.data.push({
              ...smartlock,
              battery: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BatteryGauge value={smartlock.battery} size={70} />
                </div>
              ),
              status: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {smartlock.online ? (
                    <BiWifi size={30} color={success} />
                  ) : (
                    <BiWifiOff size={30} color={fail} />
                  )}
                </div>
              ),
              this: smartlock,
            });
          });
          return { ...new_table };
        });

        setNumOfSmartlocks(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas fechaduras!", "error");
      }
      setLoadingSmartlocks(false);
    },
    [user, filters, searchString]
  );

  const actionsInitial: IActions[] = [];

  const handleOpenSmartlock = useCallback(async (smartlock: string) => {
    const response = confirm("Deseja realmente abrir a fechadura?");
    if (!response) return;

    setLoadingOpenSmartlock(true);
    try {
      await api.post(`/smartlocks/${smartlock}`);
      notify("Fechadura aberta com sucesso!", "success");
    } catch (err) {
      notify("Erro ao abrir a fechadura!", "error");
    }
    setLoadingOpenSmartlock(false);
  }, [])

  const listAction = [
    {
      title: "Abrir Fechadura remotamente",
      onClick: (item: any) => handleOpenSmartlock(item.alias),
      icon: <TbKey color={secondary[theme]} size={20} />,
      disabled: () => !user.user_type.permissions.includes("SMARTLOCKS-OPEN"),
    },
  ];

  useEffect(() => {
    searchSmartlocks(1);
  }, []);

  return (
    <>
      <PageList
        updatedAt={updatedAt}
        loading={loadingSmartlocks}
        setLoading={setLoadingSmartlocks}
        numberOfItems={numOfSmartlocks}
        actions={actionsInitial}
        textHeader="Fechaduras"
        textItems="fechaduras encontradas"
        text_loading={"Buscando Fechaduras"}
        text_empty={"Nenhuma fechadura encontrada"}
        theme={theme}
        initialFilters={initialFilters}
        onChangeFilters={setFilters}
        table={table}
        pages={numberOfPages}
        page={atualPage}
        search={searchSmartlocks}
        onChangeSearch={setSearchString}
        listActions={listAction}
        noActions={!user.user_type.permissions.includes("SMARTLOCKS-OPEN")}
      />
      {loadingOpenSmartlock && <ModalLoading loading theme={theme} />}
    </>
  );
};
