import Webcam from "react-webcam";
import { BallonCard } from "./style"
import { useCallback, useRef } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { ModalGeneric } from "../../../../ModalGeneric"
import { PhoneIcon } from "../../../../SvgComponents/Icons/PhoneIcon"

interface IModalTakePicture {
    onClose: () => void
    onTakePicture: (file: string) => void
    type: 'document' | 'selfie'
}

export const ModalTakePicture: React.FC<IModalTakePicture> = ({ onClose, onTakePicture, type }) => {
    const webcamRef = useRef<Webcam>(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        if (!imageSrc) return;
        onTakePicture(imageSrc);
    }, [webcamRef]);

    return (
        <ModalGeneric open onClose={() => onClose()}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minHeight: "calc(100vh)",
                    background: "#3D3D3D",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 30,
                }}
            >
                <div
                    onClick={() => onClose()}
                    style={{
                        cursor: "pointer",
                        padding: 10,
                        position: "absolute",
                        right: 0,
                        top: 0,
                    }}
                >
                    <AiOutlineCloseCircle fill="#f7f7f7" size={20} />
                </div>
                <BallonCard>
                    <div style={{ fontWeight: "bold", fontSize: 18 }}>
                        {type === 'selfie' && "Alinhe o rosto no círculo"}
                        {type === 'document' && "Alinhe o documento no centro"}
                    </div>
                    <div style={{ fontSize: 14 }}>
                        {type === 'selfie' && "Retire bonés, óculos ou outro acessório"}
                        {type === 'document' && "Tire uma foto do documento"}
                    </div>
                </BallonCard>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            border: "2px dashed #00FE79",
                            padding: 8,
                            borderRadius: type === 'selfie' ? "50%" : undefined,
                        }}
                    >
                        <div
                            style={{
                                borderRadius: type === 'selfie' ? "50%" : undefined,
                                overflow: "hidden",
                                height: type === 'selfie' ? "calc(100vh * 0.5625)" : "calc(100vh * 0.43)",
                                width: type === 'selfie' ? "calc(100vh * 0.5625)" : "calc(100vh * 0.8)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div style={{}}>
                                <Webcam
                                    style={{ height: "100%" }}
                                    ref={webcamRef}
                                    mirrored
                                    screenshotFormat="image/jpeg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        padding: 16,
                        borderRadius: "50%",
                        background: "#6BFEB1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={capture}
                >
                    <PhoneIcon color="#000" />
                </div>
            </div>
        </ModalGeneric>
    )
}