import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { useApi } from "../../../hooks/api";
import { FormSendEmailMessage } from "./form";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalSendEmailProps {
    onCancel: Function;
    onSave: Function;
    openModal: boolean;
    backgroundVisible?: boolean;
    id: string;
}

const texts: any = {
    title: "Envio de mensagem via email",
    subTitle: "Informe os dados da mensagem",
};

export const ModalSendEmail: React.FC<IModalSendEmailProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, id } = props;
    const [messageModelsOptions, setMessageModelsOptions] = useState<any[]>([]);
    const [variables, setVariables] = useState<any>({});

    const { api } = useApi();
    const { theme, notify } = useGlobal();

    const [loading, setLoading] = useState(true);

    const handleSearchMessageModels = useCallback(async () => {
        setLoading(true);
        try {
            const [{ data }, { data: data2 }] = await Promise.all([
                api.get(`/message-models?type=email&limit=100&page=1`),
                api.get(`/variables-email/${id}`)
            ]);
            setMessageModelsOptions(data.data.map((item: any) => ({ value: item.id, label: item.name, this: item })));
            setVariables(data2);
        } catch (err) {
            notify("Erro ao buscar os modelos de mensagem", "error");
            onCancel();
        }
        setLoading(false);
    }, [id]);

    useEffect(() => {
        handleSearchMessageModels();
    }, []);

    return (
        <ModalGeneric
            backgroundVisible={backgroundVisible}
            open={openModal}
            onClose={() => { }}
        >
            <Container theme={theme}>
                <ModalHeader
                    title={texts.title}
                    subTitle={texts.subTitle}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    {!loading && (
                        <FormSendEmailMessage
                            onSave={onSave}
                            onCancel={onCancel}
                            options={messageModelsOptions}
                            variables={variables}
                            emails={variables.emails}
                        />
                    )}
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    );
};
