import Switch from "rc-switch";
import Button from "../../components/Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { Column, Line } from "../../style";
import { FaWhatsapp } from "react-icons/fa";
import { io, Socket } from "socket.io-client";
import { BiCheckCircle } from "react-icons/bi";
import { useGlobal } from "../../hooks/global";
import { TbPlugConnected } from "react-icons/tb";
import { inactiveItens, secondary } from "../../theme";
import { HeaderList } from "../../components/HeaderList";
import { ModalWppConection } from "./ModalWppConection";
import { DashboardPage } from "../../components/DashboardPage/index";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { IntegrationsTypes, IIntegrationsProps } from "../../interfaces";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { ContainerIntegrationLogo, ContainerIntegrations, IntegrationContainer, IntegrationDescription, SwitchContainer } from "./style";
import staysIcon from "./../../assets/stays.svg";
import magikeyIcon from "./../../assets/magikey.svg";

interface IIntegrationsAvailblesProps {
    name: IntegrationsTypes;
    title: string;
    description: string;
    icon: { dark: any; light: any; }
}

const correct_names: any = {
    wpp: { name: "Whatsapp", predicate: "o" },
}

const integrationsAvailbles: IIntegrationsAvailblesProps[] = [
    {
        name: "wpp",
        title: "Whatsapp",
        description: "Integração com o Whatsapp para envio de mensagens",
        icon: {
            dark: <FaWhatsapp size={30} color={"green"} />,
            light: <FaWhatsapp size={30} color={"green"} />
        }
    },
    {
        name: "stays",
        title: "Stays",
        description: "Integração com a Stays",
        icon: {
            dark: <img src={staysIcon} alt="Stays" style={{ width: 30, objectFit: "contain" }} />,
            light: <img src={staysIcon} alt="Stays" style={{ width: 30, objectFit: "contain" }} />
        }
    },
    {
        name: "magikey",
        title: "MagiKey",
        description: "Integração com a MagiKey",
        icon: {
            dark: <img src={magikeyIcon} alt="Stays" style={{ width: 30, objectFit: "contain" }} />,
            light: <img src={magikeyIcon} alt="Stays" style={{ width: 30, objectFit: "contain" }} />
        }
    }
]

export const IntegrationsList: React.FC = () => {
    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loadingEdit, setLoadingEdit] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(true)
    const [editIntegration, setEditIntegration] = useState<IntegrationsTypes | null>(null)
    const [integrationsTokens, setIntegrationsTokens] = useState<IIntegrationsProps>({ wpp: {} } as IIntegrationsProps)
    const [socket, setSocket] = useState<Socket>();

    const handleChangeStatus = useCallback(async (type: 'wpp') => {
        const result = window.confirm(`Tem certeza que deseja ${validate(integrationsTokens[type]) ? "desconectar" : "conectar"} a integração com ${correct_names[type].predicate} ${correct_names[type].name}?`)
        if (result) await handleConfirm(type)
    }, [integrationsTokens])

    const handleConfirm = useCallback(async (type: IntegrationsTypes) => {

        setLoadingEdit(true)

        try {
            await api.delete("/integrations")

            notify(`Integração encerrada com ${correct_names[type].predicate} ${correct_names[type].name}`, "success")

            searchIntegrationsTokens()

        } catch (error) {

            notify(`Erro ao encerrar a integração com ${correct_names[type].predicate} ${correct_names[type].name}`, "error")

        }

        setLoadingEdit(false)

    }, [integrationsTokens])

    const searchIntegrationsTokens = useCallback(async () => {
        setEditIntegration(null)

        setLoadingSearch(true)

        try {
            const { data } = await api.get(`/integrations`)

            setIntegrationsTokens(data)
        } catch (err) {
            notify("Erro ao buscar as integrações", "error")
        }

        setLoadingSearch(false)

    }, [])

    const handleConectIntegration = useCallback(async (type: IntegrationsTypes) => {
        setEditIntegration(type)
    }, [])

    const handleSaveTokens = useCallback(async () => {
        setEditIntegration(null)
        searchIntegrationsTokens()
    }, [])

    const validate = useCallback((data: IIntegrationsProps['wpp']) => {
        const { instance, status } = data ?? { instance: null, status: null }
        return !!instance && status === 'open'
    }, [])

    useEffect(() => { searchIntegrationsTokens() }, [])

    useEffect(() => {
        const socketInstance = io(`${process.env.REACT_APP_API}`);

        setSocket((atual) => {
            atual?.removeAllListeners();
            atual?.disconnect();
            return socketInstance;
        });

        if (socketInstance) {
            socketInstance?.on('wpp-events', async (data) => {
                if (data.status === 'connected') notify('Whatsapp conectado', 'success')
                else notify('Conexão com o Whatsapp perdida', 'alert')
                await searchIntegrationsTokens()
            });
        }
    }, []);

    return (
        <DashboardPage>
            <Column style={{ gap: 20, flex: 1 }}>
                <HeaderList
                    textHeader="Integrações"
                    textDescritpion="Integrações disponíveis para conectar"
                    theme={theme}
                    haveBorder
                    loading={loadingSearch}
                    onRefresh={searchIntegrationsTokens}
                />
                <ContentOrLoading loading={loadingSearch} text="Buscando integrações" theme={theme}>

                    <Line style={{ gap: 20, flexWrap: "wrap", justifyContent: "center" }}>
                        {integrationsAvailbles.map((integration) => (
                            <ContainerIntegrations key={integration.name}>
                                <IntegrationContainer theme={theme}>
                                    <Column>
                                        <div style={{ minHeight: 19 }}>
                                            {integration.name === 'wpp' && (
                                                <SwitchContainer open={integration.name === 'wpp' ? validate(integrationsTokens?.[integration.name] as any) : !!integrationsTokens?.[integration.name]}>
                                                    <Switch
                                                        id="tooltip_switch"
                                                        className="custom-switch custom-switch-primary custom-switch-small"
                                                        checked={integration.name === 'wpp' ? validate(integrationsTokens?.[integration.name] as any) : !!integrationsTokens?.[integration.name]}
                                                        onChange={integration.name === 'wpp' ? () => handleChangeStatus(integration.name as 'wpp') : () => { }}
                                                    />
                                                </SwitchContainer>
                                            )}
                                        </div>
                                        <ContainerIntegrationLogo>
                                            {integration.icon[theme]}
                                            {integration.title}
                                        </ContainerIntegrationLogo>
                                    </Column>

                                    <IntegrationDescription>
                                        {integration.description}
                                    </IntegrationDescription>

                                    <Line style={{ justifyContent: "center" }}>
                                        {!(integration.name === 'wpp' ? validate(integrationsTokens?.[integration.name] as any) : !!integrationsTokens?.[integration.name]) && (
                                            <div>
                                                <Button
                                                    onClick={() => handleConectIntegration(integration.name)}
                                                    style={{ gap: 10, alignItems: "center", padding: "10px 16px", minHeight: 41.5, minWidth: 150 }}
                                                    background={inactiveItens[theme]}
                                                    color="#FFF"
                                                    children={(
                                                        <>
                                                            <TbPlugConnected size={20} color="#FFF" />
                                                            Integrar
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        )}

                                        {(integration.name === 'wpp' ? validate(integrationsTokens?.[integration.name] as any) : !!integrationsTokens?.[integration.name]) && (
                                            <div>
                                                <Button
                                                    onClick={() => { }}
                                                    style={{ gap: 10, cursor: "default", alignItems: "center", padding: "10px 16px", minHeight: 41.5, minWidth: 150 }}
                                                    background={secondary[theme]}
                                                    color="#FFF"
                                                    children={(
                                                        <>
                                                            <BiCheckCircle size={16} className="connect" />
                                                            Conectado
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </Line>
                                </IntegrationContainer>
                            </ContainerIntegrations>
                        ))}
                    </Line>

                </ContentOrLoading>
            </Column>

            <ModalLoading
                loading={loadingEdit}
                theme={theme}
            />

            {editIntegration === "wpp" && (
                <ModalWppConection
                    onCancel={handleSaveTokens}
                    onSave={handleSaveTokens}
                />
            )}
        </DashboardPage >
    )
}