import styled from "styled-components";
import { divider, secondary } from "../../theme";
import { Link as BaseLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  font-size: 13px;
  background: #fff;
  z-index: 10;
  @media (min-width: 1000px) {
    max-width: 600px;
  }
`;

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px 30px 30px;
  gap: 10px;
  border-bottom: 1px solid ${divider.light};
`;

export const ContainerImage = styled.div`
  background-image: url(/assets/bg-6.png);
  background-size: cover;
  background-position: left;
  flex: 1;
  width: calc(100vw - 600px);
  height: calc(100 * var(--vh));
  position: fixed;
  left: 0;
`;

export const ContainerBottomButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-top: 1px solid ${divider.light};
`;

export const Form = styled.form`
  flex: 1;
  padding: 15px 30px 30px 30px;
  overflow: auto;

  @media (min-width: 756px) {
    max-height: calc(calc(100 * var(--vh)) - 320px);
  }
  @media (min-width: 1000px) {
    max-height: calc(calc(100 * var(--vh)) - 339px);
  }

  @media (max-width: 854px) {
    max-height: calc(calc(100 * var(--vh)) - 339px);
  }
  @media (max-width: 756px) {
    max-height: calc(calc(100 * var(--vh)) - 339px);
  }
  @media (max-width: 596px) {
    max-height: calc(calc(100 * var(--vh)) - 369px);
  }
  @media (max-width: 545px) {
    max-height: calc(calc(100 * var(--vh)) - 370px);
  }
  @media (max-width: 481px) {
    max-height: calc(calc(100 * var(--vh)) - 389px);
  }
  @media (max-width: 434px) {
    max-height: calc(calc(100 * var(--vh)) - 408px);
  }
  @media (max-width: 348px) {
    max-height: calc(calc(100 * var(--vh)) - 438px);
  }
`;

export const Link = styled(BaseLink)`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: #212529;
  font-weight: 600;
`;

export const ContainerLink = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Description = styled.div`
  font-size: 13px;
`;

export const LogoImg = styled.img`
  object-fit: contain;
`;

export const ContainerTerms = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const AnchorTerms = styled.a`
  text-decoration: none;
  color: #212529;
`;

export const Span = styled.span`
  color: ${secondary.light};
`;
