import Input from "../../Inputs/Input";
import InputWithLogo from "../../Inputs/InputWithLogo";
import Button, { ButtonRefProps } from "../../Buttons/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useApi } from "../../../hooks/api";
import { RangePicker } from "../../RangePicker";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { maskFunctions } from "../../../services/maskServices";
import { ContainerCustomSelect, ContainerSelect } from "./style";
import { iconComponents, logos } from "../../../utils/iconsFilters";
import { copyOf, valueIsEmpty } from "../../../services/generalServices";
import { FixRequiredSelect } from "../../Inputs/BaseSelects/FixRequiredSelect";
import { SelectNoCreateSingle } from "../../Inputs/BaseSelects/SelectNoCreateSingle";
import {
  background,
  inactiveItens,
  primary,
  secondary,
  shadowColor,
} from "../../../theme";
import {
  cityProps,
  IFilters,
  MaskTypes,
  IOptionsProps,
  stateProps,
} from "../../../interfaces";
import { Label } from "../../../style";

const unMask = (value: string, maskType: MaskTypes) => {
  if (!value) return value;
  else {
    if (
      ["cep", "cpf", "cnpj", "cpfOrCnpj", "phone", "rg", "pis"].includes(
        maskType
      )
    )
      return value.replace(/\D/g, "");
    else if (["porcen", "currency"].includes(maskType))
      return Number.parseInt(value.replace(/\D/g, "")) / 100;
    return value;
  }
};

interface IModalFiltersProps {
  open: boolean;
  handleClose: () => void;
  initialFilters: IFilters[];
  disabled?: boolean;
  handleApply: (filters: IFilters[]) => void;
}

export const ModalFilters: React.FC<IModalFiltersProps> = (props) => {
  const { open, initialFilters, disabled, handleClose, handleApply } = props;

  const { theme, notify } = useGlobal();
  const { consultsServices, api, user } = useApi();

  const [filters, setFilters] = useState<IFilters[]>(initialFilters);
  const [initial_render, set_initial_render] = useState<boolean>(true);
  const [form_control_render, setFormControlRender] = useState<boolean>(false);

  const button_submit_ref = useRef<ButtonRefProps>(null);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      let filters_with_value = filters.map((filter) => {
        if (filter.type === "input") {
          const input = document.getElementById(filter.key) as HTMLInputElement;
          const correct_value = unMask(input.value, filter.mask as MaskTypes);
          return { ...filter, value: correct_value };
        } else return filter;
      });
      setFilters([...filters_with_value]);
      handleApply(filters_with_value);
    },
    [filters]
  );

  const clearFilters = useCallback(() => {
    setFilters((atual) => {
      let newFilters = copyOf(atual) as IFilters[];
      newFilters = newFilters.map((filter) => {
        if (!filter.isNotClearable) return { ...filter, value: undefined };
        else return filter;
      });
      return [...newFilters];
    });
    setFormControlRender((atual: any) => !atual);
  }, []);

  const alterFilterAttribute = useCallback(
    (key: string, attrs: any[], values: any[]) => {
      setFilters((atualFilters) => {
        let newFilters = copyOf(atualFilters) as IFilters[];

        newFilters = newFilters.map((filter) => {
          if (filter.key === key)
            attrs.forEach((attr, index) => (filter[attr] = values[index]));
          return filter;
        });

        return [...newFilters];
      });
    },
    []
  );

  const onChangeValue = useCallback(async (key: string, newValue: any) => {
    setFilters((atual) => {
      let newFilters = copyOf(atual) as IFilters[];
      if (key === "state_id") {
        const index_city = newFilters.findIndex(
          (filter) => filter.key === "city_id"
        );
        newFilters[index_city].value = undefined;
      }
      newFilters = newFilters.map((filter) => {
        if (filter.type === "input") {
          const input = document.getElementById(filter.key) as HTMLInputElement;
          const correct_value = unMask(input.value, filter.mask as MaskTypes);
          return !valueIsEmpty(correct_value)
            ? { ...filter, value: correct_value }
            : filter;
        } else {
          const correct_value = filter.key === key ? newValue : filter.value;
          return { ...filter, value: correct_value };
        }
      });
      return [...newFilters];
    });
    if (key === "state_id") setFormControlRender((atual: any) => !atual);
    if (key === "state_id" && newValue?.value)
      await searchCities(newValue.value);
  }, []);

  const isDisabled = useCallback(
    (filter: IFilters, filters: IFilters[]) => {
      if (filter.key !== "city_id") return disabled;
      else {
        const filter_state = filters.find((item) => item.key === "state_id");
        return !filter_state?.value;
      }
    },
    [disabled]
  );

  const searchState = useCallback(async () => {
    alterFilterAttribute("state_id", ["options", "loading"], [[], true]);

    const newOptionsStates: IOptionsProps[] = [];
    try {
      const result = await api.get("/states");
      const states: stateProps[] = result.data;

      states.forEach((state) => {
        newOptionsStates.push({
          label: state.name,
          value: state.id,
        });
      });
    } catch (err) {
      console.log(err);
      notify("Erro ao Buscar Estados!", "error");
    }

    alterFilterAttribute(
      "state_id",
      ["options", "loading"],
      [newOptionsStates, false]
    );
  }, []);

  const searchCities = useCallback(async (stateId) => {
    alterFilterAttribute(
      "city_id",
      ["options", "loading", "state_id"],
      [[], true, undefined]
    );
    const newOptionCities: IOptionsProps[] = [];

    try {
      const result = await api.get(`/cities/${stateId}`);
      const cities: cityProps[] = result.data;

      cities.forEach((city) => {
        newOptionCities.push({
          label: city.name,
          value: city.id,
        });
      }, []);
    } catch (err) {
      notify("Erro ao Buscar Cidades!", "error");
    }

    alterFilterAttribute(
      "city_id",
      ["options", "loading", "state_id"],
      [newOptionCities, false, stateId]
    );
  }, []);

  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++)
      inputs[i].setAttribute("autocomplete", "nope");
  }, []);

  useEffect(() => {
    if (initial_render) {
      if (filters.find((item) => item.key === "state_id")) searchState();
      set_initial_render(false);
    }
  }, [initial_render]);

  const children = (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: 20,
          flexDirection: "column",
          overflow: "auto",
          gap: 20,
          maxHeight: "calc(calc(100 * var(--vh)) - 162px)",
          minHeight: "calc(calc(100 * var(--vh)) - 162px)",
        }}
      >
        {filters.map((filter) => {
          const { moreQueries = {}, searchOptionsKey = "default_id" } = filter;

          return (
            <>
              {filter.type === "input" && (
                <div
                  key={filter.key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                >
                  <Label>{filter.name}</Label>
                  {!filter.noIcon && (
                    <InputWithLogo
                      id={filter.key}
                      mask={filter.mask}
                      defaultValue={
                        !valueIsEmpty(filter?.value)
                          ? maskFunctions[filter?.mask ?? "none"].mask(
                              filter?.value
                            )
                          : ""
                      }
                      disabled={disabled}
                      icon={logos[filter.key]}
                      iconComponent={iconComponents[filter.key]}
                    />
                  )}
                  {filter.noIcon && (
                    <Input
                      id={filter.key}
                      mask={filter.mask}
                      defaultValue={
                        !valueIsEmpty(filter?.value)
                          ? maskFunctions[filter?.mask ?? "none"].mask(
                              filter?.value
                            )
                          : ""
                      }
                      disabled={disabled}
                    />
                  )}
                </div>
              )}

              {filter.type === "select" && (
                <ContainerCustomSelect key={filter.key}>
                  <Label>{filter.name}</Label>
                  <div style={{ display: "flex", position: "relative" }}>
                    {!filter.noIcon && (
                      <div
                        style={{
                          position: "absolute",
                          left: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {iconComponents[filter.key] && (
                          <div
                            style={{
                              color: inactiveItens["dark"],
                              fontSize: 19,
                            }}
                          >
                            {iconComponents[filter.key]}
                          </div>
                        )}
                        {!iconComponents[filter.key] && (
                          <i
                            className={logos[filter.key]}
                            data-fullscreen
                            style={{
                              color: inactiveItens["dark"],
                              fontSize: 19,
                            }}
                          />
                        )}
                      </div>
                    )}
                    <ContainerSelect>
                      <FixRequiredSelect
                        id={"select" + filter.key}
                        isDisabled={isDisabled(filter, filters) || disabled}
                        value={filter.value}
                        onChange={(e: any) => onChangeValue(filter.key, e)}
                        hasIcon={!filter.noIcon}
                        isLoading={filter.loading}
                        noOptionsMessage={() => {
                          return "Nenhuma opção";
                        }}
                        loadingMessage={() => {
                          return "Buscando...";
                        }}
                        isClearable={!filter.isNotClearable}
                        placeholder=""
                        defaultValue={filter.defaultValue}
                        options={filter.options as IOptionsProps[]}
                      />
                    </ContainerSelect>
                  </div>
                </ContainerCustomSelect>
              )}
              {filter.type === "select_search" && (
                <ContainerCustomSelect key={filter.key}>
                  <Label>{filter.name}</Label>
                  <div style={{ display: "flex", position: "relative" }}>
                    {!filter.noIcon && (
                      <div
                        style={{
                          position: "absolute",
                          left: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {iconComponents[filter.key] && (
                          <div
                            style={{
                              color: inactiveItens["dark"],
                              fontSize: 19,
                            }}
                          >
                            {iconComponents[filter.key]}
                          </div>
                        )}
                        {!iconComponents[filter.key] && (
                          <i
                            className={logos[filter.key]}
                            data-fullscreen
                            style={{
                              color: inactiveItens["dark"],
                              fontSize: 19,
                            }}
                          />
                        )}
                      </div>
                    )}
                    <ContainerSelect>
                      <SelectNoCreateSingle
                        isDisabled={disabled}
                        noOptionsMessage={"Nenhuma opção"}
                        searchOptions={(search: any) =>
                          consultsServices[searchOptionsKey]({
                            search,
                            ...moreQueries,
                          })
                        }
                        hasIcon={!filter.noIcon}
                        defaultValue={filter.value}
                        onChange={(e: any) => onChangeValue(filter.key, e)}
                        isClearable
                      />
                    </ContainerSelect>
                  </div>
                </ContainerCustomSelect>
              )}
              {filter.type === "range_picker" && (
                <div
                  key={filter.key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                >
                  <Label>{filter.name}</Label>
                  <div style={{ height: 38 }}>
                    <RangePicker
                      onChange={(e: any) => onChangeValue(filter.key, e)}
                      initialRange={filter.value}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          gap: 16,
          alignItems: "flex-end",
          background: primary[theme],
          boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[theme]}`,
        }}
      >
        <Button
          onClick={clearFilters}
          disabled={disabled}
          background={primary[theme]}
          color={inactiveItens[theme]}
          style={{
            height: 40,
            margin: 0,
            boxShadow: "0.125rem 0.125rem 0.5rem #57545419",
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
            fontSize: 16,
          }}
          children="Limpar"
        />
        <Button
          type="submit"
          ref={button_submit_ref}
          disabled={disabled}
          background={secondary[theme]}
          color="#FFF"
          style={{
            height: 40,
            margin: 0,
            boxShadow: "0.125rem 0.125rem 0.5rem #57545419",
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
            fontSize: 16,
          }}
          children="Aplicar"
        />
      </div>
    </form>
  );

  return (
    <ModalGeneric onClose={handleClose} open={open}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          background: background[theme],
          color: inactiveItens[theme],
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            padding: 20,
            justifyContent: "center",
            boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[theme]}`,
            minHeight: 82,
            background: primary[theme],
          }}
        >
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              left: 10,
              cursor: "pointer",
              padding: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <IoReturnUpBackOutline
              color={theme === "dark" ? "#FFF" : inactiveItens.light}
              size={20}
            />
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              color: theme === "dark" ? "#FFF" : inactiveItens.light,
            }}
          >
            Filtros
          </div>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          {!form_control_render && children}
          {form_control_render && children}
        </div>
      </div>
    </ModalGeneric>
  );
};
