import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormCheckIn } from "./form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { documentTypesOptions } from "../../../utils/options";
import { maskFunctions } from "../../../services/maskServices";
import { correctDate2 } from "../../../services/generalServices";

interface IModalCheckInProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Check-in",
    edit: "Informações do Check-in",
  },
  subTitle: {
    new: "Informe os dados do check-in",
    edit: "Segue abaixo as informações do check-in",
  },
};

export const ModalCheckIn: React.FC<IModalCheckInProps> = (props) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);

  const searchCheckIn = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const { data } = await api.get(`/check-ins/${defaultId}`);
        const _data = {} as any;
        _data.id = data.id;
        _data.reservation_stays_id = data.reservation_stays_id;
        for (const guest of data.guests) {
          const documentType = guest.document_type;
          const uniqueId = guest.id;
          _data["name-" + uniqueId] = guest.name;
          _data["email-" + uniqueId] = guest.email;
          _data["phone-" + uniqueId] = maskFunctions.phone.mask(guest.phone);
          _data["birth-" + uniqueId] = correctDate2(guest.birth);
          _data["document_type-" + uniqueId] = documentTypesOptions.find(
            (item) => item.value === documentType
          );
          if (documentType === "cpf") {
            _data["document_value_cpf-" + uniqueId] = maskFunctions.cpf.mask(
              guest.document_value
            );
          }
          _data["document_value_others-" + uniqueId] = guest.document_value;
          _data["document-front-" + uniqueId] = guest.document_image_front;
          _data["document-back-" + uniqueId] = guest.document_image_back;
          _data["document-selfie-" + uniqueId] = guest.selfie_image;

        }
        _data.guests = data.guests;
        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar a check-in", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  useEffect(() => {
    searchCheckIn();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormCheckIn
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              defaultGuests={defaultData?.guests?.map((guest: any) => ({
                ...guest,
                uniqueId: guest.id,
              }))}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
