import styled from "styled-components";
import { IThemeProps, themeTypes } from "../../interfaces";
import { divider } from "../../theme";

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerForm = styled.div<{ open: boolean }>`
  flex-direction: column;
  flex: 1;
  margin-top: -20px;
  display: ${(props) => (props.open ? "flex" : "none")};
`;

export const ContainerDocuments = styled.div<{ open: boolean }>`
  flex-direction: column;
  flex: 1;
  margin: -16px;
  margin-top: -16px;
  display: ${(props) => (props.open ? "flex" : "none")};
`;

export const ContainerButtons = styled.div<IThemeProps>`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  gap: 20px;
  border-top: 1px solid ${(props) => divider[props.theme as themeTypes]};
`;
