import React, { useCallback, useState, useEffect } from "react"
import { RiMedalFill } from "react-icons/ri";
import { IoWarningOutline } from "react-icons/io5";
import { IconButton, Tooltip } from "@material-ui/core";
import { BsArrowDownSquare } from "react-icons/bs";
import { ContainerArrow, ContainerPagination, ContainerRanking, Content, TableLine, TD, TH } from "./style";
import { ContentOrLoading } from "../../../components/Loadings/ContentOrLoading";
import { fail, inactiveItens } from "../../../theme";
import { DashboardCard } from "../DashboardCard";
import { correct_date_filter, setUpConsultation, valueIsEmpty } from "../../../services/generalServices";
import { IFranchisesInRankingProps, themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { useApi } from "../../../hooks/api";
import PaginationCustom from "../../../components/PaginationCustom";
import { returnNumberWithDot, returnNumberWithDotInt } from "../utils";

interface IRankingClientsProps {
    minWidth: number
    theme: themeTypes
    maxWidth: string
    range: { from: any, to: any }
}

interface ISearchProps {
    _page: number
    _order: "ASC" | "DESC"
    _orderBy: string
}

const franchiseValue = (item: IFranchisesInRankingProps, attribute: string) => {
    const value = item[attribute]

    const values_attributes = ["value_expended"]

    const string_attributes = ["email"]

    if (string_attributes.includes(attribute)) return value

    if (values_attributes.includes(attribute)) return 'R$ ' + returnNumberWithDot(value)

    if (valueIsEmpty(value)) return "Não informado"

    return returnNumberWithDotInt(value)
}

const colors_podium: any = {
    0: "#FFD800",
    1: "#CBCCCC",
    2: "#BD7B65"
}

const columns = [
    { name: "Email", attribute: "email", className: "text-center pointer j-center", type: 'string' },
    { name: "Valor gasto", attribute: "value_expended", className: "text-center pointer j-center", type: 'currency' },
    { name: "Pontos disponíveis", attribute: "points_availables", className: "text-center pointer j-center", type: 'number' },
    { name: "Pontos pendentes", attribute: "points_pending", className: "text-center pointer j-center", type: 'number' },
    { name: "Pontos para expirar", attribute: "points_to_expire", className: "text-center pointer j-center", type: 'number' },
    { name: "Pontos expirados", attribute: "points_expired", className: "text-center pointer j-center", type: 'number' },
    { name: "Pontos utilizados", attribute: "points_used", className: "text-center pointer j-center", type: 'number' },
    { name: "Reservas pendentes", attribute: "reservations_pending", className: "text-center pointer j-center", type: 'number' },
    { name: "Reservas canceladas", attribute: "reservations_canceled", className: "text-center pointer j-center", type: 'number' },
    { name: "Reservas pagas", attribute: "reservations_paid", className: "text-center pointer j-center", type: 'number' },
] as { name: string, attribute: string, className: string; noHaveClick?: boolean; type: 'string' | 'number' | 'currency' }[]

const getEmail: React.FC<{ email: string; active: boolean }> = ({ active, email }) => {
    return (
        <div style={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center", textTransform: "uppercase" }}>
            <div style={{ whiteSpace: "nowrap", position: "relative" }}>
                {!active && (
                    <Tooltip placement="top" title={"Cliente Bloqueado"} style={{ width: 30, height: 30, position: "absolute", left: -36, top: "50%", transform: "translateY(-50%)" }}>
                        <IconButton aria-label={"Cliente Bloqueado"}>
                            <div style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <IoWarningOutline
                                    color={fail}
                                    size={16}
                                />
                            </div>
                        </IconButton>
                    </Tooltip>
                )}
                {email}
            </div>
        </div>
    )
}

export const RankingClients: React.FC<IRankingClientsProps> = (props) => {
    const { minWidth, theme, maxWidth, range } = props

    const default_order = "DESC"
    const default_orderBy = columns[1].attribute

    const [atualPage, setAtualPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>(default_orderBy)
    const [order, setOrder] = useState<"ASC" | "DESC">(default_order)
    const [controlPagination, setControlPagination] = useState<boolean>(false)
    const [clientsRanking, setClientsRanking] = useState<any[]>([])

    const { api } = useApi()
    const { notify, paginationLimit } = useGlobal()

    const search = useCallback(async (props: ISearchProps) => {
        const { _page, _order, _orderBy } = props
        setAtualPage(_page)
        setLoading(true);
        try {
            const query: any = {}
            const date_init = range.from ? range.from : (range.to ? range.to : null)
            const date_end = range.to ? range.to : (range.from ? range.from : null)
            query.start_date = date_init ? correct_date_filter(date_init) : null
            query.end_date = date_end ? correct_date_filter(date_end) : null
            query.page = _page
            query.limit = paginationLimit
            query.order_type = _order
            query.order_by = _orderBy

            const string: any = setUpConsultation(query)
            const { data } = await api.get(`/users-details?${string}`)
            for (let i = 0; i < data.rows.length; i++) {
                data.rows[i].email = getEmail(data.rows[i])
            }
            setClientsRanking(data.rows)
            setNumberOfPages(Math.ceil((data.count / paginationLimit)))
        } catch (err) {
            console.log(err);
            notify("Erro ao buscar ranking de clientes", "error")
        }
        setLoading(false);
    }, [paginationLimit, range])

    useEffect(() => { setControlPagination(atual => !atual) }, [atualPage])

    useEffect(() => { search({ _page: 1, _order: default_order, _orderBy: default_orderBy }) }, [range])

    const childrenPaginationCustom = (
        <ContainerPagination theme={theme}>
            <PaginationCustom
                disable={loading}
                onChange={(newPage: any) => search({ _page: newPage, _order: order, _orderBy: orderBy })}
                page={atualPage}
                pages={numberOfPages}
            />
        </ContainerPagination>
    )

    const handleOrder = useCallback((attribute: string) => {
        let newOrder: any = undefined

        setOrderBy((atualOrderBy) => {
            if (atualOrderBy === attribute) {
                setOrder((atualOrder) => {
                    newOrder = atualOrder === "ASC" ? "DESC" : "ASC"
                    return newOrder
                })
            }
            else {
                setOrder((atualOrder) => {
                    newOrder = atualOrder
                    return newOrder
                })
            }
            return attribute
        })

        search({ _page: 1, _order: newOrder, _orderBy: attribute })
    }, [])

    return (
        <DashboardCard minWidth={minWidth} title={"Ranking Clientes"} theme={theme}>
            <ContainerRanking theme={theme}>
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <Content maxWidth={maxWidth} theme={theme}>
                        <table style={{ width: "100%", borderSpacing: "0 0px" }}>
                            <thead>
                                <tr>
                                    <TH className="text-center"></TH>
                                    {columns.map(({ attribute, className, name, noHaveClick }) => (
                                        <TH key={attribute} className={className} onClick={() => !noHaveClick ? handleOrder(attribute) : {}}>
                                            <div className={className} style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ position: "relative" }}>
                                                    {orderBy === attribute && (
                                                        <ContainerArrow invert={order === "DESC"}>
                                                            <BsArrowDownSquare
                                                                size={16}
                                                                color={inactiveItens[theme]}
                                                            />
                                                        </ContainerArrow>
                                                    )}
                                                    {name}
                                                </div>
                                            </div>
                                        </TH>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {clientsRanking.map((item, pos) => {
                                    const correctPos = (atualPage - 1) * paginationLimit + pos
                                    return (
                                        <TableLine key={item.name} isPair={correctPos % 2 === 0} isPodium={correctPos <= 2} theme={theme}>
                                            <TD className="text-center">
                                                <div>
                                                    {(correctPos <= 2) ? <RiMedalFill color={colors_podium[correctPos]} size={20} /> : `${correctPos + 1}º`}
                                                </div>
                                            </TD>
                                            {columns.map(({ attribute, className, type }) => (
                                                <TD key={attribute} className={className + (valueIsEmpty(item[attribute]) ? " empty" : "")}>
                                                    {franchiseValue(item, attribute)}
                                                </TD>
                                            ))}
                                        </TableLine>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Content>
                    {controlPagination && childrenPaginationCustom}
                    {!controlPagination && childrenPaginationCustom}
                </ContentOrLoading>
            </ContainerRanking>
        </DashboardCard>
    )
}