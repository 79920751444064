import React, { useEffect, useRef } from "react";
import { secondary } from "../../../theme";
import { WaveLoading } from "../WaveLoading";
import { themeTypes } from "../../../interfaces";
import { CircularProgress } from "@material-ui/core";

interface ILoadingSearch {
  loading: boolean;
  theme: themeTypes;
  text?: string;
  type?: "wave" | "circular";
}

export const ContentOrLoading: React.FC<ILoadingSearch> = (props) => {
  const { loading, theme, text = "", type = "circular", children } = props;

  const content_ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    content_ref.current?.click();
  }, [loading]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: 16,
          }}
        >
          {type === "circular" && (
            <>
              <h6 style={{ textAlign: "center" }}>{text}</h6>
              <CircularProgress
                variant="indeterminate"
                style={{ color: secondary[theme] }}
              />
            </>
          )}
          {type === "wave" && (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                minHeight: 150,
              }}
            >
              <WaveLoading />
            </div>
          )}
        </div>
      )}
      {!loading && children}
    </>
  );
};
