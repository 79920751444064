import './style.css';

interface IEmailSimulatorProps {
    text: string;
}

export const EmailSimulator: React.FC<IEmailSimulatorProps> = ({ text }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ userSelect: 'none', background: "#FFF", borderRadius: 8, padding: 10, height: "100%", minHeight: 500, maxWidth: 'calc(100vw - 32px)', overflow: "auto" }}
        />
    )
}