import { fail, info, success } from "../theme";

export const reservation_status: any = {
  booked: { text: "Reservado", color: success },
  reserved: { text: "Pré-reserva", color: info },
  contract: { text: "Contrato", color: info },
  blocked: { text: "Bloqueado", color: fail },
  maintenance: { text: "Manutenção", color: info },
  canceled: { text: "Cancelado", color: fail },
};

export const payment_status: any = {
  paid: { text: "Pago", color: success },
  pending: { text: "Pendente", color: info },
  refused: { text: "Recusado", color: fail },
  refunded: { text: "Devolvido", color: fail },
  canceled: { text: "Cancelado", color: fail },
};

export const accommodation_status: any = {
  active: { text: "Ativo", color: success },
  hidden: { text: "Ativo porém oculto", color: info },
  inactive: { text: "Inativo", color: fail },
  draft: { text: "Rascunho", color: fail },
};

export const notifications_status: any = {
  sent: { text: "Enviado", color: success },
  pending: { text: "Pendente", color: info },
  failed: { text: "Falhou", color: fail },
  time_exceeded: { text: "Tempo máximo para envio excedido", color: fail },
}