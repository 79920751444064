import Countdown from "react-countdown";
import React, { useState, useCallback, useEffect } from "react";
import { Line } from "../../../style";
import { FiClock } from "react-icons/fi";
import { useApi } from "../../../hooks/api";
import { Container, Content } from "./style";
import { useGlobal } from "../../../hooks/global";
import { ModalHeader } from "../../../components/ModalHeader";
import { ModalGeneric } from "../../../components/ModalGeneric";
import { ContentOrLoading } from "../../../components/Loadings/ContentOrLoading";

interface IModalSuperlogicaProps {
    onCancel: Function
    onSave: Function
}

const msToTime = (duration: number) => {
    let seconds: any = parseInt(((duration / 1000) % 60) + "", 10)
    let minutes: any = parseInt(((duration / (1000 * 60)) % 60) + "", 10)
    let hours: any = parseInt(((duration / (1000 * 60 * 60)) % 24) + "", 10)

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + "h" + " : " + minutes + "m" + " : " + seconds + "s";
}

export const ModalWppConection: React.FC<IModalSuperlogicaProps> = (props) => {
    const { onCancel, onSave } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(true)

    const [qrCode, setQrCode] = useState<string | null>(null)

    const getNewInstance = useCallback(async () => {
        setLoading(true)

        try {
            const { data } = await api.post("/integrations")

            setQrCode(data.qrCode)
        } catch (err) {
            notify("Erro ao tentar obter nova instância", "error")
        }

        setLoading(false)
    }, [api])

    const onComplete = useCallback(async () => {
        onSave()
    }, [])

    useEffect(() => { getNewInstance() }, [])

    return (
        <ModalGeneric open onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={"Integração com o WhatsApp"}
                    subTitle={"Aguarde o QR Code aparecer e escaneie com o WhatsApp que deseja integrar"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContentOrLoading loading={loading} theme={theme} type="wave">
                    <Content>
                        <img src={qrCode ?? ''} alt="QR Code" style={{ height: 300, objectFit: 'scale-down' }} />
                    </Content>
                </ContentOrLoading>

                {!loading && (
                    <Line style={{ alignItems: "center", color: "#FAAD14", gap: 5, justifyContent: "center", marginBottom: 16 }}>
                        <FiClock size={15} />
                        <Line style={{ fontSize: 14, gap: 5 }}>
                            Você têm
                            <Countdown
                                date={(new Date()).getTime() + 60 * 1000}
                                intervalDelay={0}
                                precision={3}
                                onComplete={onComplete}
                                renderer={props => <b style={{ fontSize: 14 }}>{msToTime(props.total)}</b>}
                            />
                            para logar
                        </Line>
                    </Line>
                )}
            </Container>
        </ModalGeneric>
    )
}