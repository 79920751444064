import { modules } from "./modules";
import { IOptionsProps } from "../interfaces";

const executors = [
  { id: "QuodScore.Score", name: "Score", type: "PF" },
  { id: "ScorePJ.Score", name: "Score", type: "PJ" },
  {
    id: "Negative.Apontamentos.length",
    name: "Qnt. de Inadimplências comunicadas",
    type: "PF_PJ",
  },
  {
    id: "Negative.TotalValorApontamentos",
    name: "Valor de Inadimplências comunicadas",
    type: "PF_PJ",
    mask: "currencyInt",
  },
  { id: "Negative.TotalProtests", name: "Qnt. de Protestos", type: "PF_PJ" },
  {
    id: "Negative.TotalValorProtests",
    name: "Valor de Protestos",
    type: "PF_PJ",
    mask: "currencyInt",
  },
  {
    id: "Negative.LawSuitApontamentos.length",
    name: "Qnt. de Ações Judiciais",
    type: "PF_PJ",
  },
  {
    id: "Negative.TotalValorLawSuitApontamentos",
    name: "Valor de Ações Judiciais",
    type: "PF_PJ",
    mask: "currencyInt",
  },
  {
    id: "Negative.CcfApontamentos.length",
    name: "Qnt. de Cheques sem fundos",
    type: "PF_PJ",
  },
  {
    id: "Inquiries.InquiryCountLast30Days",
    name: "Qnt. de consultas nos últimos 30 dias",
    type: "PF_PJ",
  },
  { id: "BestInfo.Age", name: "Idade (anos)", type: "PF" },
  { id: "foundation", name: "Fundação (anos)", type: "PJ" },
];

export const documentTypesOptions: IOptionsProps[] = [
  { label: "CPF", value: "cpf" },
  { label: "Passaporte", value: "passport" },
];

export const modulesOptions: IOptionsProps[] = modules.map((module) => {
  return { value: module, label: module.description, this: module };
});

export const executorsOptions: IOptionsProps[] = executors.map((executor) => {
  return { value: executor, label: executor.name, this: executor };
});

export const executorActionsOptions: IOptionsProps[] = [
  { value: "pending", label: "Pendenciar" },
  { value: "reject", label: "Rejeitar" },
];

export const userPersonsTypesOptions: IOptionsProps[] = [
  { value: "broker", label: "Corretor" },
  { value: "analyst", label: "Analista" },
  { value: "manager", label: "Gerente" },
];

export const statusProposalOptions: IOptionsProps[] = [
  { value: "AP", label: "Aguardando preenchimento" },
  { value: "AA", label: "Aguardando análise" },
  { value: "PEA", label: "Proposta em análise" },
  { value: "RP", label: "Resolução de pendências" },
  { value: "PA", label: "Proposta aprovada" },
  { value: "PR", label: "Proposta reprovada" },
  { value: "PC", label: "Proposta cancelada" },
  { value: "CF", label: "Contrato finalizado" },
];

export const personsTypesOptions: IOptionsProps[] = [
  { value: "PF", label: "Pessoa Física" },
  { value: "PJ", label: "Pessoa Jurídica" },
];

export const paymentTypesOptions: IOptionsProps[] = [
  { value: "DC", label: "Depósito em cheque" },
  { value: "DD", label: "Depósito em dinheiro" },
  { value: "RC", label: "Retirar cheque no local" },
  { value: "RD", label: "Retirar dinheiro no local" },
  { value: "TB", label: "Transferência bancária" },
  { value: "DOC/TED", label: "DOC/TED" },
  { value: "PIX", label: "PIX" },
];

export const accountTypesOptions: IOptionsProps[] = [
  { value: "CC", label: "Conta corrente" },
  { value: "CP", label: "Conta poupança" },
];

export const banksOptions: IOptionsProps[] = [
  { value: "001", label: "001 Banco do Brasil" },
  { value: "003", label: "003 Banco da Amazônia" },
  { value: "004", label: "004 Banco do Nordeste" },
  { value: "010", label: "010 Credicoamo" },
  { value: "021", label: "021 Banestes" },
  { value: "027", label: "027 Banco Besc" },
  { value: "033", label: "033 Banco Santander" },
  { value: "037", label: "037 Banpara" },
  { value: "041", label: "041 Banrisul" },
  { value: "047", label: "047 Banese" },
  { value: "048", label: "048 Banco Bamge S.A." },
  { value: "069", label: "069 Banco Crefisa S.A." },
  { value: "070", label: "070 BRB" },
  { value: "077", label: "077 Banco Intermedium S.A." },
  { value: "082", label: "082 Banco Topazio S.A." },
  { value: "084", label: "084 Unicred Uniprime Norte do Paraná" },
  {
    value: "085",
    label: "085 Cooperativa Central de Crédito Urbano-CECRED",
  },
  { value: "087", label: "087 Unicred Central Santa Catarina" },
  { value: "089", label: "089 CREDISAN" },
  { value: "090", label: "090 Unicred Central São Paulo" },
  { value: "091", label: "091 Unicred Central do Rio Grande do Sul" },
  {
    value: "092",
    label: "092 Brickell S.A. Crédito, financiamento e Investimento",
  },
  {
    value: "097",
    label: "097 Cooperativa Central de Crédito Noroeste Brasileiro Ltda",
  },
  {
    value: "098",
    label: "098 Credialiança Cooperativa de Crédito Rural",
  },
  {
    value: "099",
    label:
      "099 Uniprime Central - Central Interestadual de Cooperativas de Crédito Ltda.",
  },
  { value: "102", label: "102 XP Investimentos S.A." },
  { value: "104", label: "104 Caixa Econômica Federal" },
  { value: "112", label: "112 Unicred Brasil Central" },
  {
    value: "114",
    label:
      "114 CECOOPES - Central das Coop. de econ. e cred. mútuo do Estado do ES",
  },
  { value: "121", label: "121 Agibank" },
  { value: "133", label: "133 Cresol" },
  {
    value: "136",
    label: "136 Conf. Nacional das cooperativas centrais Unicreds",
  },
  { value: "140", label: "140 Easynvest - Título Cv S.A." },
  {
    value: "153",
    label: "153 Caixa Econômica Estadual do Rio Grande do Sul",
  },
  { value: "184", label: "184 BCO ITAÚ BBA S.A" },
  { value: "197", label: "197 Stone Pagamentos S.A." },
  { value: "208", label: "208 BTG PACTUAL" },
  { value: "212", label: "212 Banco Original" },
  { value: "218", label: "218 Banco BS2 S.A." },
  { value: "237", label: "237 Bradesco" },
  { value: "243", label: "243 Banco Máxima S.A." },
  { value: "260", label: "260 Nu Pagamentos S.A" },
  { value: "269", label: "269 HSBC Brasil Bancos de Investimentos" },
  {
    value: "279",
    label: "279 Cooperativa de Crédito Rural de Primavera do Leste (CCR)",
  },
  { value: "290", label: "290 PagSeguro Internet S.A" },
  {
    value: "292",
    label: "292 BS2 Distribuidora de Títulos e Valores Mobiliários S.A",
  },
  { value: "301", label: "301 BPP Instituição de Pagamentos S.A." },
  { value: "318", label: "318 Banco BMG S.A." },
  { value: "320", label: "320 Banco Industrial e Comercial S.A." },
  { value: "323", label: "323 Mercadopago.com Representações LTDA" },
  { value: "329", label: "329 QI Sociedade de Crédito Direto S.A." },
  { value: "330", label: "330 BANCO BARI S.A" },
  { value: "335", label: "335 Banco Digio S.A." },
  { value: "336", label: "336 Banco C6 S.A." },
  {
    value: "340",
    label: "340 Super Pagamentos e Administração de Meios Eletrônicos S.A.",
  },
  { value: "341", label: "341 Banco Itaú S.A." },
  { value: "348", label: "348 Banco XP" },
  { value: "352", label: "352 TORO CTVM LTDA" },
  { value: "364", label: "364 Gerencianet pagamentos do Brasil" },
  { value: "380", label: "380 PICPAY SERVICOS S.A" },
  {
    value: "383",
    label: "383 Boletobancário.com Tecnologia de Pagamentos LTDA.",
  },
  { value: "389", label: "389 Banco Mercantil Brasil" },
  { value: "396", label: "396 Hub Pagamentos" },
  { value: "399", label: "399 HSBC" },
  { value: "403", label: "403 CORA Sociedade de Crédito Direto S.A." },
  { value: "413", label: "413 BV S.A." },
  { value: "422", label: "422 Banco Safra" },
  { value: "453", label: "453 Banco Rural" },
  { value: "479", label: "479 Bank Boston" },
  { value: "582", label: "582 Unicred União" },
  { value: "623", label: "623 Banco Panamericano S.A." },
  { value: "633", label: "633 Rendimento" },
  { value: "637", label: "637 Sofisa" },
  { value: "654", label: "654 Banco A.J.Renner S.A." },
  { value: "655", label: "655 Banco Votorantim S.A." },
  { value: "707", label: "707 Banco Daycoval S.A." },
  { value: "735", label: "735 Banco Neon S.A." },
  { value: "741", label: "741 BRP" },
  { value: "745", label: "745 Citibank" },
  { value: "746", label: "746 Banco Modal S.A." },
  { value: "748", label: "748 Sicredi" },
  { value: "749", label: "749 Banco Simples S.A." },
  { value: "752", label: "752 BCO BNP PARIBAS BRASIL SA" },
  { value: "756", label: "756 Bancoob" },
];

export const maritalStatusOptions: IOptionsProps[] = [
  { value: "single", label: "Solteiro(a)" },
  { value: "married", label: "Casado(a)" },
  { value: "separate", label: "Separado(a) judicialmente" },
  { value: "widower", label: "Viúvo(a)" },
  { value: "divorced", label: "Divorciado(a)" },
  { value: "divorced_quit", label: "Desquitado(a)" },
  { value: "stable_union", label: "União estável" },
];

export const destinationsOptions: IOptionsProps[] = [
  { value: "RESI", label: "Residencial" },
  { value: "N_RESI", label: "Não residencial" },
  { value: "COMER", label: "Comercial" },
  { value: "INDUS", label: "Industrial" },
  { value: "TEMP", label: "Temporada" },
  { value: "ENCOM", label: "Por encomenda" },
  { value: "MISTO", label: "Misto" },
];

export const warrantyNameOptions: IOptionsProps[] = [
  { value: "GUARANTOR", label: "Fiador" },
  { value: "SECURITY", label: "Caução" },
  { value: "GUARANTEE_INSURANCE", label: "Seguro-fiança" },
  { value: "CAPITALIZATION_TITLE", label: "Título de capitalização" },
  { value: "FIDUCIARY_ASSIGNMENT", label: "Cessão Fiduciária" },
  { value: "NO_WARRANTY", label: "Sem Garantia" },
];

export const localizationsOptions: IOptionsProps[] = [
  { value: "RURAL", label: "Imóvel rural" },
  { value: "URBAN", label: "Imóvel urbano" },
];

export const ownerRelationsOptions: IOptionsProps[] = [
  { value: "recipient", label: "Beneficiário" },
  { value: "owner", label: "Proprietário" },
  { value: "owner_main", label: "Proprietário principal" },
];

export const consultsOptionsRelations: IOptionsProps[] = [
  { label: "Fiador", value: "guarantor" },
  { label: "Locatário", value: "tenant" },
  { label: "Cônjuge Fiador", value: "guarantor_spouse" },
  { label: "Cônjuge Locatário", value: "tenant_spouse" },
];

export const warrantyOptions: IOptionsProps[] = [
  { value: "GUARANTOR", label: "Fiador" },
  { value: "SECURITY", label: "Caução" },
  { value: "GUARANTEE_INSURANCE", label: "Seguro-fiança" },
  { value: "CAPITALIZATION_TITLE", label: "Título de capitalização" },
  { value: "FIDUCIARY_ASSIGNMENT", label: "Cessão fiduciária" },
  { value: "NO_WARRANTY", label: "Sem Garantia" },
];

export const securityTypeOptions: IOptionsProps[] = [
  { label: "Valor", value: "value" },
  { label: "Veículo", value: "vehicle" },
  { label: "Carta-fiança", value: "letter_of_guarantee" },
  { label: "Imóvel", value: "immobile" },
  { label: "Outros", value: "others" },
];

export const responsibleTypeOptions: IOptionsProps[] = [
  { label: "Imobiliária", value: "real_estate" },
  { label: "Proprietário", value: "owner" },
];

export const readjustmentIndicesOptions: IOptionsProps[] = [
  { value: "INPC", label: "INPC" },
  { value: "IPCA", label: "IPCA" },
  { value: "IGPM", label: "IGPM" },
];

export const signatoryTypesOptions: IOptionsProps[] = [
  { value: "party", label: "Parte" },
  { value: "witness", label: "Testemunha" },
  { value: "intervening", label: "Interveniente" },
  { value: "endorser", label: "Endossante" },
  { value: "endorsee", label: "Endossatário" },
  { value: "administrator", label: "Administrador" },
  { value: "guarantor", label: "Avalista" },
  { value: "transferor", label: "Cedente" },
  { value: "transferee", label: "Cessionário" },
  { value: "contractee", label: "Contratada" },
  { value: "contractor", label: "Contratante" },
  { value: "joint_debtor", label: "Devedor solidário" },
  { value: "issuer", label: "Emitente" },
  { value: "manager", label: "Gestor" },
  { value: "buyer", label: "Parte compradora" },
  { value: "seller", label: "Parte vendedora" },
  { value: "attorney", label: "Procurador" },
  { value: "legal_representative", label: "Representante legal" },
  { value: "co_responsible", label: "Responsável solidário" },
  { value: "validator", label: "Validador" },
  { value: "lessor", label: "Locador" },
  { value: "lessee", label: "Locatário" },
  { value: "surety", label: "Fiador" },
  { value: "employer", label: "Empregador" },
  { value: "employee", label: "Empregado" },
  { value: "consenting", label: "Anuente" },
  { value: "accountant", label: "Contador" },
  { value: "debtor", label: "Devedor" },
  { value: "franchisee", label: "Franqueado" },
  { value: "broker", label: "Corretor" },
  { value: "creditor", label: "Credor" },
  { value: "franchisor", label: "Franqueador" },
  { value: "insured", label: "Segurado" },
  { value: "grantor", label: "Outorgante" },
  { value: "president", label: "Presidente" },
  { value: "partner", label: "Sócio" },
  { value: "comforter", label: "Comodante" },
  { value: "account_holder", label: "Correntista" },
  { value: "distracted", label: "Distratado" },
  { value: "distracting", label: "Distratante" },
  { value: "lender", label: "Mutuante" },
  { value: "associate", label: "Associado" },
  { value: "sign", label: "Assinar" },
  { value: "approve", label: "Assinar para aprovar" },
  { value: "ratify", label: "Assinar para homologar" },
  { value: "receipt", label: "Assinar para acusar recebimento" },
];

export const isManagerOptions = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export const planIconsOptions: IOptionsProps[] = [
  { value: "FaCar", label: "Carro" },
  { value: "FaAirPlane", label: "Avião" },
  { value: "TbHandStop", label: "Mão" },
  { value: "MdOutlinePedalBike", label: "Bicicleta" },
];

export const documentsTypesOptions: IOptionsProps[] = [
  { value: "PF", label: "Pessoa Física" },
  { value: "PJ", label: "Pessoa Jurídica" },
  { value: "property", label: "Imóvel" },
];

export const documentsExtensionsOptions: IOptionsProps[] = [
  { value: "pdf", label: "PDF" },
  { value: "svg", label: "SVG" },
  { value: "jpg", label: "JPG" },
  { value: "jpeg", label: "JPEG" },
  { value: "png", label: "PNG" },
  { value: "doc", label: "DOC" },
  { value: "docx", label: "DOCX" },
  { value: "gif", label: "GIF" },
  { value: "tiff", label: "TIFF" },
];

export const ownersTypesOptions: IOptionsProps[] = [
  { label: "Beneficiário", value: "recipient" },
  { label: "Proprietário", value: "owner" },
  { label: "Proprietario principal", value: "owner_main" },
];

export const documentEletronicBondsOptions: IOptionsProps[] = [
  { value: "proposal", label: "Proposta" },
  { value: "property", label: "Imóvel" },
  { value: "free", label: "Nenhum" },
];

export const documentEletronicTypeOptions: IOptionsProps[] = [
  { value: "send_file", label: "Enviar arquivo" },
  { value: "send_personalized", label: "Enviar modelo personalizado" },
  { value: "select", label: "Selecionar modelo" },
];

export const signatoriesRelationsOptions: IOptionsProps[] = [
  { value: "party", label: "Parte" },
  { value: "witness", label: "Testemunha" },
  { value: "intervening", label: "Interveniente" },
  { value: "endorser", label: "Endossante" },
  { value: "endorsee", label: "Endossatário" },
  { value: "administrator", label: "Administrador" },
  { value: "guarantor", label: "Avalista" },
  { value: "transferor", label: "Cedente" },
  { value: "transferee", label: "Cessionário" },
  { value: "contractee", label: "Contratada" },
  { value: "contractor", label: "Contratante" },
  { value: "joint_debtor", label: "Devedor solidário" },
  { value: "issuer", label: "Emitente" },
  { value: "manager", label: "Gestor" },
  { value: "buyer", label: "Parte compradora" },
  { value: "seller", label: "Parte vendedora" },
  { value: "attorney", label: "Procurador" },
  { value: "legal_representative", label: "Representante legal" },
  { value: "co_responsible", label: "Responsável solidário" },
  { value: "validator", label: "Validador" },
  { value: "lessor", label: "Locador" },
  { value: "lessee", label: "Locatário" },
  { value: "surety", label: "Fiador" },
  { value: "employer", label: "Empregador" },
  { value: "employee", label: "Empregado" },
  { value: "consenting", label: "Anuente" },
  { value: "accountant", label: "Contador" },
  { value: "debtor", label: "Devedor" },
  { value: "franchisee", label: "Franqueado" },
  { value: "broker", label: "Corretor" },
  { value: "creditor", label: "Credor" },
  { value: "franchisor", label: "Franqueador" },
  { value: "insured", label: "Segurado" },
  { value: "grantor", label: "Outorgante" },
  { value: "president", label: "Presidente" },
  { value: "partner", label: "Sócio" },
  { value: "comforter", label: "Comodante" },
  { value: "account_holder", label: "Correntista" },
  { value: "distracted", label: "Distratado" },
  { value: "distracting", label: "Distratante" },
  { value: "lender", label: "Mutuante" },
  { value: "associate", label: "Associado" },
  { value: "sign", label: "Assinar" },
  { value: "approve", label: "Assinar para aprovar" },
  { value: "ratify", label: "Assinar para homologar" },
  { value: "receipt", label: "Assinar para acusar recebimento" },
];

export const accessTypesOptions: IOptionsProps[] = [
  { value: "normal", label: "Fechadura Comum" },
  { value: "on", label: "Fechadura Eletrônica online" },
  { value: "off", label: "Fechadura Eletrônica offline" },
];

export const yesNoOptions: IOptionsProps[] = [
  { value: "true", label: "Sim" },
  { value: "false", label: "Não" },
];

export const reservationStatusOptions: IOptionsProps[] = [
  { value: "blocked", label: "Bloqueado" },
  { value: "canceled", label: "Cancelado" },
  { value: "contract", label: "Contrato" },
  { value: "maintenance", label: "Manutenção" },
  { value: "reserved", label: "Pré-reserva" },
  { value: "booked", label: "Reservado" },
];

export const paymentStatusOptions: IOptionsProps[] = [
  { value: "paid", label: "Pago" },
  { value: "pending", label: "Pendente" },
];

export const get_corrrect_names = (options: IOptionsProps[]) => {
  const corrrect_names: any = {};
  options.map(({ value, label }) => {
    corrrect_names[value] = label;
  });
  return corrrect_names;
};
