import { Line } from './../style';
import { TopCard } from '../TopCard';
import { Skeleton } from "@mui/material";
import { fail, info, secondary, success } from '../../../theme';
import { useGlobal } from '../../../hooks/global';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { BiCheckCircle, BiError, BiXCircle } from 'react-icons/bi';
import { returnNumberWithDot, returnNumberWithDotInt } from '../utils';

export interface IPointsCardsProps {
  loading: boolean;
  exchange_rate: number;
  statistics: {
    points_available: number;
    points_to_enter: number;
    points_to_expire: number;
  }
}

export const PointsCards: React.FC<IPointsCardsProps> = ({ statistics, exchange_rate, loading }) => {
  const { theme } = useGlobal();

  if (loading) return (
    <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 108 }}
      />

      <Skeleton
        variant="rectangular"
        animation="wave"
        style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 108 }}
      />

      <Skeleton
        variant="rectangular"
        animation="wave"
        style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 108 }}
      />

      <Skeleton
        variant="rectangular"
        animation="wave"
        style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 108 }}
      />
    </Line>
  )

  return (
    <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
      <TopCard
        icon={<BiCheckCircle size={40} />}
        title="Pontos disponíveis"
        value={returnNumberWithDotInt(statistics.points_available)}
        containerStyle={{ color: success }}
        tooltip={
          returnNumberWithDotInt(statistics.points_available) +
          " pontos disponíveis para utilizção"
        }
      />
      <TopCard
        icon={<BiXCircle size={40} />}
        title="Pontos a expirar"
        value={returnNumberWithDotInt(statistics.points_to_expire)}
        containerStyle={{ color: fail }}
        tooltip={
          returnNumberWithDotInt(statistics.points_to_expire) + " pontos a expirar"
        }
      />
      <TopCard
        icon={<BiError size={40} />}
        title="Pontos pendentes"
        value={returnNumberWithDotInt(statistics.points_to_enter)}
        containerStyle={{ color: info }}
        tooltip={
          returnNumberWithDotInt(statistics.points_to_enter) +
          " pontos pendentes de entrada"
        }
      />
      <TopCard
        icon={
          <AiOutlineDollarCircle size={40} style={{ opacity: 0.7 }} />
        }
        title="Pontos Disponíveis em Reais"
        value={'R$ ' + returnNumberWithDot((statistics.points_available / exchange_rate))}
        containerStyle={{ background: secondary[theme], color: "#FFF" }}
        titleStyle={{ opacity: 0.7, color: "#FFF" }}
        tooltip={
          "Pontos Disponíveis em Reais - R$ " +
          returnNumberWithDot(
            statistics.points_available / exchange_rate
          )
        }
      />
    </Line>
  );
};
