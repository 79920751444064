import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../interfaces";
import {
  divider,
  shadowColor,
  inactiveItens,
  primary,
  borderColors,
} from "../../../../theme";

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerForm = styled.div<{ open: boolean }>`
  flex-direction: column;
  flex: 1;
  display: ${(props) => (props.open ? "flex" : "none")};
  padding-bottom: 25px;
`;

export const ContainerButtons = styled.div<IThemeProps>`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 20px;
  border-top: 1px solid ${(props) => divider[props.theme as themeTypes]};
`;

export const VariableButton = styled.div<IThemeProps>`
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  ${(props) => css`
    box-shadow: 0.125rem 0.125rem 0.5rem
      ${shadowColor[props.theme as themeTypes]};
    background: ${primary[props.theme as themeTypes]};
  `}
  user-select: none;
`;

export const InputVariable = styled.input<IThemeProps>`
  background: transparent;
  resize: none;
  border: none;
  width: 100%;
  cursor: pointer;
  ${(props) => css`
    color: ${inactiveItens[props.theme as themeTypes]};
  `}
`;

export const ContainerTextArea = styled.div<IThemeProps>`
  ${(props) => css`
    .ql-container.ql-snow .ql-editor::before {
      color: ${inactiveItens[props.theme as themeTypes]};
    }

    .ql-toolbar .ql-formats .ql-picker-label {
      color: ${inactiveItens[props.theme as themeTypes]};
    }

    .ql-container.ql-snow {
      border: 1px solid ${borderColors[props.theme as themeTypes]}; /* Cor e largura da borda da área de edição */
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .ql-toolbar.ql-snow {
      border: 1px solid ${borderColors[props.theme as themeTypes]}; /* Cor e largura da borda da barra de ferramentas */
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-editor {
      background: ${primary[props.theme as themeTypes]};
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .ql-toolbar {
      background: ${primary[props.theme as themeTypes]};
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  `}
`;

export const TextArea = styled.textarea<IThemeProps>`
  ${(props) => css`
    border: 1px solid ${borderColors[props.theme as themeTypes]};
    color: ${inactiveItens[props.theme as themeTypes]};
    background: ${primary[props.theme as themeTypes]};
    height: calc(100vh - 399px);
  `}
  width: 100%;
  min-height: 250px;
  padding: 10px;
  border-radius: 4px;
  font-family: monospace;
  resize: none;
`;
