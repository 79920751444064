import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "../../../../style";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";

interface IFormPersonModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: { id: string; name: string; accommodations: { id: string; name: string } } | null;
  accommodationOptions: { label: string; value: string }[];
}

export const FormAccommodationGroup: React.FC<IFormPersonModalProps> = (props) => {
  const { onSave, defaultData, accommodationOptions } = props;

  const { theme, notify } = useGlobal();
  const { api, consultsServices } = useApi();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : ({} as any),
    mode: "all",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();
      form.accommodation_ids = form.accommodation_ids?.map(({ id }: any) => id);

      if (defaultData?.id) {
        await api.put(`/accommodations-groups/${defaultData?.id}`, form);
      } else {
        await api.post("/accommodations-groups", form);
      }

      notify(
        `Grupo de acomodações ${defaultData?.id ? "atualizado" : "criado"} com sucesso!`,
        "success"
      );

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field) setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, api]);

  const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    handleSubmit(onSubmit)(e);
  }, [handleSubmit, onSubmit, form_ref, _form, api]);

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "name",
            label: "Nome",
            type: "input",
            isClearable: true,
            required: true,
          },
        ],
        [
          {
            name: "accommodation_ids",
            label: "Acomodações",
            type: "select-fixed-multi",
            required: true,
            isClearable: true,
            icon: "🏠",
            options: accommodationOptions,
          }
        ]
      ],
    },
  ];

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", padding: 20 }}>
        <ContainerForm open>
          <Form onSubmit={handleStopPropagation}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
      </Column>

      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
