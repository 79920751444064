import React from "react";
import { Home } from "../pages/Home";
import { UsersList } from "../pages/Users";
import { PrivateRoute } from "./privateRoute";
import { Dashboard } from "../pages/Dashboard";
import { ClientsList } from "../pages/Clients";
import { CheckInsList } from "../pages/CheckIns";
import { SettingsList } from "../pages/Settings";
import { UserTypesList } from "../pages/UserTypes";
import { SmartlocksList } from "../pages/Smartlocks";
import { MessageModels } from "../pages/MessageModels";
import { IntegrationsList } from "../pages/Integrations";
import { ReservationsList } from "../pages/Reservations";
import { GeneralSettings } from "../pages/GeneralSettings";
import { AccommodationsList } from "../pages/Accommodations";
import { NotificationRulesList } from "../pages/NotificationRules";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import { AccommodationTypesList } from "../pages/AccommodationTypes";
import { NotificationsHistory } from "../pages/NotificationsHistory";
import { CompanyData } from "../pages/CompanyData";

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="" element={<PrivateRoute />}>
        <Route index element={<Home />} />
      </Route>

      <Route path="dashboard" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
      </Route>

      <Route path="users" element={<PrivateRoute />}>
        <Route index element={<UsersList />} />
      </Route>

      <Route path="clients-app" element={<PrivateRoute />}>
        <Route index element={<ClientsList />} />
      </Route>

      <Route path="accommodations" element={<PrivateRoute />}>
        <Route index element={<AccommodationsList />} />
      </Route>

      <Route path="smartlocks" element={<PrivateRoute />}>
        <Route index element={<SmartlocksList />} />
      </Route>

      <Route path="reservations" element={<PrivateRoute />}>
        <Route index element={<ReservationsList />} />
      </Route>

      <Route path="check-ins" element={<PrivateRoute />}>
        <Route index element={<CheckInsList />} />
      </Route>

      <Route path="settings" element={<PrivateRoute />}>
        <Route index element={<Navigate to="/" />} />
        <Route path="general" element={<GeneralSettings />} />
        <Route path="users-types" element={<UserTypesList />} />
        <Route path="accommodations-types" element={<AccommodationTypesList />} />
        <Route path="notification-rules" element={<NotificationRulesList />} />
        <Route path="notifications-history" element={<NotificationsHistory />} />
        <Route path="mobile-app" element={<SettingsList />} />
        <Route path="message-models" element={<MessageModels />} />
        <Route path="integrations" element={<IntegrationsList />} />
        <Route path="company-data" element={<CompanyData />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="message-models" element={<PrivateRoute />}>
        <Route index element={<MessageModels />} />
      </Route>

      <Route path="*" element={<Navigate to="" />} />
    </Switch>
  );
};
